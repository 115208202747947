import { useEffect } from "react";

const useConversionTracking = () => {
  // Function to be called after the user signs up
  const signup = (planValue) => {
    try {
      // Google Analytics SignUp event
      if (typeof window.gtag !== "undefined") {
        console.log("GA: signup event");
        window.gtag("event", "user_registration", {});
      } else {
        console.warn("Google Tag is not initialized.");
      }
    } catch (e) {
      console.log(e);
    }

    // Reddit Pixel signup event
    try {
      if (typeof window.rdt !== "undefined") {
        console.log("Reddit: signup event");
        window.rdt("track", "SignUp", {});
      } else {
        console.warn("Reddit Pixel is not initialized.");
      }
    } catch (e) {
      console.log("Reddit Pixel error:", e);
    }
  };

  return { signup };
};

export default useConversionTracking;
