import React, { useState } from "react";

// mui:
import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  TableCell,
  TableRow,
} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";

// components and constants:
import Text from "components/Text";
// import ProductStatusIndicator from "components/ProductStatusIndicator";
import { PRODUCT_TYPE_UNBRANDED, BRANDED_BOX_COST } from "constants/constants";

import styles from "./CustomerOrderItem.module.scss";

function CustomerOrderItem(props) {
  const { item, order, isSelected, handleSetBrandedBox, fullfilledByBlanka } =
    props;

  const [orderReadOnly] = useState(
    order.status !== "PAYMENT_REQUIRED" || !fullfilledByBlanka,
  );

  /*
  Check list of skus that are not allow to have a branded box
  Checks to see if the root sku is present
  */
  const isBrandedBoxAvailable = (brandedBoxAvailable, productType) => {
    if (!brandedBoxAvailable || productType === PRODUCT_TYPE_UNBRANDED) {
      return false;
    }

    return true;
  };

  const handleBrandedBoxChecked = (event, id) => {
    handleSetBrandedBox(event, id);
  };

  return (
    <TableRow>
      <TableCell>
        <div className={styles.productImage}>
          {item.product?.product_base?.out_of_stock &&
            !item.product?.product_base?.is_discontinued && (
              <div className={styles.diagonal} />
            )}

          {/* <Box className={styles.statusIndicator}>
            <ProductStatusIndicator product={item.product} />
          </Box> */}

          <div
            className={
              item.product?.product_base?.is_discontinued &&
              styles.diagonalLines
            }
          >
            <img
              className={`image ${
                (item.product?.product_base?.out_of_stock ||
                  item.product?.product_base?.is_discontinued) &&
                styles.imageOutOfStock
              }`}
              src={item?.image}
              alt="Product"
            />
          </div>
        </div>
      </TableCell>
      <TableCell className="text--cell-details text--nunito text--upper">
        {item.sku}
      </TableCell>
      <TableCell className="text--cell-details text--nunito text--upper">
        {item.title}
      </TableCell>

      <TableCell
        className="text--cell-details text--bold text--brandedbox"
        align="left"
      >
        {!orderReadOnly &&
        isBrandedBoxAvailable(item.branded_box_available, item.product_type) ? (
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  disabled={
                    orderReadOnly ||
                    !isBrandedBoxAvailable(
                      item.branded_box_available,
                      item.product_type,
                    ) ||
                    !fullfilledByBlanka
                  }
                  checked={isSelected}
                  onChange={(event) => handleBrandedBoxChecked(event, item.id)}
                  inputProps={{
                    readOnly: true,
                  }}
                />
              }
              label="Add Branded Boxes $0.40/Ea"
            />
          </FormGroup>
        ) : (
          <div
            className={`${
              !isBrandedBoxAvailable(
                item.branded_box_available,
                item.product_type,
              )
                ? "hidden"
                : ""
            } `}
          >
            <Text
              className={`${!item.branded_box ? "hidden" : ""} text--label`}
            >
              <CheckIcon className="icon--check" /> Add Branded Boxes $0.40/Ea
            </Text>
          </div>
        )}
      </TableCell>
      <TableCell className="text--cell-details" align="center">
        $
        {(
          Number(item.cost) + Number(isSelected ? BRANDED_BOX_COST : 0)
        ).toFixed(2)}
      </TableCell>

      <TableCell className="text--cell-details text--bold" align="center">
        {item.quantity}
      </TableCell>
      <TableCell className="text--cell-details text--bold" align="center">
        $
        {(
          Number(item.cost * item.quantity) +
          Number(isSelected ? BRANDED_BOX_COST * item.quantity : 0)
        ).toFixed(2)}
      </TableCell>
    </TableRow>
  );
}

export default CustomerOrderItem;
