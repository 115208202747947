import { SvgIcon } from "@mui/material";
import React from "react";

function CheckIcon({ size, color = "#FFF" }) {
  return (
    <SvgIcon
      data-testid="CheckIcon"
      className="icon icon--normal"
      viewBox="0 0 40 41"
      style={{ width: size, height: size }}
    >
      <g>
        <path
          d="M7.61633 15.7622L0.888855 8.99496L2.87923 7.00458L7.61633 11.7019L19.1207 0.237305L21.1111 2.26749L7.61633 15.7622Z"
          fill={color}
        />
      </g>
    </SvgIcon>
  );
}

export default CheckIcon;
