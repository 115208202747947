import React from "react";

// MUI components:
import { Box, Grid, Skeleton, Stack } from "@mui/material";

// Custom components:
import PageHeader from "components/PageHeader/PageHeader";

import { ORDER_TYPE_INVENTORY, ORDER_TYPE_SAMPLE } from "constants/constants";
import CustomerOrderCard from "views/CustomerOrdersView/components/CustomerOrdersDesktopView/CustomerOrderCard";
import InventoryOrderCard from "views/InventoryOrdersView/components/InventoryOrderCard";
import SampleOrderCard from "views/SampleOrdersView/components/SampleOrderCard";

import styles from "./OrderProductsDesktop.module.scss";

export default function OrderProductsDesktop({
  isLoading,
  isFetching,
  order,
  refetch,
}) {
  // TODO: Refactor this to a separate Hook:
  const renderOrderCard = () => {
    if (order?.order_type === ORDER_TYPE_SAMPLE) {
      return <SampleOrderCard order={order} />;
    }
    if (order?.order_type === ORDER_TYPE_INVENTORY) {
      return <InventoryOrderCard order={order} />;
    }

    return <CustomerOrderCard order={order} refetch={refetch} />;
  };

  return (
    <div className="orders-view">
      <Grid className="page-header" container>
        <PageHeader title="ORDER DETAILS" />
      </Grid>

      {!isLoading || !isFetching ? (
        <Grid container>{renderOrderCard()}</Grid>
      ) : (
        <Grid container>
          <div className="order-skeleton-container">
            <Box sx={{ width: "100%" }}>
              <Grid container>
                <div className={styles.orderSkeletonContainer}>
                  <Stack spacing={2}>
                    <Skeleton variant="rectangular" />
                    <Skeleton variant="rectangular" height={40} />
                    <Skeleton variant="rectangular" height={118} />
                  </Stack>
                </div>
                <div className={styles.ordersSkeletonContainer}>
                  <Stack spacing={2}>
                    <Skeleton variant="rectangular" />
                    <Skeleton variant="rectangular" height={40} />
                    <Skeleton variant="rectangular" height={118} />
                  </Stack>
                </div>
              </Grid>
            </Box>
          </div>
        </Grid>
      )}
    </div>
  );
}
