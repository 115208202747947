import PropTypes from "prop-types";
import React from "react";

import OrderStatusTag from "components/Labels/OrderStatusTag";
import ModalWrapper from "components/ModalWrapper";
import OrderTable from "components/Tables/OrderTable";
import Text from "components/Text";

import LocalShippingOutlinedIcon from "@mui/icons-material/LocalShippingOutlined";
import { Box, Divider } from "@mui/material";
import moment from "moment";

import { SMALL } from "constants/breakpoints";
import { useMediaQuery } from "react-responsive";

import {
  ORDER_STATUS_CANCELLED,
  ORDER_STATUS_FULFILLED,
  ORDER_STATUS_PAYMENT_REQUIRED,
  ORDER_STATUS_SHIPPED,
  ORDER_STATUS_UNFULFILLED,
} from "constants/constants";
import styles from "./OrderDetailsModal.module.scss";

function OrderDetailsModal(props) {
  const { open, order, setOpen, orderType } = props;
  const {
    status,
    tracking_code: trackingCode,
    external_order_number: externalOrderNumber,
  } = order;

  const isMobile = useMediaQuery({ maxWidth: SMALL });

  const getTrackingCode = (status) => {
    switch (status) {
      case ORDER_STATUS_PAYMENT_REQUIRED:
        return <Text className={styles.trackPaymentRequired}>Pending</Text>;
      case ORDER_STATUS_UNFULFILLED:
        return (
          <Text className={styles.trackUnfulfilled}>
            Order is being processed
          </Text>
        );
      case ORDER_STATUS_SHIPPED:
        return <Text className={styles.trackFulfilled}>{trackingCode}</Text>;
      case ORDER_STATUS_FULFILLED:
        return <Text className={styles.trackFulfilled}>{trackingCode}</Text>;
      case ORDER_STATUS_CANCELLED:
        return <Text className={styles.trackFulfilled}>N/A</Text>;
      default:
        return <Text />;
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <ModalWrapper
      isOpen={open}
      handleClose={handleClose}
      headerType={isMobile ? "mobile" : "default"}
      className={styles.modalWrapper}
      title="Order Details"
    >
      <Box className={styles.orderDetailsModal}>
        <Box className={styles.flexContainer}>
          <div className={styles.leftSideBox}>
            <Text className={styles.orderNumber}>Order {orderType}</Text>
            <Text className={styles.orderDate}>
              Date: {moment(order.created_at).format("MMM DD yyy")}
            </Text>
          </div>
          <div className={styles.rightSideBox}>
            <Text className={styles.orderStatus}>Status</Text>
            <OrderStatusTag status={status} type={order.order_type} />
          </div>
        </Box>
        <Divider orientation="horizontal" className={styles.divider} />
        <Box className={styles.trackContainer}>
          <div>
            <LocalShippingOutlinedIcon />
            <Text className={styles.trackInfo}>Tracking info</Text>
          </div>
          {getTrackingCode(status)}
        </Box>
        <Divider orientation="horizontal" className={styles.divider} />
        <OrderTable order={order} />
      </Box>
    </ModalWrapper>
  );
}

OrderDetailsModal.propTypes = {
  open: PropTypes.bool.isRequired,
  order: PropTypes.object.isRequired,
  setOpen: PropTypes.func.isRequired,
};

export default OrderDetailsModal;
