import CloseButton from "components/Notifications/CloseButton";
import React from "react";
import { ToastContainer } from "react-toastify";
// MUI components:
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Box, Divider, Stack } from "@mui/material";
import Button from "components/Buttons/Button";
import { useSelector } from "react-redux";
// Custom components & assets:
// import Button from "components/Buttons/Button";
import blankaLogo from "assets/images/logo.png";
import Text from "components/Text";

import { Link, useNavigate } from "react-router-dom";
import styles from "./CommonLayout.module.scss";

const STEP_NAMES = [
  { name: "FEEDBACK" },
  { name: "FEATURE_USAGE" },
  { name: "REVIEW" },
];

function CommonLayout(props) {
  const {
    currentRouteIndex,
    handleNext,
    handleKeepCurrentPlan,
    handleUnderstoodProceed,
    children,
    handleReviewDowngrade,
  } = props;

  return (
    <Stack className={styles.commonLayout}>
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar
        preventDuplicates
        newestOnTop={false}
        closeOnClick
        limit={1}
        rtl={false}
        pauseOnFocusLoss
        pauseOnHover
        closeButton={CloseButton}
      />
      <header className={styles.navbar}>
        <Box
          className={styles.backButton}
          onClick={() =>
            handleKeepCurrentPlan(STEP_NAMES[currentRouteIndex].name)
          }
        >
          <ArrowBackIcon />
          <Text className={styles.text}>Back to my account</Text>
        </Box>
        <img src={blankaLogo} alt="Blanka Logo" className={styles.logo} />
      </header>

      <main className={styles.commonLayoutContent}>
        {children}
        <Box className={styles.ctaContainer}>
          {currentRouteIndex === 1 && (
            <>
              <Link
                to="/profile"
                onClick={() =>
                  handleKeepCurrentPlan(STEP_NAMES[currentRouteIndex].name)
                }
                className={styles.ctaText}
              >
                Keep Current Plan
              </Link>
              <Button
                variant="contained"
                color="primary"
                disabled={
                  !useSelector(
                    (state) => state.changePlan.hasSelectedDowngradeReason
                  )
                }
                onClick={() => handleNext()}
                className={styles.ctaBtn}
              >
                Next
              </Button>
            </>
          )}

          {currentRouteIndex === 2 && (
            <>
              <Button
                size="small"
                variant="outlined"
                color="primary"
                onClick={() =>
                  handleKeepCurrentPlan(STEP_NAMES[currentRouteIndex].name)
                }
                className={styles.ctaBtn}
              >
                Keep Current Plan
              </Button>
              {/* <Button
                variant="contained"
                color="primary"
                onClick={handleUnderstoodProceed}
                size="small"
                loading={useSelector(
                  (state) => state.changePlan.planChangeLoading
                )}
                className={styles.ctaBtn}
              >
                Understood, proceed!
              </Button> */}
              <Button
                variant="text"
                color="primary"
                size="small"
                onClick={handleReviewDowngrade}
                className={styles.cancelSubscriptionButton}
              >
                Cancel subscription
              </Button>
            </>
          )}
        </Box>
      </main>
    </Stack>
  );
}

CommonLayout.defaultProps = {};

export default CommonLayout;
