import React, { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import { ExpandMore } from "@mui/icons-material";
import { Box } from "@mui/material";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";

import UserIcon from "components/Icons/UserIcon";
import Text from "components/Text";

import ROUTE_PATHS from "routes/routePaths";
import cn from "utils/cn";
import styles from "./UserMenu.module.scss";

const MENU_ITEMS = [
  { label: "Dashboard", path: ROUTE_PATHS.DASHBOARD },
  { label: "My Account", path: ROUTE_PATHS.PROFILE },
  // { label: "My Brand", path: ROUTE_PATHS.BRAND_SETUP },
  { label: "Logout", path: `${ROUTE_PATHS.LOGOUT}?no_persist=true` },
];

export default function UserMenu() {
  const navigate = useNavigate();
  const popoverRef = useRef(null);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleDocumentClick = (e) => {
    if (
      popoverRef.current &&
      !popoverRef.current.contains(e.target) &&
      anchorEl &&
      !anchorEl.contains(e.target)
    ) {
      setAnchorEl(null);
    }
  };

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, [setAnchorEl]);

  useEffect(() => {
    if (anchorEl) {
      document.addEventListener("click", handleDocumentClick);
    } else {
      document.removeEventListener("click", handleDocumentClick);
    }

    return () => document.removeEventListener("click", handleDocumentClick);
  }, [anchorEl]);

  return (
    <Box className={styles.userMenu}>
      <Box
        className={styles.iconWrapper}
        onClick={(e) => {
          if (anchorEl) {
            setAnchorEl(null);
          } else {
            setAnchorEl(e.currentTarget);
          }
        }}
      >
        <UserIcon size="18" active={!!anchorEl} /> <ExpandMore />
      </Box>

      {anchorEl && (
        <Box
          ref={popoverRef}
          className={cn(styles.popoverContent)}
          style={{ top: anchorEl.offsetTop + 32 }}
        >
          <Box className={cn(styles.popoverBody)}>
            {MENU_ITEMS.map((item) => (
              <MenuItem
                key={item.path}
                onClick={() => {
                  handleClose();
                  navigate(item.path);
                }}
              >
                <Text>{item.label}</Text>
              </MenuItem>
            ))}
          </Box>
        </Box>
      )}
    </Box>
  );
}
