import { Box } from "@mui/material";
import Text from "components/Text";
import React from "react";

import shootingStar from "assets/images/shooting-star.png";
import Button from "components/Buttons/Button";
import styles from "./TaskSuccessModal.module.scss";

function TaskSuccessModal({ onClose, loading }) {
  return (
    <Box className={styles.taskSuccessModal}>
      <Box className={styles.confetti1} />
      <Box className={styles.confetti2} />
      <Box className={styles.taskSuccessModalContent}>
        <Box>
          <Text variant="h1" className={styles.title}>
            You&apos;re all done
          </Text>
          <Text variant="h2" className={styles.subtitle}>
            Your Blanka product line is ready for launch.
          </Text>
        </Box>

        <Box className={styles.star}>
          <img src={shootingStar} alt="complete tasks" />
        </Box>

        {/* <Box className={styles.circle}>
          <Box className={styles.circleInner}>
            <Box className={styles.priceWrapper}>
              <Text className={styles.dollar}>$</Text>
              <Text className={styles.price}>50</Text>
            </Box>
            <Text className={styles.creditLabel}>CREDIT</Text>
          </Box>
        </Box> */}

        <Button
          disabled={loading}
          loading={loading}
          onClick={onClose}
          variant="contained"
          color="primary"
        >
          WOOHOO!
        </Button>
      </Box>
    </Box>
  );
}

export default TaskSuccessModal;
