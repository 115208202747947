import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import Dropdown from "components/Dropdown";
import OrderCard from "components/Cards/OrderCard";
import Text from "components/Text";
import PageHeader from "components/PageHeader/PageHeader";

import { Stack } from "@mui/material";
import { ORDER_STATUS_ALL } from "constants/constants";
import SuspenseLoading from "components/SuspenseLoading";
import NoOrdersModal from "components/Modals/NoOrdersModal";
import styles from "./InventoryOrdersMobileView.module.scss";

function InventoryOrdersMobileView(props) {
  const { isLoading, data, options } = props;
  const orders = data?.data?.results;

  // state variable to keep track of the selected option in the dropdown:
  const [selectedOption, setSelectedOption] = useState(options[0]);
  const [openNoOrderModal, setOpenNoOrderModal] = useState(false);

  // filter orders based on the selected option:
  const ordersFiltered =
    selectedOption === ORDER_STATUS_ALL
      ? orders
      : orders.filter((order) => order.status === selectedOption);

  useEffect(() => {
    if (
      !isLoading &&
      selectedOption === ORDER_STATUS_ALL &&
      ordersFiltered?.length === 0
    ) {
      setOpenNoOrderModal(true);
    }
  }, [isLoading, ordersFiltered, selectedOption]);

  const handleOptionChange = (option) => {
    setSelectedOption(option);
  };

  return (
    <div className={styles.inventoryOrdersMobileView}>
      <PageHeader title="">
        <div className={styles.mobileFlexContainer}>
          <p className="text__page-header">Inventory Orders</p>
          <Dropdown options={options} onChange={handleOptionChange} />
        </div>
      </PageHeader>
      <Stack sx={{ paddingTop: "30px" }}>
        {isLoading && <SuspenseLoading />}
        {!isLoading && ordersFiltered.length === 0 && (
          <Text variant="h1" className={styles.noOrdersToDisplay}>
            You have no orders of this type
          </Text>
        )}
        {!isLoading &&
          ordersFiltered.length > 0 &&
          ordersFiltered.map((order) => (
            <OrderCard key={order.id} order={order} />
          ))}
      </Stack>

      <NoOrdersModal
        open={openNoOrderModal}
        handleClose={() => setOpenNoOrderModal(false)}
        title="Hold tight! No orders to see here, yet."
        message="You haven’t placed any Inventory Orders yet.  Please visit “MY PRODUCTS” to place some orders for inventory."
        ctaText="Place Order"
        navLink="/my-products"
      />
    </div>
  );
}

InventoryOrdersMobileView.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  data: PropTypes.object,
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
};

InventoryOrdersMobileView.defaultProps = {
  data: {},
};

export default InventoryOrdersMobileView;
