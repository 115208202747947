import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";

// Material-UI
import { Box } from "@mui/material";

// Custom components
import BrandSetupExplainModal from "components/BrandSetup/BrandSetupExplainModal";
import BrandSetupSteps from "components/BrandSetup/BrandSetupSteps";
import BrandSetupUploadFile from "components/BrandSetup/BrandSetupUploadFile";
import BrandSetupUploadValidator from "components/BrandSetup/BrandSetupUploadValidator";
import BrandSetupWrapper from "components/BrandSetup/BrandSetupWrapper";
import BrandSetupLogoExampleModal from "components/BrandSetup/BrandSetupLogoExampleModal";
import Button from "components/Buttons/Button";
import Text from "components/Text";

// API:
import { uploadBase64ImageV2 } from "api/endpoints";
import { uploadMyLogoGenerated } from "api/endpoints/shopApi";

// state
import { setLogo, setLogoIsUploaded } from "state/brandSetupSlice";

// Assets
import PencilLogo from "assets/images/brand-plate/pencil-logo.svg";

// Constants
import { SMALL } from "constants/breakpoints";
import { LOGO_DEFAULT } from "constants/generatorLogo";

// Utils
import cn from "utils/cn";

import { BRAND_SETUP_STEPS } from "constants/constants";
import { useAnalytics } from "hooks";
import { useShopMutations } from "hooks/mutations/useShopMutations";
import ROUTE_PATHS from "routes/routePaths";
import styles from "./BrandSetupUploadView.module.scss";

function BrandSetupUploadView() {
  const navigate = useNavigate();

  const isMobile = useMediaQuery({ maxWidth: SMALL });
  const dispatch = useDispatch();
  const logos = useSelector((state) => state.brandSetup.logos);
  const { shop } = useSelector((state) => state.profile);
  const [openModal, setOpenMoal] = useState(false);
  const [imagePreview, setImagePreview] = useState(null);
  const [onError, setOnError] = useState(false);
  const [responseData, setResponseData] = useState([]);
  const [nextStepDisabled, setNextStepDisabled] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingCancel, setIsLoadingCancel] = useState(false);
  const [onLogoUpload, setOnLogoUpload] = useState(false);
  const analytics = useAnalytics();
  const { updateShopMutation } = useShopMutations();

  const [openLogoExamplesModal, setOpenLogoExamplesModal] = useState(false);

  const handleOpenLogoExamplesModal = () => {
    setOpenLogoExamplesModal(true);
  };

  const handleCloseLogoExamplesModal = () => {
    setOpenLogoExamplesModal(false);
  };

  useEffect(() => {
    analytics.sendEvent(analytics.BRAND_SETUP_UPLOAD_VIEW, {});
  }, []);

  const onNext = async () => {
    setIsLoading(true);
    try {
      dispatch(setLogoIsUploaded(true));
      analytics.sendEvent(analytics.BRAND_SETUP_UPLOAD);
      await uploadMyLogoGenerated(logos[0]);
      await updateShopMutation.mutateAsync({
        id: shop.id,
        brand_setup_step: BRAND_SETUP_STEPS.MOCKUP,
      });

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      // console.error(error);
    }
  };

  useEffect(() => {
    if (updateShopMutation.isSuccess && isLoadingCancel) {
      navigate(ROUTE_PATHS.BRAND_SETUP);
    }
  }, [isLoadingCancel, updateShopMutation.isSuccess]);

  const onCancel = async () => {
    setIsLoadingCancel(true);
    await updateShopMutation.mutateAsync({
      id: shop.id,
      brand_setup_step: BRAND_SETUP_STEPS.COMPLETE,
    });
  };

  const handleOpenExplanationModal = () => {
    setOpenMoal(!openModal);
  };

  const handleOnLogoUpload = async (file) => {
    setOnLogoUpload(true);
    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onloadend = async () => {
      try {
        // The base64-encoded string with "data:image/png;base64,"
        const base64ImageWithPrefix = reader.result;

        // Extract just the base64 string, without the prefix
        const base64String = base64ImageWithPrefix.split(",")[1];

        const response = await uploadBase64ImageV2(base64String);
        setResponseData(response.data);
        dispatch(
          setLogo({
            logo: file,
            logo_type: LOGO_DEFAULT,
          })
        );

        // Check if any validation failed
        const hasFalse = Object.values(response.data).some(
          (value) => value === false
        );

        if (hasFalse) {
          setImagePreview(base64ImageWithPrefix);
          setOnError(true);
          setNextStepDisabled(true);
          setOnLogoUpload(false);

          console.log("upload logo error", response.data);

          analytics.sendEvent(analytics.BRAND_SETUP_UPLOAD_LOGO_ERROR, {
            errors: Object.keys(response.data).filter(
              (key) => !response.data[key]
            ),
          });

          return;
        }

        setImagePreview(base64ImageWithPrefix);
        setOnError(false);
        setNextStepDisabled(false);
        setOnLogoUpload(false);
        analytics.sendEvent(analytics.BRAND_SETUP_UPLOAD_LOGO_SUCCESS, {});
      } catch (error) {
        // console.error(error);
        setOnError(true);
        setNextStepDisabled(true);
        setOnLogoUpload(false);
      }
    };
  };

  return (
    <BrandSetupWrapper
      pageTitle="My Brand Setup"
      wrapperClassName={styles.brandSetupUpload}
    >
      <Box className={styles.brandSetupUploadHeader}>
        <Box className={styles.boxContainer}>
          <Text variant="h1" className={styles.title}>
            Create your branded products!
          </Text>
          <Text className={styles.subsection1}>
            Upload your logo and review{" "}
            <span className={styles.link} onClick={handleOpenExplanationModal}>
              branded mockups
            </span>
            . This one-time step is essential to create your product line.
          </Text>
          <Text className={styles.subsection2}>
            For best results, upload logo image file with a transparent
            background that meets the criteria below. Don’t have a logo yet?{" "}
            <a
              href="https://blankabrand.com/collections/marketing-creative/products/logo-design"
              target="_blank"
              className={styles.link}
              rel="noreferrer"
            >
              Get a custom logo
            </a>
            . After your first complimentary brand setup, a new brand setup has
            a fee of $149.
          </Text>
          <Text className={styles.subsection2}>
            Need help uploading your logo? Reach out to{" "}
            <a
              className={styles.link}
              rel="noreferrer"
              onClick={(e) => window.Intercom("showNewMessage")}
            >
              support here
            </a>{" "}
            for assistance.{" "}
            <span onClick={handleOpenLogoExamplesModal} className={styles.link}>
              See logo examples
            </span>
            .
          </Text>
        </Box>

        <Box>
          <BrandSetupSteps />
        </Box>
      </Box>

      <Text className={styles.brandSetupUploadTitle}>
        <img
          src={PencilLogo}
          alt="Pencil logo"
          className="target__upload_logo--upload-logo-text"
        />{" "}
        Upload your logo
      </Text>
      <Box className={styles.brandSetupUploadContent}>
        <BrandSetupUploadFile
          onLogoUpload={handleOnLogoUpload}
          uploadedImage={imagePreview}
          onError={onError}
        />
        <BrandSetupUploadValidator
          onLogoUpload={handleOnLogoUpload}
          validationResults={responseData}
          loading={onLogoUpload}
        />
      </Box>

      <Box className={cn(!isMobile ? styles.desktopCta : styles.mobileCta)}>
        {shop.brand_plate_created && (
          <Button
            onClick={onCancel}
            color="primary"
            variant="outlined"
            size="small"
            disabled={isLoadingCancel}
            loading={isLoadingCancel}
          >
            Cancel
          </Button>
        )}
        <Button
          onClick={onNext}
          color="primary"
          variant="contained"
          hasChevron
          size="small"
          disabled={nextStepDisabled}
          loading={isLoading}
        >
          Next
        </Button>
      </Box>

      <BrandSetupExplainModal
        open={openModal}
        handleClose={handleOpenExplanationModal}
      />

      <BrandSetupLogoExampleModal
        open={openLogoExamplesModal}
        handleClose={handleCloseLogoExamplesModal}
      />
    </BrandSetupWrapper>
  );
}

export default BrandSetupUploadView;
