import { StyledEngineProvider, ThemeProvider } from "@mui/material/styles";
import queryClient from "api/queryClient";
import React, { useMemo } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { QueryClientProvider } from "react-query";
import { useSelector } from "react-redux";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Navigate,
  Route,
  RouterProvider,
} from "react-router-dom";
import ErrorView from "views/ErrorView";
import Main from "views/Main";

import { GoogleOAuthProvider } from "@react-oauth/google";
import LoginRedirect from "components/LoginRedirect/LoginRedirect";
import ScrollToTop from "components/ScrollToTop";

import { useFeatureFlags } from "hooks";
import DowngradeView from "views/DowngradeView";
import { getProtectedRoutes, publicRoutes } from "./routes";
import { theme } from "./theme";

import "./App.scss";

function App() {
  // need to work on this conditional route rendering of feature flags that comes from backend
  // currently doesn't play nicely with react-redux and react-query when refetching
  // stored in localStorage for now like the token
  const { featureFlags } = useFeatureFlags();
  const isLoggedIn = useSelector((state) => state.session.isLoggedIn);

  const protectedRoutes = useMemo(() => {
    if (!isLoggedIn) return [];
    return getProtectedRoutes(featureFlags).filter(
      (route) => route.showRoute !== false
    );
  }, [featureFlags, isLoggedIn]);

  const router = useMemo(
    () =>
      createBrowserRouter(
        createRoutesFromElements(
          <>
            {/* @look-rain why is downgrade outside private route? */}
            {/* <Route
              key="downgrade"
              path="/downgrade/*"
              element={<DowngradeView />}
            /> */}

            {/* Protected Routes */}
            {isLoggedIn ? (
              <Route element={<Main loggedIn={isLoggedIn} />}>
                {protectedRoutes.map((route) => (
                  <Route
                    key={route.path}
                    path={route.path}
                    element={route.component}
                  />
                ))}
              </Route>
            ) : (
              <Route path="*" element={<LoginRedirect />} />
            )}

            {/* Public Routes */}
            {publicRoutes.map((route) => (
              <Route
                key={route.path}
                path={route.path}
                element={route.component}
              />
            ))}

            {/* Catch-all Redirect */}
            <Route path="*" element={<Navigate to="/" />} />
          </>
        )
      ),
    [protectedRoutes, isLoggedIn]
  );

  return (
    <GoogleOAuthProvider clientId={`${process.env.REACT_APP_GOOGLE_CLIENT_ID}`}>
      <ThemeProvider theme={theme}>
        <StyledEngineProvider injectFirst>
          <QueryClientProvider client={queryClient}>
            <div className="App" data-testid="App">
              <ErrorBoundary
                FallbackComponent={ErrorView}
                onReset={() => {
                  window.location.reload();
                }}
              >
                <RouterProvider router={router}>
                  <ScrollToTop />
                </RouterProvider>
              </ErrorBoundary>
            </div>
          </QueryClientProvider>
        </StyledEngineProvider>
      </ThemeProvider>
    </GoogleOAuthProvider>
  );
}

export default App;
