import React from "react";

// MUI components
import { Box } from "@mui/material";

// Custom components
import Text from "components/Text";
import quote from "assets/images/loading-screen/quote.png";

// third-party
import Lottie from "react-lottie";
import animationData from "assets/animations/blanka_loader.json";

import styles from "./NewLoadingScreen.module.scss";

const defaultOptions = {
  // Set to false to play the animation only once
  loop: true,
  // Set to false to start animation manually
  autoplay: true,
  // JSON data for the animation
  animationData,
  rendererSettings: {
    // Control how the animation scales
    preserveAspectRatio: "xMidYMid slice",
  },
};

export default function NewLoadingScreen() {
  return (
    <main className={styles.newLoadingScreen}>
      <Lottie options={defaultOptions} height={300} width={300} />
      <Box className={styles.quoteContainer}>
        <img src={quote} alt="open quote" className={styles.openQuote} />

        <Text variant="body1" className={styles.text}>
          We pride ourselves on offering our clients the best ingredients in the
          business.
        </Text>
        <Text variant="body1" className={styles.text}>
          Cruelty-free, gluten-free and sulphate-free, with the majority also
          being paraben-free and vegan.
        </Text>

        <img src={quote} alt="closing quote" className={styles.closeQuote} />
      </Box>
    </main>
  );
}
