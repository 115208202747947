import React from "react";
import { useMediaQuery } from "react-responsive";

// MUI components
import { Box } from "@mui/material";

// Custom components
import Text from "components/Text";
import {
  ORDER_STATUS_CANCELLED,
  ORDER_STATUS_FULFILLED,
  ORDER_STATUS_PAYMENT_REQUIRED,
  ORDER_STATUS_SHIPPED,
  ORDER_STATUS_UNFULFILLED,
  ORDER_TYPE_CUSTOMER,
  ORDER_TYPE_INVENTORY,
  ORDER_TYPE_SAMPLE,
} from "constants/constants";

import { SMALL } from "constants/breakpoints";

import {
  CUSTOMER_ORDER_STATUS_PROGRESS,
  INVENTORY_ORDER_STATUS_PROGRESS,
  SAMPLE_ORDER_STATUS_PROGRESS,
} from "constants/orders";
import styles from "./OrderStatusBar.module.scss";

export default function OrderStatusBar({ status, type }) {
  const isMobile = useMediaQuery({ maxWidth: SMALL });

  const renderProgress = () => {
    let progress = [];

    switch (type) {
      case ORDER_TYPE_INVENTORY:
        progress = INVENTORY_ORDER_STATUS_PROGRESS;
        break;
      case ORDER_TYPE_CUSTOMER:
        progress = CUSTOMER_ORDER_STATUS_PROGRESS;
        break;

      case ORDER_TYPE_SAMPLE:
        progress = SAMPLE_ORDER_STATUS_PROGRESS;
        break;

      default:
        break;
    }

    return progress.map((value) => {
      let isActive = false;

      if (
        status === ORDER_STATUS_PAYMENT_REQUIRED &&
        value === "Payment Required"
      ) {
        isActive = true;
      }

      if (status === ORDER_STATUS_UNFULFILLED && value === "In Progress") {
        isActive = true;
      }

      if (
        (status === ORDER_STATUS_FULFILLED ||
          status === ORDER_STATUS_SHIPPED) &&
        (value === "Shipped" || value === "Complete")
      ) {
        isActive = true;
      }

      return (
        <Text
          key={status}
          variant="body2"
          className={`${styles.orderStatus} ${isActive ? styles.active : ""}`}
        >
          {value}
        </Text>
      );
    });
  };

  return (
    <Box className={styles.orderStatusBar}>
      {!isMobile && (
        <Text variant="body2" className={styles.title}>
          Status
        </Text>
      )}
      {status !== ORDER_STATUS_CANCELLED ? (
        <Box className={styles.orderProgressBar}>{renderProgress()}</Box>
      ) : (
        <Text variant="body2" className={styles.cancelled}>
          Cancelled
        </Text>
      )}
    </Box>
  );
}
