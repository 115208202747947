import moment from "moment";
import React from "react";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";

import CheckIcon from "@mui/icons-material/Check";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import {
  Box,
  Card,
  CardContent,
  Divider,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

import Text from "components/Text";

import CartTotals from "components/CartTotals/CartTotals";
import OrderDetails from "components/Orders/OrderDetails/OrderDetails";
import OrderStatusBar from "components/Orders/OrderStatusBar/OrderStatusBar";

import {
  FULFILLED,
  ORDER_TYPE_SAMPLE,
  PAYMENT_REQUIRED,
  UNFULFILLED,
} from "constants/constants";

import { TruckIcon } from "components/Icons";

import { SMALL } from "constants/breakpoints";

import DiscountTag from "components/Labels/DiscountTag/DiscountTag";
import styles from "./NewInventoryOrdersCard.module.scss";

function NewInventoryOrderCard(props) {
  const { order } = props;

  const navigate = useNavigate();

  const handlePrevious = () => {
    navigate("/inventory-orders");
  };

  const isMobile = useMediaQuery({ maxWidth: SMALL });

  const cartBill = {
    subtotal: order.subtotal,
    subtotal_with_savings: order.subtotal_with_savings,
    total_with_savings: order.total_cost_with_savings,
    total: order.total,
    total_discount: order.total_discount,
    fees: order.fees,

    total_branded_box_fees: order.branded_box_cost,
    shipping: {
      shipping_cost: order.shipping_cost,
    },
    type: order.order_type,
  };

  const trackingStatus = () => {
    if (order?.status === FULFILLED) {
      return (
        <Text className="text--nunito text__tracking-header">
          {order?.tracking_code ? order?.tracking_code : "PENDING"}
        </Text>
      );
    }

    if (order.status === PAYMENT_REQUIRED) {
      return (
        <Text className="text--nunito text__tracking-header">PENDING</Text>
      );
    }
    if (order.status === UNFULFILLED) {
      return (
        <Text className="text--nunito text__tracking-header">
          ORDER IS BEING PROCESSSED
        </Text>
      );
    }

    return (
      <a
        target="_blank"
        href={`https://parcelsapp.com/en/tracking/${order.tracking_code}`}
        className="text__tracing-number"
        rel="noreferrer"
      >
        {order.tracking_code}
      </a>
    );
  };

  return (
    <Card className="order-card root">
      <CardContent className={styles.cardContent}>
        {!isMobile && (
          <div className="order-card__header">
            <div onClick={handlePrevious}>
              <div className={styles.backBtn}>
                <KeyboardBackspaceIcon />
                Back To Orders
              </div>
            </div>
          </div>
        )}

        <div className="order-card__details">
          {!isMobile ? (
            <>
              <Box className={styles.appendedHeader}>
                <Stack direction="row" gap="32px">
                  <Box
                    className="text--bold"
                    align="left"
                    classes={styles.cell}
                  >
                    <Text variant="body2" className={styles.title}>
                      Date
                    </Text>
                    <Text variant="body2" className={styles.peachText}>
                      {moment(order?.created_at).format("MMM DD, YYYY")}
                    </Text>
                  </Box>
                  <Box
                    className="text--bold"
                    align="left"
                    classes={styles.cell}
                  >
                    <Text variant="body2" className={styles.title}>
                      Order #
                    </Text>
                    <Text variant="body2" className={styles.peachText}>
                      {`10${order?.id}`}
                    </Text>
                  </Box>
                  <Box
                    className="text--bold"
                    align="left"
                    classes={styles.cell}
                  >
                    <Text variant="body2" className={styles.title}>
                      Qty
                    </Text>
                    <Text variant="body2" className={styles.peachText}>
                      {order?.number_of_items}
                    </Text>
                  </Box>
                  <Box
                    className="text--bold"
                    align="left"
                    classes={styles.cell}
                  >
                    <Text variant="body2" className={styles.title}>
                      Total
                    </Text>
                    <Text variant="body2" className={styles.peachText}>
                      ${order?.total}
                    </Text>
                  </Box>
                </Stack>
                <Box className="text--bold" align="center">
                  <OrderStatusBar
                    status={order.status}
                    type={order.order_type}
                  />
                </Box>
              </Box>
              <TableContainer>
                <Table aria-label="">
                  <TableHead>
                    <TableRow>
                      <TableCell className="text--bold" align="left">
                        Product
                      </TableCell>
                      <TableCell className="text--bold" align="left">
                        SKU
                      </TableCell>
                      <TableCell className="text--bold " align="left">
                        Branded Boxes
                      </TableCell>
                      <TableCell className="text--bold" align="center">
                        Quantity
                      </TableCell>
                      <TableCell className="text--bold" align="center">
                        Unit Cost
                      </TableCell>
                      <TableCell className="text--bold" align="center">
                        Total Cost
                      </TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {order?.items?.map((item) => (
                      <TableRow>
                        <TableCell>
                          <div className={styles.productImage}>
                            <img
                              className="image"
                              src={item?.product.image}
                              alt="Product"
                            />
                          </div>
                        </TableCell>
                        <TableCell className="text--cell-details text--nunito text--upper">
                          {item.product.sku}
                        </TableCell>
                        <TableCell className="text--cell-details text--nunito text--upper">
                          {item.product.name}
                        </TableCell>

                        <TableCell
                          className="text--cell-details text--bold text--brandedbox"
                          align="left"
                        >
                          <Text
                            className={`${
                              !item.branded_box ? "hidden" : ""
                            } text--label`}
                          >
                            <CheckIcon className="icon--check" /> Add Branded
                            Boxes $0.40/Ea
                          </Text>
                        </TableCell>
                        <TableCell
                          className="text--cell-details text--bold"
                          align="center"
                        >
                          {item.quantity}
                        </TableCell>
                        <TableCell
                          className="text--cell-details"
                          align="center"
                        >
                          <p className={styles.cost}>
                            $
                            {Number(
                              item.product.cost - item.discount.amount
                            ).toFixed(2)}
                          </p>
                          {Number(item.discount.value) !== 0 && (
                            <>
                              <p className={styles.costNoDiscount}>
                                ${item.product.cost}
                              </p>
                              <Box className={styles.boxDiscountTag}>
                                <DiscountTag
                                  color="primary"
                                  key={item.product.id}
                                  label={`${Number(item?.discount?.value)}% off`}
                                />
                              </Box>
                            </>
                          )}
                        </TableCell>
                        <TableCell
                          className="text--cell-details"
                          align="center"
                        >
                          <p className={styles.cost}>
                            ${Number(item?.total_cost_with_savings).toFixed(2)}
                          </p>
                          {item.discount_savings !== "0.00" && (
                            <p className={styles.costNoDiscount}>
                              ${item.total_cost}
                            </p>
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          ) : (
            <>
              <Box className={styles.appendedHeader}>
                <Stack direction="row" gap="32px">
                  <Box
                    className="text--bold"
                    align="left"
                    classes={styles.cell}
                  >
                    <Text variant="body2" className={styles.title}>
                      Date
                    </Text>
                    <Text variant="body2" className={styles.peachText}>
                      {moment(order?.created_at).format("MMM DD, YYYY")}
                    </Text>
                  </Box>
                  <Box
                    className="text--bold"
                    align="left"
                    classes={styles.cell}
                  >
                    <Text variant="body2" className={styles.title}>
                      Order #
                    </Text>
                    <Text variant="body2" className={styles.peachText}>
                      {`10${order?.id}`}
                    </Text>
                  </Box>
                  <Box
                    className="text--bold"
                    align="left"
                    classes={styles.cell}
                  >
                    <Text variant="body2" className={styles.title}>
                      Qty
                    </Text>
                    <Text variant="body2" className={styles.peachText}>
                      {order?.number_of_items}
                    </Text>
                  </Box>
                  <Box
                    className="text--bold"
                    align="left"
                    classes={styles.cell}
                  >
                    <Text variant="body2" className={styles.title}>
                      Total
                    </Text>
                    <Text variant="body2" className={styles.peachText}>
                      ${order?.total}
                    </Text>
                  </Box>
                </Stack>
                <Box className="text--bold" align="center">
                  <OrderStatusBar
                    status={order.status}
                    type={order.order_type}
                  />
                </Box>
              </Box>
              <Divider className={styles.divider} />
              <OrderDetails order={order} />
            </>
          )}
        </div>
      </CardContent>

      {!isMobile && (
        <>
          <div className="tracking-number-container">
            <div className={styles.trackingContainer}>
              <TruckIcon className="icon--truck" />
              <Text className="text__tracking-header text--upper text--nunito">
                Tracking Info:
              </Text>
              {trackingStatus()}
            </div>
          </div>

          <Box className={styles.boxContainer}>
            <CartTotals cart={cartBill} step="invoice" />
          </Box>
        </>
      )}
    </Card>
  );
}

export default NewInventoryOrderCard;
