import { useCallback } from "react";
import { useSelector } from "react-redux";

const useCheckTask = () => {
  const shop = useSelector((state) => state.profile.shop);

  const isTaskDone = useCallback(
    (key) => {
      if (key === "brand_plate_created") {
        return shop?.brand_plate_created;
      }

      if (key === "count_sample_orders") {
        return shop?.count_sample_orders > 0;
      }

      if (key.includes("count_inventory_orders")) {
        const inventoryOrders = shop?.count_inventory_orders;
        const customProducts = shop?.count_number_of_custom_products;
        const total = inventoryOrders + customProducts;

        return total > 0;
      }

      return false;
    },
    [shop]
  );

  return { isTaskDone };
};

export default useCheckTask;
