import { SvgIcon } from "@mui/material";
import React from "react";

function EarthquakeIcon({ size = 40, color = "#D56F5B" }) {
  return (
    <SvgIcon
      data-testid="MoonIcon"
      className="icon icon--normal"
      viewBox="0 0 24 24"
      style={{ width: size, height: size }}
    >
      <path
        d="M9.025 22C8.79167 22 8.5875 21.9375 8.4125 21.8125C8.2375 21.6875 8.11667 21.5167 8.05 21.3L5.5 13H2V11H6.25C6.46667 11 6.6625 11.0625 6.8375 11.1875C7.0125 11.3125 7.13333 11.4833 7.2 11.7L8.85 17.075L12.025 2.8C12.075 2.56667 12.1917 2.375 12.375 2.225C12.5583 2.075 12.7667 2 13 2C13.2333 2 13.4417 2.07083 13.625 2.2125C13.8083 2.35417 13.925 2.54167 13.975 2.775L16.15 12.175L17.55 7.7C17.6167 7.48333 17.7375 7.3125 17.9125 7.1875C18.0875 7.0625 18.2833 7 18.5 7C18.7167 7 18.9083 7.05833 19.075 7.175C19.2417 7.29167 19.3667 7.45 19.45 7.65L20.7 11H22V13H20C19.7833 13 19.5917 12.9417 19.425 12.825C19.2583 12.7083 19.1333 12.55 19.05 12.35L18.575 11.075L16.95 16.3C16.8833 16.5167 16.7583 16.6917 16.575 16.825C16.3917 16.9583 16.1833 17.0167 15.95 17C15.7167 16.9833 15.5167 16.9042 15.35 16.7625C15.1833 16.6208 15.075 16.4417 15.025 16.225L13 7.525L9.975 21.225C9.925 21.4583 9.8125 21.6417 9.6375 21.775C9.4625 21.9083 9.25833 21.9833 9.025 22Z"
        fill={color}
      />
    </SvgIcon>
  );
}

export default EarthquakeIcon;
