import React from "react";

// mui:
import { Card, Grid } from "@mui/material";

import styles from "./AccountCard.module.scss";

function AccountCard({ children, ...props }) {
  return (
    <div className={styles.accountCard} {...props}>
      <Grid container spacing={2} className={styles.card}>
        <Grid item xs={12} md={12}>
          <Card className={`card ${styles.cardWrapper}`} variant="outlined">
            {children}
          </Card>
        </Grid>
      </Grid>
    </div>
  );
}

export default AccountCard;
