import { SvgIcon } from "@mui/material";
import React from "react";

function ProductIcon({ size, color = "currentColor" }) {
  return (
    <SvgIcon
      data-testid="ProductIcon"
      className="icon icon--normal"
      viewBox="0 0 40 41"
      style={{ width: size, height: size }}
    >
      <g>
        <path
          d="M25.7325 19.1533V1.81656C25.7324 1.45598 25.625 1.10359 25.424 0.804232C25.223 0.504878 24.9375 0.272103 24.6038 0.135491C24.2701 -0.00112116 23.9033 -0.0353918 23.5501 0.0370697C23.1969 0.109531 22.8732 0.28541 22.6203 0.542371L13.9518 9.21076C13.7259 9.43496 13.5467 9.70187 13.4248 9.9959C13.3029 10.2899 13.2406 10.6053 13.2417 10.9236V19.1533H11.1111V40.0411H27.8213V19.1533H25.7325ZM17.3774 11.6337L21.555 7.4562V19.1533H17.3774V11.6337ZM23.6438 35.8635H15.2887V23.3309H23.6438V35.8635Z"
          fill={color}
        />
      </g>
    </SvgIcon>
  );
}

export default ProductIcon;
