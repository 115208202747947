import { Box } from "@mui/material";
import { QUERY } from "api";
import { updateReadAllNotifications } from "api/endpoints/profileApi";
import queryClient from "api/queryClient";
import Button from "components/Buttons/Button";
import DoubleCheckIcon from "components/Icons/DoubleCheckIcon";
import NotificationBellIcon from "components/Icons/NotificationBellIcon";
import Text from "components/Text";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useMutation } from "react-query";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ROUTE_PATHS from "routes/routePaths";
import cn from "utils/cn";
import NotificationCard from "./NotificationCard";
import styles from "./NotificationCenter.module.scss";

function NotificationCenter() {
  const navigate = useNavigate();
  const popoverRef = useRef(null);

  const [anchorEl, setAnchorEl] = useState(null);
  const notifications = useSelector((state) => state.profile.notifications);
  // const [selectedFilter, setSelectedFilter] = useState("all");

  const readAllNotificationMutation = useMutation(updateReadAllNotifications, {
    onSuccess: () => {
      queryClient.refetchQueries([QUERY.getNotifications]);
    },
  });

  const unreadNotifications = useMemo(
    () => notifications.filter((value) => !value?.is_read),
    [notifications]
  );

  const handleDocumentClick = (e) => {
    // this handles closing outside of notification window
    if (
      popoverRef.current &&
      !popoverRef.current.contains(e.target) &&
      anchorEl &&
      !anchorEl.contains(e.target)
    ) {
      setAnchorEl(null);
    }
  };

  const onCloseNavigationCenter = useCallback(() => {
    setAnchorEl(null);
  }, [setAnchorEl]);

  useEffect(() => {
    if (anchorEl) {
      document.addEventListener("click", handleDocumentClick);
    } else {
      document.removeEventListener("click", handleDocumentClick);
    }

    return () => document.removeEventListener("click", handleDocumentClick);
  }, [anchorEl]);

  return (
    <Box className={styles.notificationCenter}>
      <Box
        className={styles.iconWrapper}
        onClick={(e) => {
          if (anchorEl) {
            setAnchorEl(null);
          } else {
            setAnchorEl(e.currentTarget);
          }
        }}
      >
        <NotificationBellIcon active={!!anchorEl} />
        {unreadNotifications.length > 0 && (
          <Box
            className={cn(
              styles.iconBadge,
              unreadNotifications.length > 10 && styles.iconBadgeBig
            )}
            px={unreadNotifications.length > 99 && "4px"}
          >
            {unreadNotifications.length}
            {unreadNotifications.length > 99 && "+"}
          </Box>
        )}
      </Box>

      {anchorEl && (
        <Box
          ref={popoverRef}
          className={cn(styles.popoverContent)}
          style={{ top: anchorEl.offsetTop + 40 }}
        >
          <Box className={styles.popoverArrow} />

          <Box className={cn(styles.popoverBody)}>
            <Box className={styles.popoverArrowCover} />

            <Box className={styles.notificationBody}>
              <Box className={styles.notificationHeader}>
                <Text variant="h2" className={styles.title}>
                  Notifications
                </Text>

                <Text
                  onClick={() => {
                    navigate(ROUTE_PATHS.PROFILE_SETTINGS);
                    setAnchorEl(null);
                  }}
                  variant="body1"
                  className={styles.action}
                >
                  Edit settings
                </Text>
              </Box>

              {notifications.length > 0 && (
                <Box className={styles.notificationActions}>
                  <div />
                  {/* <Box className={styles.selectWrapper}>
                  <Text variant="caption" className={styles.selectLabel}>
                    Filter
                  </Text>
                  <FormControl style={{ position: "relative" }}>
                    <Select
                      size="small"
                      value={selectedFilter}
                      onChange={(e) => setSelectedFilter(e.target.value)}
                      onClick={(e) => e.stopPropagation()}
                      className={styles.notificationFilter}
                      MenuProps={{
                        container: popoverRef.current,
                        disablePortal: true,
                        classes: {
                          paper: styles.selectMenu,
                        },
                      }}
                    >
                      {NOTIFICATION_CENTER_FILTER.map((filter) => (
                        <MenuItem key={filter.value} value={filter.value}>
                          {filter.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box> */}

                  <Button
                    onClick={() => readAllNotificationMutation.mutate()}
                    disabled={
                      unreadNotifications.length === 0 ||
                      readAllNotificationMutation.isLoading
                    }
                    loading={readAllNotificationMutation.isLoading}
                    size="small"
                    color="secondary"
                  >
                    <Box className={styles.markAllReadButton}>
                      <DoubleCheckIcon size={14} />
                      <span>Mark all as read</span>
                    </Box>
                  </Button>
                </Box>
              )}

              {notifications.length > 0 ? (
                <Box>
                  <Text
                    // onClick={() => sendNotification()}
                    variant="caption"
                    className={styles.notificationsCount}
                  >
                    {unreadNotifications.length} unread
                  </Text>
                  <Box className={styles.notificationCardsWrapper}>
                    <Box className={styles.notificationCards}>
                      {notifications.map((notification) => (
                        <NotificationCard
                          data={notification}
                          key={notification.id}
                          onCloseNavigationCenter={onCloseNavigationCenter}
                        />
                      ))}
                    </Box>
                  </Box>
                </Box>
              ) : (
                <Box className={styles.noNotificationCard}>
                  <span>You have no notifications</span>
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
}

export default NotificationCenter;
