import React from "react";
import { useNavigate } from "react-router-dom";

// MUI components:
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { Box, Grid, Skeleton, Stack } from "@mui/material";

// Custom components:
import PageHeader from "components/PageHeader/PageHeader";
import Text from "components/Text/Text";
import CustomerOrderCard from "views/CustomerOrdersView/components/CustomerOrdersDesktopView/CustomerOrderCard";
import InventoryOrderCard from "views/InventoryOrdersView/components/InventoryOrderCard";
import SampleOrderCard from "views/SampleOrdersView/components/SampleOrderCard";

import { ORDER_TYPE_INVENTORY, ORDER_TYPE_SAMPLE } from "constants/constants";

import { SMALL } from "constants/breakpoints";
import { useMediaQuery } from "react-responsive";
import cn from "utils/cn";
import InventoryOrdersMobileView from "views/InventoryOrdersView/components/InventoryOrdersMobileView";
import SampleOrderMobileView from "views/SampleOrdersView/components/SampleOrderMobileView";
import styles from "./OrderProductsMobile.module.scss";

const ITEMS_PER_PAGE = 10;

export default function OrderProductsMobile({
  isLoading,
  isFetching,
  order,
  refetch,
}) {
  const isMobile = useMediaQuery({ maxWidth: SMALL });
  const navigate = useNavigate();

  // TODO: Refactor this to a separate Hook:
  const renderOrderCard = (order) => {
    if (order?.order_type === ORDER_TYPE_SAMPLE) {
      // return <SampleOrderCard order={order} />;
      return <SampleOrderCard order={order} />;
    }
    if (order?.order_type === ORDER_TYPE_INVENTORY) {
      return <InventoryOrderCard order={order} />;
    }

    return <CustomerOrderCard order={order} refetch={refetch} />;
  };

  const handlePrevious = () => {
    navigate(-1);
  };

  return (
    <>
      <Grid className="page-header">
        <PageHeader title="">
          <div
            className={styles.wrapperOrderDetailsCta}
            onClick={handlePrevious}
          >
            <div className={styles.backBtn}>
              <KeyboardBackspaceIcon />
            </div>
            <div className={styles.text}>ORDER DETAILS</div>
          </div>
        </PageHeader>
      </Grid>
      <div className={cn("orders-view", styles.ordersViewContent)}>
        {!isLoading || isFetching ? (
          <Grid container>{renderOrderCard(order)}</Grid>
        ) : (
          <Grid container>
            <div className="order-skeleton-container">
              <Box sx={{ width: "100%" }}>
                <Grid container>
                  <div className={styles.orderSkeletonContainer}>
                    <Stack spacing={2}>
                      <Skeleton variant="rectangular" />
                      <Skeleton variant="rectangular" height={40} />
                      <Skeleton variant="rectangular" height={118} />
                    </Stack>
                  </div>
                  <div className={styles.ordersSkeletonContainer}>
                    <Stack spacing={2}>
                      <Skeleton variant="rectangular" />
                      <Skeleton variant="rectangular" height={40} />
                      <Skeleton variant="rectangular" height={118} />
                    </Stack>
                  </div>
                </Grid>
              </Box>
            </div>
          </Grid>
        )}
      </div>
    </>
  );
}
