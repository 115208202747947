import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import React, { useMemo } from "react";
import cn from "utils/cn";

import styles from "./Text.module.scss";

export default function Text({
  children,
  variant,
  color,
  fontSize,
  className,
  ...props
}) {
  // @rain note: need to work on this - seems like we're not using our typography properly
  // meet up with Karen to have one typography source of truth on figma and apply it here.
  // next step it to update typography everywhere in the app
  const variantClassName = useMemo(() => {
    switch (variant) {
      case "title":
        return styles.titleVariant;

      case "subtitle":
        return styles.subtitleVariant;

      case "description":
        return styles.descriptionVariant;

      default:
        return "";
    }
  }, [variant]);

  return (
    <Typography
      variant={variant}
      fontSize={`${fontSize || 12}px`}
      data-testid="Text"
      className={cn(`text--${color}`, className, variantClassName)}
      {...props}
    >
      {children}
    </Typography>
  );
}

Text.propTypes = {
  variant: PropTypes.oneOf([
    "body1",
    "body2",
    "button",
    "caption",
    "h1",
    "h2",
    "h3",
    "h4",
    "h5",
    "h6",
    "title",
  ]),

  color: PropTypes.string,
  className: PropTypes.string,
};

Text.defaultProps = {
  variant: "body1",
  color: "",

  className: "",
};
