import Chip from "@mui/material/Chip";
import CircularProgress from "@mui/material/CircularProgress";
import {
  connectStore,
  connectWixStore,
  connectWoocommerceStore,
  getUser,
} from "api";
import bigCommerceLogo from "assets/images/big-commerce-logo.png";
import shopifyLogo from "assets/images/shopify-logo.png";
import tikTokLogo from "assets/images/tiktok_logo.png";
import wixLogo from "assets/images/wix-logo.png";
import wooCommerceLogo from "assets/images/woocommerce_logo.png";
import Text from "components/Text";
import { useAnalytics, useProfile } from "hooks";
import useChangePlanModal from "hooks/useChangePlanModal";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { setProfile } from "state/profileSlice";

import blankaApiLogo from "assets/images/blanka-api.svg";

import { CrownIcon } from "components/Icons";
import { GenericSuccessNotification } from "components/Notifications";
import styles from "./AccountConnectStore.module.scss";

export default function AccountConnectStore() {
  const dispatch = useDispatch();
  const analytics = useAnalytics();
  const shop = useSelector((state) => state.profile.shop);
  const [, setError] = useState();
  const [loading, setLoading] = useState(false);

  const { onOpenModal } = useChangePlanModal();
  const profileHook = useProfile();

  const handleClick = (type) => {
    analytics.sendEvent(analytics.ACCOUNT_CONNECTION_SQUARE_CLICK, {
      type,
    });
  };

  useEffect(() => {
    if (!shop) {
      return;
    }

    const connectShopify = async (store) => {
      try {
        await connectStore({ token: store.token, url: store.url });
        const response = await getUser();

        dispatch(setProfile(response.data));

        toast.success(
          <GenericSuccessNotification text="Your store is now connected to Blanka." />
        );

        sessionStorage.removeItem("shopify_store");
        setLoading(false);
      } catch (e) {
        setError(
          "There was a problem connecting your store, please try again later"
        );
        sessionStorage.removeItem("shopify_store");
        setLoading(false);
      }
    };

    const connectWoocommerce = async (store) => {
      try {
        await connectWoocommerceStore({ token: store.token, url: store.url });
        const response = await getUser();

        dispatch(setProfile(response.data));

        toast.success(
          <GenericSuccessNotification text="Your store is now connected to Blanka." />
        );

        sessionStorage.removeItem("woocommerce_store");
        setLoading(false);
      } catch (e) {
        setError(
          "There was a problem connecting your store, please try again later"
        );
        sessionStorage.removeItem("woocommerce_store");
        setLoading(false);
      }
    };

    const connectWix = async (store) => {
      try {
        await connectWixStore({ url: store.url });
        const response = await getUser();

        dispatch(setProfile(response.data));

        toast.success(
          <GenericSuccessNotification text="Your store is now connected to Blanka." />
        );

        sessionStorage.removeItem("wix_store");
        setLoading(false);
      } catch (e) {
        setError(
          "There was a problem connecting your store, please try again later"
        );
        sessionStorage.removeItem("wix_store");
        setLoading(false);
      }
    };

    // check for shopify store connection
    const shopifyStore = JSON.parse(sessionStorage.getItem("shopify_store"));
    if (shopifyStore) {
      setLoading(true);
      connectShopify(shopifyStore);
    }

    // check for woocomerce store connection
    const woocommerceStore = JSON.parse(
      sessionStorage.getItem("woocommerce_store")
    );
    if (woocommerceStore) {
      setLoading(true);
      connectWoocommerce(woocommerceStore);
    }

    // check for wix store connection
    const wixStore = JSON.parse(sessionStorage.getItem("wix_store"));
    if (wixStore) {
      setLoading(true);
      connectWix(wixStore);
    }
  }, []);

  return (
    <>
      <Text variant="title">Connect your online store</Text>
      {loading && (
        <div className={styles.loading}>
          <CircularProgress />
        </div>
      )}
      {!loading && (
        <div className={styles.storesContainer}>
          <div
            className={`${styles.storeContainer} ${styles.storeContainerActive}`}
          >
            <a
              target="_blank"
              href="https://apps.shopify.com/blanka"
              rel="noreferrer"
              onClick={() => handleClick("shopify")}
            >
              <img src={shopifyLogo} alt="shopify logo" />
            </a>
            <Text
              fontSize={12}
              className={`text--nunito text--bold ${styles.textShopType}`}
            >
              Shopify
            </Text>
          </div>
          <div
            className={`${styles.storeContainer} ${styles.storeContainerActive}`}
          >
            <a
              target="_blank"
              href="https://wordpress.org/plugins/blanka/"
              rel="noreferrer"
              onClick={() => handleClick("woocommerce")}
            >
              <img src={wooCommerceLogo} alt="woocommerce logo" />
            </a>

            <Text
              fontSize={12}
              className={`text--nunito text--bold ${styles.textShopType}`}
            >
              WooCommerce
            </Text>
          </div>
          <div
            className={`${styles.storeContainer} ${styles.storeContainerActive}`}
          >
            <a
              target="_blank"
              href="https://www.wix.com/app-market/beauty-dropshipping-by-blanka"
              rel="noreferrer"
              onClick={() => handleClick("wix")}
            >
              <img src={wixLogo} alt="wix logo" width="125" />
            </a>

            <Text
              fontSize={12}
              className={`text--nunito text--bold ${styles.textShopType}`}
            >
              Wix
            </Text>
          </div>
          <div
            className={`${styles.storeContainer} ${styles.storeContainerActive}`}
          >
            <Chip label="Join Waitlist" className={styles.waitlistTag} />
            <a
              target="_blank"
              href="https://tk8w7r4gjq0.typeform.com/to/qnsk2zo3"
              rel="noreferrer"
              onClick={() => handleClick("tiktok")}
            >
              <img src={tikTokLogo} alt="Tiktok Logo" />
            </a>

            <Text
              fontSize={12}
              className={`text--nunito text--bold ${styles.textShopType}`}
            >
              TikTok Shop
            </Text>
          </div>
          <div
            className={`${styles.storeContainer} ${styles.storeContainerActive}`}
            style={{ marginRight: "0px" }}
          >
            <div className={styles.vip}>
              <CrownIcon isPartialLength />
            </div>
            <a
              target="_blank"
              href="https://faq.blankabrand.com/en/articles/8896232-does-blanka-have-a-public-api"
              rel="noreferrer"
              onClick={handleClick("blanka_api")}
            >
              <img src={blankaApiLogo} alt="Blanka API Logo" />
            </a>

            <Text
              fontSize={12}
              className={`text--nunito text--bold ${styles.textShopType}`}
            >
              Blanka API
            </Text>
          </div>
        </div>
      )}
    </>
  );
}
