import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";

// Material-UI
import { Box, Grid } from "@mui/material";

// Custom components
import BrandSetupMockup from "components/BrandSetup/BrandSetupMockup";
import BrandSetupSteps from "components/BrandSetup/BrandSetupSteps";
import BrandSetupWrapper from "components/BrandSetup/BrandSetupWrapper";
import Button from "components/Buttons/Button";
import Text from "components/Text";
import { BRAND_PLATE_MOCKUPS } from "constants/brandPlate";
import { SMALL } from "constants/breakpoints";
import BrandSetupLogoExampleModal from "components/BrandSetup/BrandSetupLogoExampleModal";

// API
import { QUERY } from "api";
import { createProducts } from "api/endpoints/generatorApi";
import { getInProgressLogos } from "api/endpoints/shopApi";

// State
import { setFetchedLogos } from "state/brandSetupSlice";

// Hooks
import { useAnalytics } from "hooks";

import { ArrowBack } from "@mui/icons-material";
import queryClient from "api/queryClient";
import { BRAND_SETUP_STEPS } from "constants/constants";
import { useShopMutations } from "hooks/mutations/useShopMutations";
import styles from "./BrandSetupMockupsCheckoutView.module.scss";

export default function BrandSetupMockupsCheckoutView() {
  const analytics = useAnalytics();

  const isMobile = useMediaQuery({ maxWidth: SMALL });
  const dispatch = useDispatch();

  const logos = useSelector((state) => state.brandSetup.logos);
  const shop = useSelector((state) => state.profile.shop);

  const [loading, setLoading] = useState(false);
  const [activeLogoType, setActiveLogoType] = useState(null);
  const { updateShopMutation } = useShopMutations();

  const [openLogoExamplesModal, setOpenLogoExamplesModal] = useState(false);

  const handleOpenLogoExamplesModal = () => {
    setOpenLogoExamplesModal(true);
  };

  const handleCloseLogoExamplesModal = () => {
    setOpenLogoExamplesModal(false);
  };

  const onNext = async () => {
    setLoading(true);

    try {
      await createProducts();
      await queryClient.refetchQueries([QUERY.getUser]);
      await updateShopMutation.mutateAsync({
        id: shop.id,
        brand_setup_step: BRAND_SETUP_STEPS.REVIEW,
      });
      analytics.sendEvent(analytics.BRAND_SETUP_CREATE_MOCKUP);

      setLoading(false);
    } catch (error) {
      setLoading(false);
      // console.error(error);
    }
  };

  const handleLogoChange = (logoType) => {
    setActiveLogoType(logoType);
  };

  useQuery([QUERY.getInProgressLogos], getInProgressLogos, {
    onSuccess: (response) => {
      dispatch(setFetchedLogos(response.data));
    },
  });

  useEffect(() => {}, [logos]);

  const renderBackComponent = () => (
    <Box
      onClick={() => {
        updateShopMutation.mutate({
          id: shop.id,
          brand_setup_step: BRAND_SETUP_STEPS.UPLOAD,
        });
      }}
      mb={1}
      display="flex"
      alignItems="center"
      gap="4px"
      className={styles.backNav}
    >
      <ArrowBack className={styles.backNavIcon} />
      {!isMobile && (
        <Text fontWeight={600} color="gray" fontSize={12} letterSpacing="1px">
          BACK
        </Text>
      )}
    </Box>
  );

  return (
    <BrandSetupWrapper
      pageTitle="My Brand Setup"
      wrapperClassName={styles.brandSetupMockupsCheckoutView}
      leftAction={isMobile && renderBackComponent()}
    >
      <Box className={styles.leftSide}>
        {!isMobile && renderBackComponent()}
        <Text variant="h1" className={styles.title}>
          Create your branded products!
        </Text>
        <Text className={styles.subsection1}>
          We recommend uploading alternative colors and variations of your logo
          for different packaging types.
        </Text>
        <Text onClick={handleOpenLogoExamplesModal} className={styles.link}>
          See logo examples
        </Text>

        {isMobile && (
          <Box mt={4}>
            <BrandSetupSteps />
          </Box>
        )}
        <Grid mt={4} className={styles.gridWrapper}>
          {BRAND_PLATE_MOCKUPS.map((mockup) => (
            <BrandSetupMockup
              key={mockup.logo_type}
              mockup={mockup}
              onChangeLogo={() => handleLogoChange(mockup.logo_type)}
              targetLogoType={activeLogoType}
              logoObj={
                logos.find((logo) => logo.logo_type === mockup.logo_type) || {
                  logo: null,
                  logo_type: mockup.logo_type,
                }
              }
            />
          ))}
          <BrandSetupLogoExampleModal
            open={openLogoExamplesModal}
            handleClose={handleCloseLogoExamplesModal}
          />
        </Grid>
      </Box>
      <Box className={styles.rightSide}>
        {!isMobile && <BrandSetupSteps />}
        <Box mt={4} className={!isMobile ? "" : styles.bottomNavigation}>
          <Button
            onClick={onNext}
            color="primary"
            variant="contained"
            hasChevron
            size="small"
            disabled={loading}
            loading={loading}
          >
            Create Mockups
          </Button>
        </Box>
      </Box>
    </BrandSetupWrapper>
  );
}
