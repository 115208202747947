// useAccountAddresses.js
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";

import {
  setIsAdding,
  setIsEditing,
  setAddressToShip,
  setAddressToEdit,
  setAddressToEditById,
  // selectors:
  selectAddresses,
  selectorMaximumAmountOfAddresses,
  selectAddressToShip,
  selectIsEditing,
  selectIsAdding,
  // endpoints:
  createShippingAddressAsync,
  deleteShippingAddressAsync,
  updateShippingAddressAsync,
} from "state/accountAddressesSlice";

// util function to clean the phone number by removing spaces, parentheses, and hyphens
const cleanPhoneNumber = (phoneNumber) => phoneNumber.replace(/[\s().-]/g, "");

const useAccountAddresses = () => {
  const dispatch = useDispatch();
  const { reset } = useForm();

  const addresses = useSelector(selectAddresses);
  const addressToShip = useSelector(selectAddressToShip);
  const isEditing = useSelector(selectIsEditing);
  const isAdding = useSelector(selectIsAdding);
  const maxAddresses = useSelector(selectorMaximumAmountOfAddresses);

  const handleOnClickAddAddress = () => {
    dispatch(setIsAdding(true));
    dispatch(setIsEditing(false));
  };

  const handleOnClickEditAddress = (id) => {
    dispatch(setIsEditing(true));
    dispatch(setIsAdding(false));
    dispatch(setAddressToEditById(id));
  };

  const handleOnRemoveAddress = (id) => {
    dispatch(deleteShippingAddressAsync(id));
    toast.success("Address has been removed.");
  };

  const handleOnSubmitForm = async (data) => {
    try {
      const transformedData = {
        ...data,
        address_one: data.address,
        address_two: data.unit,
        phone: cleanPhoneNumber(data.phone),
      };

      // Remove the original 'address' and 'unit' fields
      delete transformedData.address;
      delete transformedData.unit;

      let result;

      if (isEditing) {
        result = await dispatch(
          updateShippingAddressAsync({
            id: transformedData.id,
            data: transformedData,
          })
        ).unwrap(); // Use unwrap() to get the actual payload
      }

      if (isAdding) {
        result = await dispatch(
          createShippingAddressAsync(transformedData)
        ).unwrap(); // Use unwrap() to get the actual payload
      }

      // Only clean up state after successful API call
      dispatch(setIsEditing(false));
      dispatch(setIsAdding(false));
      dispatch(setAddressToEdit({}));

      // Show success message
      toast.success(
        isEditing
          ? "Address updated successfully."
          : "Address added successfully."
      );

      return result; // Important: return the result
    } catch (error) {
      toast.error(
        isEditing
          ? "Failed to update address. Please try again."
          : "Failed to add address. Please try again."
      );
      throw error; // Important: propagate the error
    }
  };

  const handleOnCancelForm = () => {
    reset();
    dispatch(setIsEditing(false));
    dispatch(setIsAdding(false));
    dispatch(setAddressToEdit({}));
  };

  const handleOnSelectAddressToShip = (address) => {
    dispatch(setAddressToShip(address));
  };

  return {
    addresses,
    addressToShip,
    maxAddresses,
    isAdding,
    isEditing,
    handleOnClickAddAddress,
    handleOnClickEditAddress,
    handleOnRemoveAddress,
    handleOnSubmitForm,
    handleOnCancelForm,
    handleOnSelectAddressToShip,
  };
};

export default useAccountAddresses;
