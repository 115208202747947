import React, { useMemo, useState } from "react";
import { useQuery } from "react-query";
import { useMediaQuery } from "react-responsive";
import { useLocation } from "react-router-dom";

// Custom components:
import { SMALL } from "constants/breakpoints";

import { Grid, Skeleton, Stack } from "@mui/material";
import {
  getCustomerOrderDetails,
  getInventoryOrderDetails,
  getSampleOrderDetails,
} from "api/endpoints/ordersApi";
import PageHeader from "components/PageHeader";
import OrderProductsDesktop from "./components/OrderProductsDesktop/OrderProductsDesktop";
import OrderProductsMobile from "./components/OrderProductsMobile/OrderProductsMobile";

import styles from "./OrderProductsView.module.scss";

export default function OrderProductsView() {
  const orderId = localStorage.getItem("lastViewedId");
  const location = useLocation();

  const isMobile = useMediaQuery({ maxWidth: SMALL });

  // Extract the parent segment from the path
  const pathSegments = location.pathname.split("/").filter(Boolean);
  const parentRoute = pathSegments[0];

  // Get the order ID (compatible with both params)
  const orderType = parentRoute;

  // Fetch orders dynamically based on the order type
  const { isLoading, isFetching, refetch, data } = useQuery(
    [orderId, "order"],
    () => {
      switch (orderType) {
        case "orders":
          return getCustomerOrderDetails(orderId);

        case "inventory-orders":
          return getInventoryOrderDetails(orderId);

        case "sample-orders":
          return getSampleOrderDetails(orderId);

        default:
          return getCustomerOrderDetails(orderId);
      }
    },
    {
      enabled: !!orderId,
      refetchOnWindowFocus: false,
    }
  );

  const order = useMemo(() => data?.data, [data]);

  if (!order) {
    return (
      <>
        <Grid className="page-header" container>
          <PageHeader title="ORDER DETAILS" />
        </Grid>
        <Stack spacing={2}>
          <Skeleton variant="rectangular" />
          <Skeleton variant="rectangular" height={40} />
          <Skeleton variant="rectangular" height={118} />
        </Stack>
      </>
    );
  }

  return (
    <div
      className={styles.inventoryOrdersView}
      key={`${orderId}-${new Date().getTime()}`}
    >
      {!isMobile ? (
        <OrderProductsDesktop
          isLoading={isLoading}
          isFetching={isFetching}
          order={order}
          refetch={refetch}
        />
      ) : (
        <OrderProductsMobile
          isLoading={isLoading}
          isFetching={isFetching}
          order={order}
          refetch={refetch}
        />
      )}
    </div>
  );
}
