import React, { useState } from "react";
import Cookies from "js-cookie";
import Text from "components/Text";
import Button from "components/Buttons/Button";
import PlanCard from "components/Cards/PlanCard";
import { TRY_FOR_FREE, SHOP_ONBOARDING_SHOPIFY } from "constants/constants";
import { PLAN_INFO } from "constants/plans";
import { useSearchParams, useLocation } from "react-router-dom";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { updateShop, updatePlan } from "api";
import { useSelector, useDispatch } from "react-redux";
import Checkbox from "@mui/material/Checkbox";
import Box from "@mui/material/Box";
import { setShop, setOnboardingStep } from "state/profileSlice";
import { SMALL } from "constants/breakpoints";
import PlanSwitch from "components/Switch/components/PlanSwitch";
import { useMediaQuery } from "react-responsive";
import BlankaLogoImg from "assets/images/logo.png";
import { useAnalytics } from "hooks";
import PlanTable from "../../../components/Tables/PlanTable";
import OnboardingWrapper from "../OnboardingWrapper";

import styles from "./ShopifyOnboardingStepTwo.module.scss";

export default function ShopifyOnboardingStepTwo() {
  const dispatch = useDispatch();
  const location = useLocation();
  const [checked, setChecked] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const shop = useSelector((state) => state.profile.shop);
  const profile = useSelector((state) => state.profile.profile);
  const [loading, setLoading] = useState(false);
  const [switchChecked, setSwitchChecked] = useState(true);
  const analytics = useAnalytics();

  const isMobile = useMediaQuery({ maxWidth: SMALL });

  const handleChange = (event) => {
    setSwitchChecked(event.target.checked);
  };

  const handlePaidPlanSelect = async (id) => {
    setLoading(true);

    // console.log(id);

    let affiliateID = null;
    // check if user has come from an affiliate link

    try {
      affiliateID = Cookies.get("irclickid");
    } catch (err) {
      affiliateID = null;
    }

    if (shop.shop_signup_type === SHOP_ONBOARDING_SHOPIFY) {
      await updateShop(shop.id, {
        onboarding_step: 3,
        plan: id,
        affiliate_id: affiliateID || "",
      });

      const response = await updatePlan(id);

      window.location.href = response.data.confirmation_url;

      analytics.sendEvent(analytics.ONBOARDING_SELECT_PLAN, { plan_id: id });
    } else {
      const response = await updateShop(shop.id, {
        onboarding_step: 3,
        plan: id,
        affiliate_id: affiliateID || "",
      });
      analytics.sendEvent(analytics.ONBOARDING_SELECT_PLAN, { plan_id: id });
    }
  };

  const handleFreePlanSelect = async (id) => {
    setLoading(true);

    const response = await updateShop(shop.id, {
      onboarding_step: 4,
      plan: id,
    });

    analytics.sendEvent(analytics.ONBOARDING_COMPLETE, {
      plan_id: id,
    });

    analytics.sendEvent(analytics.ONBOARDING_SELECT_PLAN, { plan_id: id });

    dispatch(setShop(response.data));
  };

  return (
    <OnboardingWrapper>
      <div className={styles.onboardingStepTwo} data-testid="OnboardingStepTwo">
        {shop.has_trial && (
          <Text className={styles.modalSubTitle}>
            Try a 14-day trial for free now!
          </Text>
        )}

        <Box className={styles.boxContainer}>
          <Text
            className={`text__body ${
              switchChecked ? "text--nunito" : "text--peach text--bold"
            }`}
          >
            Annually
          </Text>
          <PlanSwitch handleChange={handleChange} checked={switchChecked} />
          <Text
            className={`text__body ${
              !switchChecked ? "text--nunito" : "text--peach text--bold"
            }`}
          >
            Monthly
          </Text>
        </Box>

        <div className={styles.planCardsContainer}>
          <PlanTable
            onPaidPlanSelect={handlePaidPlanSelect}
            onFreePlanSelect={handleFreePlanSelect}
            loading={loading}
            constant={TRY_FOR_FREE}
            switchChecked={switchChecked}
            allowAllSelect
          />
        </div>
        <Text className={styles.warning}>
          <span>* applies to published products</span>
          <span>
            **1 free Brand Setup is included with each plan. Subsequent Brand
            Setups are an additional cost.
          </span>
        </Text>
      </div>
    </OnboardingWrapper>
  );
}
