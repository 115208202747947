import ModalWrapper from "components/ModalWrapper/ModalWrapper";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Box, CircularProgress, Skeleton, Stack } from "@mui/material";
import { createSetupIntent, getUser, QUERY, updatePlan } from "api";
import queryClient from "api/queryClient";
import BlackFridayModalNotification from "components/BlackFriday/BlackFridayModalNotification/BlackFridayModalNotification";
import FreeTrialTag from "components/Labels/FreeTrialTag";
import PlanSwitch from "components/Switch/components/PlanSwitch";
import PlanTable from "components/Tables/PlanTable";
import Text from "components/Text";
import { SMALL } from "constants/breakpoints";
import { SHOP_ONBOARDING_SHOPIFY, TRY_FOR_FREE } from "constants/constants";
import {
  ANNUAL_PLAN_IDS,
  FREE_PLAN_ID,
  PLAN_FREE_ID,
  PLAN_INFO,
} from "constants/plans";
import {
  useFeatureFlags,
  usePlanChange,
  useProfile,
  useQueryParam,
} from "hooks";
import useChangePlanModal from "hooks/useChangePlanModal";
import profileHook from "hooks/useProfile";
import { useMutation } from "react-query";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import ROUTE_PATHS from "routes/routePaths";
import {
  choosenPlan,
  resetDisplayUpgradeMessage,
  setChoosenPlan,
  updateDowngradeStatus,
} from "state/changePlanSlice";
import UpdatePaymentStripe from "../UpdatePaymentStripe";
import styles from "./ChangePlanModal.module.scss";
import ChangePlanPaymentConfirmation from "./components/ChangePlanPaymentConfirmation";

function NewChangePlanModal(props) {
  const navigate = useNavigate();
  const isMobile = useMediaQuery({ maxWidth: SMALL });
  const dispatch = useDispatch();
  const { isOnTrial, isShopifyBilling, displayBlackFridayBanner } =
    useProfile();
  const { isDowngrading } = usePlanChange();
  const { cadence } = props;
  const featureFlagsHook = useFeatureFlags();

  // store states
  const {
    title,
    subtitle,
    isFreePlanLimit,
    freePlanLimitSubText,
    isOpen,
    onSuccess,
    onCloseModal,
    cadence: stateCadence,
  } = useChangePlanModal();
  const shop = useSelector((state) => state.profile.shop);

  // states
  // states
  const [switchChecked, setSwitchChecked] = useState(() => {
    if (cadence === "annual") {
      return true;
    }
    return !ANNUAL_PLAN_IDS.includes(shop.plan);
  });
  const [loading, setLoading] = useState(false);
  const [clientSecret, setClientSecret] = useState(null);
  const [pendingPlanId, setPendingPlanId] = useState(null);
  const [confirmModal, setConfirmModal] = useState(false);
  const [setupCardModal, setSetupCardModal] = useState(false);
  const queryParams = useQueryParam();

  const isDowngradeSuccess = useMemo(
    () => queryParams.get("downgrade") === "success",
    []
  );

  const planCadence = useMemo(() => {
    const cadence = queryParams.get("cadence");

    if (stateCadence === "annual") {
      return false;
    }

    if (cadence) {
      if (cadence === "monthly") {
        return true;
      }

      return false;
    }

    return !ANNUAL_PLAN_IDS.includes(shop.plan);
  }, [stateCadence]);

  useEffect(() => {
    setSwitchChecked(planCadence);
  }, [planCadence]);

  useEffect(() => {
    if (isDowngradeSuccess) {
      onCloseModal();
      toast.success("Success! Your account has been updated.");
    }
  }, [isDowngradeSuccess]);

  // mutations
  const changePlanMutation = useMutation(updatePlan);

  const handlePlanSwitch = (event) => {
    setSwitchChecked(event.target.checked);
  };

  const setSetupIntent = async (id) => {
    await getUser();
    const response = await createSetupIntent(PLAN_INFO[id].price);
    setClientSecret(response.data.client_secret);
  };

  const executeChangePlanMutation = (id) => {
    changePlanMutation.mutate(id, {
      onSuccess: (response) => {
        if (shop.shop_signup_type === SHOP_ONBOARDING_SHOPIFY) {
          window.location.href = response.data.confirmation_url;
          return;
        }

        queryClient.refetchQueries(QUERY.getUser).then(() => {
          setLoading(false);
          toast.success("Success! Your account has been updated.");
          setConfirmModal(false);
          // this onSuccess comes from component that called this modal incase you need to trigger a function within that component
          if (onSuccess) {
            onSuccess();
          }

          onCloseModal();
        });
      },
      onError: (err) => {
        setLoading(false);
      },
    });
  };

  const onSuccessUpdateCard = async () => {
    setLoading(pendingPlanId);
    setSetupCardModal(false);
    await executeChangePlanMutation(pendingPlanId);
    await queryClient.refetchQueries([QUERY.getUser]);
  };

  const onErrorUpdateCard = async () => {
    setClientSecret("");
    await setSetupIntent(pendingPlanId);
  };

  const handleChangePlan = async (id, confirmed, bypass = false) => {
    // bypass is used to get a round a race condition with stripe returning
    // the payment save info

    if (
      !shop.has_payment_method &&
      !bypass &&
      id !== PLAN_FREE_ID &&
      !isShopifyBilling()
    ) {
      setPendingPlanId(id);
      setSetupCardModal(true);
      await setSetupIntent(id);
      return;
    }

    if (
      isDowngrading(shop.plan, id) &&
      !shop.subscription_status !== "trialing"
    ) {
      dispatch(updateDowngradeStatus({ plan: id }));
      dispatch(
        choosenPlan({
          older_plan: shop.plan,
          new_plan: id,
        })
      );
      dispatch(setChoosenPlan(id));

      if (isOnTrial()) {
        setPendingPlanId(id);
        setConfirmModal(true);
        dispatch(resetDisplayUpgradeMessage());
        setLoading(id);
        executeChangePlanMutation(id);
        return;
      }

      navigate(ROUTE_PATHS.DOWNGRADE_FEEDBACK);

      return;
    }

    // check if switching to an annual plan
    if (!confirmed && !bypass && id !== shop.plan) {
      setPendingPlanId(id);
      setConfirmModal(true);
      return;
    }

    setLoading(id);
    dispatch(resetDisplayUpgradeMessage());
    executeChangePlanMutation(id);
  };

  const isFree = useMemo(
    () => PLAN_INFO[pendingPlanId]?.name.includes("Free"),
    [pendingPlanId]
  );

  return (
    <>
      <ModalWrapper
        handleClose={onCloseModal}
        isOpen={isOpen}
        data-testid="ChangePlanModal"
        className={`${styles.changePlanWrapper}`}
      >
        <>
          {featureFlagsHook.showBlackFridayComponent() &&
            displayBlackFridayBanner() && <BlackFridayModalNotification />}
          {isOnTrial() && shop?.trial_days_remaining > 0 && (
            <Box className={styles.positioningFreeTrialTag}>
              <FreeTrialTag date={shop?.subscription_next_billing} />
            </Box>
          )}
          {isFreePlanLimit ? (
            <Text
              className="text--bold text__header"
              color="medium-gray"
              variant="h2"
              fontSize={21}
            >
              {title ||
                `Oops! You've reached your plan limit. Select any paid plan below to continue.`}
            </Text>
          ) : (
            <>
              <Text
                color="medium-gray"
                fontSize={!isMobile ? 21 : 18}
                variant="h1"
              >
                {title || "Pick Your Plan"}
              </Text>
              <Text className="text__body text--nunito">
                {freePlanLimitSubText ||
                  "Select any paid plan below to brand products."}
              </Text>
            </>
          )}
          <>
            {shop.has_trial && (
              <Text className={styles.modalSubTitle}>
                Try a {shop?.trial_days_remaining}-day trial for free now!
              </Text>
            )}

            <Box className={styles.boxContainer}>
              <Text
                className={`text__body ${
                  switchChecked ? "text--nunito" : "text--peach text--bold"
                }`}
              >
                Annually
              </Text>
              <PlanSwitch
                handleChange={handlePlanSwitch}
                checked={switchChecked}
              />
              <Text
                className={`text__body ${
                  !switchChecked ? "text--nunito" : "text--peach text--bold"
                }`}
              >
                Monthly
              </Text>
            </Box>

            <Text fontSize={18} className="">
              {subtitle}
            </Text>
          </>

          <div className={styles.plans}>
            <PlanTable
              onPaidPlanSelect={handleChangePlan}
              onFreePlanSelect={handleChangePlan}
              loading={loading}
              constant={TRY_FOR_FREE}
              switchChecked={switchChecked}
            />
          </div>
          <Text className={styles.warning}>
            <span>* applies to published products</span>
            <span>
              **1 free Brand Setup is included with each plan. Subsequent Brand
              Setups are an additional cost.
            </span>
          </Text>
        </>
      </ModalWrapper>

      <ModalWrapper
        handleClose={() => {
          setConfirmModal(false);
        }}
        isOpen={confirmModal}
        data-testid="ConfirmPlanModal"
        className={`${styles.changePlanWrapper}`}
      >
        <ChangePlanPaymentConfirmation
          shop={shop}
          handleConfirm={() => {
            handleChangePlan(pendingPlanId, true);
            setConfirmModal(false);
          }}
          handleCancelConfirm={() => {
            setConfirmModal(false);
          }}
          pendingPlanId={pendingPlanId}
        />
      </ModalWrapper>

      <ModalWrapper
        handleClose={() => {
          setSetupCardModal(false);
        }}
        isOpen={setupCardModal}
        data-testid="SetupCardModal"
        className={`${styles.stripeModalWrapper}`}
      >
        {!clientSecret && (
          <div className={styles.loadingContainer}>
            <CircularProgress />
          </div>
        )}
        <UpdatePaymentStripe
          clientSecret={clientSecret}
          onError={onErrorUpdateCard}
          onSuccess={onSuccessUpdateCard}
          onCancel={() => setSetupCardModal(false)}
        />
      </ModalWrapper>
    </>
  );
}

export default NewChangePlanModal;
