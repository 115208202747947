import { SvgIcon } from "@mui/material";
import React from "react";

function DoubleCheckIcon({ size, color = "currentColor" }) {
  return (
    <SvgIcon
      className="icon icon--normal"
      viewBox="0 0 14 8"
      style={{ width: size, height: size }}
    >
      <g clipPath="url(#clip0_6164_81035)">
        <path
          d="M3.90838 7.5L0.612549 4.20416L1.4438 3.3875L4.73963 6.68333L3.90838 7.5ZM7.20422 7.5L3.90838 4.20416L4.72505 3.37291L7.20422 5.85208L12.5709 0.485413L13.3875 1.31666L7.20422 7.5ZM7.20422 4.20416L6.37297 3.3875L9.26046 0.499996L10.0917 1.31666L7.20422 4.20416Z"
          fill={color}
        />
      </g>
    </SvgIcon>
  );
}

export default DoubleCheckIcon;
