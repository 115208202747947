import React, { useEffect, useMemo } from "react";

import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { resetQuestions, setQuestions } from "state/changePlanSlice";

import {
  Box,
  Checkbox,
  FormControlLabel,
  Stack,
  TextField,
} from "@mui/material";
import Button from "components/Buttons/Button";
import DowngradeLayout from "components/DowngradeLayout";
import Text from "components/Text";

import { useAnalytics } from "hooks";
import { Helmet } from "react-helmet";
import ROUTE_PATHS from "routes/routePaths";
import styles from "./DowngradeFeedbackView.module.scss";

function DowngradeFeedbackView() {
  const analytics = useAnalytics();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const changePlan = useSelector((state) => state.changePlan);

  useEffect(() => {
    if (changePlan) {
      analytics.sendEvent(analytics.DOWNGRADE_FLOW_START, {
        old_plan: changePlan.trackPlan.older_plan,
        new_plan: changePlan.trackPlan.new_plan,
      });
      analytics.sendEvent(analytics.DOWNGRADE_FLOW_STEP_CHANGE, {
        step: "FEEDBACK",
      });
    }
  }, [changePlan]);

  const isQuestionChecked = useMemo(
    () => changePlan.questions.some((question) => question.isChecked),
    [changePlan.questions]
  );

  const handleFeedbackChange = (id, isChecked, answer) => {
    const updatedQuestions = changePlan.questions.map((question) => {
      if (question.id === id) {
        return { ...question, isChecked, answer };
      }
      return { ...question, isChecked: false, answer: "" };
    });
    dispatch(setQuestions(updatedQuestions));
  };

  const handleOnCheck = (e, id) => {
    const isChecked = e.target.checked;
    const currentAnswer = changePlan.questions.find((q) => q.id === id).answer;
    handleFeedbackChange(id, isChecked, currentAnswer);
  };

  const handleInputChange = (e, id) => {
    const newAnswer = e.target.value;
    const { isChecked } = changePlan.questions.find((q) => q.id === id);
    handleFeedbackChange(id, isChecked, newAnswer);
  };

  const onNext = () => {
    navigate(ROUTE_PATHS.DOWNGRADE_REVIEW);
  };

  const onKeepCurrentPlan = () => {
    analytics.sendEvent(analytics.DOWNGRADE_FLOW_EXIT, {
      step_name: "FEEDBACK",
    });
    dispatch(resetQuestions());
    navigate(ROUTE_PATHS.PROFILE);
  };

  return (
    <DowngradeLayout>
      <Helmet>
        <title>Downgrade: Feedback</title>
      </Helmet>
      <Box className={styles.feedbackWrapper}>
        <Box className={styles.feedback}>
          <Text variant="h1" className={styles.title}>
            Why are you changing your plan?
          </Text>

          <Box className={styles.cardWrapper}>
            <Stack className={styles.questions}>
              {changePlan.questions?.map((question) => (
                <Box key={question.id} className={styles.questionWrapper}>
                  <Box className={styles.checkboxAndQuestion}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          className={styles.checkMark}
                          onClick={(e) => handleOnCheck(e, question.id)}
                          checked={question.isChecked}
                        />
                      }
                      label={question.question}
                      classes={{ label: styles.question }}
                    />
                  </Box>
                  {question.hasReply && question.isChecked && (
                    <Box className={styles.replyWrapper}>
                      <TextField
                        className={styles.reply}
                        placeholder="Please specify to continue"
                        value={question.answer}
                        onChange={(e) => handleInputChange(e, question.id)}
                        required
                      />
                    </Box>
                  )}
                </Box>
              ))}
            </Stack>
          </Box>
        </Box>

        <Box className={styles.actions}>
          <Link
            to="/profile"
            onClick={onKeepCurrentPlan}
            className={styles.keepCurrentLink}
          >
            Keep Current Plan
          </Link>
          <Button
            size="small"
            variant="contained"
            color="primary"
            disabled={!isQuestionChecked}
            onClick={onNext}
            className={styles.ctaBtn}
          >
            Next
          </Button>
        </Box>
      </Box>
    </DowngradeLayout>
  );
}

export default DowngradeFeedbackView;
