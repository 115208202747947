/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

// eslint-disable-next-line import/no-cycle
import {
  getShippingAddresses,
  getShippingAddressById,
  createShippingAddress,
  updateShippingAddressById,
  deleteShippingAddressById,
} from "api";
import {
  ACCOUNT_ADDRESSES_CREATED,
  ACCOUNT_ADDRESSES_UPDATED,
} from "constants/accountAddresses";

export const getShippingAddressesAsync = createAsyncThunk(
  "accountAddresses/getShippingAddresses",
  async (_, { rejectWithValue }) => {
    try {
      const response = await getShippingAddresses();
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const getShippingAddressByIdAsync = createAsyncThunk(
  "accountAddresses/getShippingAddressById",
  async (id, { rejectWithValue }) => {
    try {
      const response = await getShippingAddressById(id);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const createShippingAddressAsync = createAsyncThunk(
  "accountAddresses/createShippingAddress",
  async (address, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      if (state.accountAddresses.addresses.length === 0) {
        address.default = true;
      }
      const response = await createShippingAddress(address);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const updateShippingAddressAsync = createAsyncThunk(
  "accountAddresses/updateShippingAddress",
  async ({ id, data }, thunkAPI) => {
    try {
      const response = await updateShippingAddressById(id, data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const deleteShippingAddressAsync = createAsyncThunk(
  "accountAddresses/deleteShippingAddress",
  async (id, { getState, dispatch, rejectWithValue }) => {
    try {
      const state = getState();
      const { addresses } = state.accountAddresses;
      const addressToDelete = addresses.find((address) => address.id === id);

      await deleteShippingAddressById(id);

      if (addressToDelete.default && addresses.length === 2) {
        const remainingAddress = addresses.find((address) => address.id !== id);
        await dispatch(
          updateShippingAddressAsync({
            id: remainingAddress.id,
            data: { ...remainingAddress, default: true },
          })
        ).unwrap();
      }

      return id;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

/*
Addresses object structure:
  {
    id: null,
    first_name: "",
    last_name: "",
    company: "",
    address_one: "",
    address_two: "",
    city: "",
    state: "",
    zip_code: "",
    country: {
      name: "",
      code: "",
    },
    phone: "",
    default: false,
    updated_at: "",
  },
*/

export const accountAddressesSlice = createSlice({
  name: "AccountAddressesSlice",
  initialState: {
    addresses: [],
    addressToEdit: {},
    addressToShip: {},
    isEditing: false,
    isAdding: false,
    addressId: undefined,
    maximumAmountOfAddresses: 10,
    status: "loading",
    error: null,
  },
  reducers: {
    setIsAdding: (state, action) => {
      state.isAdding = action.payload;
    },
    setIsEditing: (state, action) => {
      state.isEditing = action.payload;
    },
    setSelectedAddressId: (state, action) => {
      state.selectedAddressId = action.payload;
    },
    setAddressToShip: (state, action) => {
      state.addressToShip = action.payload;
    },
    setAddressToEdit: (state, action) => {
      state.addressToEdit = action.payload;
    },
    setAddressToEditById: (state, action) => {
      state.addressToEdit = state.addresses.find(
        (obj) => obj.id === action.payload
      );
    },
  },
  extraReducers: (builder) => {
    // GET SHIPPING ADDRESSES
    builder.addCase(getShippingAddressesAsync.pending, (state) => {
      state.status = "loading";
    });
    builder.addCase(getShippingAddressesAsync.fulfilled, (state, action) => {
      state.status = "idle";
      state.addresses = action.payload;
    });
    // GET SHIPPING ADDRESS BY ID
    builder.addCase(getShippingAddressByIdAsync.pending, (state) => {
      state.status = "loading";
    });
    builder.addCase(getShippingAddressByIdAsync.fulfilled, (state, action) => {
      state.status = "idle";
      state.addressToEdit = action.payload;
    });
    // CREATE SHIPPING ADDRESS
    builder.addCase(createShippingAddressAsync.pending, (state) => {
      state.status = "loading";
    });
    builder.addCase(createShippingAddressAsync.fulfilled, (state, action) => {
      state.status = ACCOUNT_ADDRESSES_CREATED;
    });
    // UPDATE SHIPPING ADDRESS
    builder.addCase(updateShippingAddressAsync.pending, (state) => {
      state.status = "loading";
    });
    builder.addCase(updateShippingAddressAsync.rejected, (state) => {
      state.status = "error";
      state.error = "Error updating address";
    });
    builder.addCase(updateShippingAddressAsync.fulfilled, (state, action) => {
      const updatedAddress = action.payload;
      if (updatedAddress.default) {
        state.addresses.forEach((address) => {
          if (address.id !== updatedAddress.id) {
            address.default = false;
          }
        });
      }
      const index = state.addresses.findIndex(
        (address) => address.id === updatedAddress.id
      );
      if (index !== -1) {
        state.addresses[index] = updatedAddress;
      }
      state.status = ACCOUNT_ADDRESSES_UPDATED;
    });
    // DELETE SHIPPING ADDRESS
    builder.addCase(deleteShippingAddressAsync.pending, (state) => {
      state.status = "loading";
    });
    builder.addCase(deleteShippingAddressAsync.fulfilled, (state, action) => {
      state.status = "idle";
      state.addresses = state.addresses.filter(
        (address) => address.id !== action.payload
      );
    });
  },
});

export const {
  setIsAdding,
  setIsEditing,
  setAddressToShip,
  setAddressToEdit,
  setAddressToEditById,
} = accountAddressesSlice.actions;

export const selectAddresses = (state) => state.accountAddresses.addresses;
export const selectAddressesToEdit = (state) =>
  state.accountAddresses.addressToEdit;
export const selectAddressToShip = (state) =>
  state.accountAddresses.addressToShip;
export const selectorMaximumAmountOfAddresses = (state) =>
  state.accountAddresses.maximumAmountOfAddresses;
export const selectIsAdding = (state) => state.accountAddresses.isAdding;
export const selectIsEditing = (state) => state.accountAddresses.isEditing;
export const selectAddressId = (state) => state.accountAddresses.addressId;
export const selectError = (state) => state.accountAddresses.error;
export const selectStatus = (state) => state.accountAddresses.status;

export default accountAddressesSlice.reducer;
