import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useMediaQuery } from "react-responsive";

// Material-UI
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CircleOutlinedIcon from "@mui/icons-material/CircleOutlined";
import CloseOutlined from "@mui/icons-material/CloseOutlined";
import ErrorIcon from "@mui/icons-material/Error";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import { Box } from "@mui/material";

// Custom components
import Button from "components/Buttons/Button";
import LogoDropzone from "components/LogoDropzone";
import Text from "components/Text";
import { BRAND_PLATE_FILE_UPLOAD_STATUS } from "constants/brandPlate";
import { SMALL } from "constants/breakpoints";
import { FILE_UPLOAD_REQUIREMENTS } from "constants/generatorLogo";
import { useAnalytics } from "hooks";

// API
import { QUERY, uploadMyLogoGenerated } from "api";
import { uploadBase64ImageV2 } from "api/endpoints/generatorApi";

// Redux
import { setLogoByType } from "state/brandSetupSlice";

import { ReactComponent as PencilLogoWhite } from "assets/images/brand-plate/pencil-logo-white.svg";
import { ReactComponent as PencilLogo } from "assets/images/brand-plate/pencil-logo.svg";

import queryClient from "api/queryClient";
import styles from "./BrandSetupMockup.module.scss";

export default function BrandSetupMockup(props) {
  const { mockup, onChangeLogo, targetLogoType, logoObj } = props;

  const isMobile = useMediaQuery({ maxWidth: SMALL });
  const dispatch = useDispatch();
  const analytics = useAnalytics();
  const [mouseEnter, setMouseEnter] = useState(false);
  const [changeLogoWasClicked, setChangeLogoWasClicked] = useState(false);
  const [responseData, setResponseData] = useState([]);
  const [imagePreview, setImagePreview] = useState(null);

  useEffect(() => {
    let objectUrl;

    if (logoObj?.logo) {
      if (logoObj.logo instanceof File) {
        objectUrl = URL.createObjectURL(logoObj.logo);
        setImagePreview(objectUrl);
      } else {
        setImagePreview(logoObj.logo);
      }
    } else {
      setImagePreview(null);
    }

    return () => {
      if (objectUrl) {
        URL.revokeObjectURL(objectUrl);
      }
    };
  }, [logoObj]);

  const handleUploadLogo = (file) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);

    analytics.sendEvent(analytics.BRAND_SETUP_UPLOAD_ALT_LOGO, {
      type: mockup.logo_type,
    });

    try {
      reader.onloadend = async () => {
        const base64ImageWithPrefix = reader.result;
        const base64String = base64ImageWithPrefix.split(",")[1];

        const response = await uploadBase64ImageV2(base64String);
        setResponseData(response.data);
        setImagePreview(base64ImageWithPrefix);

        const isValidImage = Object.values(response.data).every(
          (value) => value
        );

        if (isValidImage) {
          dispatch(setLogoByType({ logo_type: mockup.logo_type, logo: file }));

          analytics.sendEvent(analytics.BRAND_SETUP_UPLOAD_ALT_LOGO_SUCCESS, {
            type: mockup.logo_type,
          });

          await uploadMyLogoGenerated({
            logo: file,
            logo_type: mockup.logo_type,
          });

          setChangeLogoWasClicked(false);
          setMouseEnter(false);
        } else {
          analytics.sendEvent(analytics.BRAND_SETUP_UPLOAD_ALT_LOGO_ERROR, {
            type: mockup.logo_type,
            errors: Object.keys(response.data).filter(
              (key) => !response.data[key]
            ),
          });
          queryClient.refetchQueries([QUERY.getCurrentLogos]);
        }
      };
    } catch (error) {
      console.error("Error reading the file", error);
      setChangeLogoWasClicked(true);
    }
  };

  const handleValidatorIcon = (index) => {
    const requirement = FILE_UPLOAD_REQUIREMENTS[index];

    if (!requirement) {
      return <CircleOutlinedIcon />;
    }

    const validationResult = responseData[requirement.requirement];

    if (validationResult === true) {
      return (
        <CheckCircleIcon fill="#FFF" className={styles.icon} color="success" />
      );
    }
    if (validationResult === false) {
      return <ErrorIcon fill="#FFF" color="error" />;
    }

    return <CircleOutlinedIcon className={styles.icon} />;
  };

  const handleValidatorDescription = (description, index) => {
    const requirement = FILE_UPLOAD_REQUIREMENTS[index];

    if (!requirement) {
      return <span>{description}</span>;
    }

    const validationResult = responseData[requirement.requirement];

    if (validationResult === true) {
      return <span>{description}</span>;
    }
    if (validationResult === false) {
      return <span className={styles.descriptionError}>{description}</span>;
    }

    return <span>{description}</span>;
  };

  const handleChangeLogo = () => {
    setChangeLogoWasClicked(true);
    onChangeLogo();
  };

  const handleCloseOverlay = () => {
    setChangeLogoWasClicked(false);
    onChangeLogo();
  };

  const handleImageClassName = (mockup) => {
    switch (mockup.image_class) {
      case "image1":
        return styles.image1;
      case "image2":
        return styles.image2;
      case "image3":
        return styles.image3;
      case "image4":
        return styles.image4;
      default:
        return styles.image1;
    }
  };

  const handleLogoPosition = (mockup) => {
    switch (mockup.image_class) {
      case "image1":
        return styles.logo1;
      case "image2":
        return styles.logo2;
      case "image3":
        return styles.logo3;
      case "image4":
        return styles.logo4;
      default:
        return styles.logo1;
    }
  };

  const showOverlay =
    changeLogoWasClicked && mockup.logo_type === targetLogoType;

  const renderOverlay = () => (
    <Box className={styles.uploadLogoOverlay}>
      <Box className={styles.overlayContent}>
        <CloseOutlined
          className={styles.closeCta}
          onClick={handleCloseOverlay}
        />
        <Text className={styles.uploadLogoText}>upload logo</Text>
        <LogoDropzone
          onFileAccepted={handleUploadLogo}
          onFileError={(error) => {
            // should we add analytics for handling error on image uploads?
            console.log("File upload logo error:", error);
          }}
        >
          <Box className={styles.inputDropzone}>
            <FileUploadOutlinedIcon />
            <Text className={styles.info}>
              {!isMobile
                ? "Click here or drag your file here to upload"
                : "Tap here to upload"}
            </Text>
            <Text variant="body2" className={styles.sub}>
              PNG
            </Text>
          </Box>
        </LogoDropzone>

        <ul>
          {BRAND_PLATE_FILE_UPLOAD_STATUS.map((description, index) => (
            <li key={description}>
              {handleValidatorIcon(index)}
              {handleValidatorDescription(description, index)}
            </li>
          ))}
        </ul>
      </Box>
    </Box>
  );

  const imageClassName = handleImageClassName(mockup);
  const logoClassName = handleLogoPosition(mockup);

  const renderChangeLogoCard = () => (
    <>
      <Box className={styles.brandSetupMockupAction}>
        {showOverlay && renderOverlay()}
        {mockup?.logo_type !== "DEFAULT" && (
          <Button
            variant="outlined"
            color="primary"
            onClick={handleChangeLogo}
            onMouseEnter={() => setMouseEnter(true)}
            onMouseLeave={() => setMouseEnter(false)}
            className={styles.changeLogoCta}
            startIcon={
              mouseEnter ? (
                <PencilLogoWhite className={styles.pencilLogo} />
              ) : (
                <PencilLogo className={styles.pencilLogo} />
              )
            }
          >
            Change logo
          </Button>
        )}
      </Box>

      <Box className={styles.mockup}>
        <Box className={imageClassName}>
          {imagePreview && (
            <img
              src={imagePreview}
              alt={`${mockup.caption} Logo`}
              className={logoClassName}
            />
          )}
        </Box>
      </Box>
      <Text className={styles.productType}>{mockup.caption}</Text>
    </>
  );

  return (
    <Box className={styles.brandSetupMockup}>
      {!changeLogoWasClicked ? renderChangeLogoCard() : renderOverlay()}
    </Box>
  );
}
