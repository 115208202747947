import {
  Box,
  Step,
  StepConnector,
  StepContent,
  StepLabel,
  Stepper,
} from "@mui/material";
import Button from "components/Buttons/Button";
import Text from "components/Text";

import { Close, ExpandMore } from "@mui/icons-material";
import ProductIcon from "components/Icons/ProductIcon";
import StoreIcon from "components/Icons/StoreIcon";
import WandIcon from "components/Icons/WandIcon";
import { FOR_DYNAMIC_TASKS } from "constants/dashboard";
import { useCheckTask } from "hooks";
import parse from "html-react-parser";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ROUTE_PATHS from "routes/routePaths";
import cn from "utils/cn";
import styles from "./DashboardHeaderPopover.module.scss";

function DashboardHeaderPopover() {
  const navigate = useNavigate();
  const popoverRef = useRef(null);
  const { isTaskDone } = useCheckTask();
  const [anchorEl, setAnchorEl] = useState(null);
  const shop = useSelector((state) => state.profile.shop);

  const handleDocumentClick = (e) => {
    if (
      popoverRef.current &&
      !popoverRef.current.contains(e.target) &&
      anchorEl &&
      !anchorEl.contains(e.target)
    ) {
      setAnchorEl(null);
    }
  };

  const onCloseTasks = useCallback(() => {
    setAnchorEl(null);
  }, [setAnchorEl]);

  useEffect(() => {
    if (anchorEl) {
      document.addEventListener("click", handleDocumentClick);
    } else {
      document.removeEventListener("click", handleDocumentClick);
    }

    return () => document.removeEventListener("click", handleDocumentClick);
  }, [anchorEl]);

  const renderIcon = (icon) => {
    switch (icon) {
      case "wand":
        return <WandIcon size={24} />;

      case "product":
        return <ProductIcon size={24} />;

      case "store":
        return <StoreIcon size={24} />;

      default:
        return <WandIcon size={24} />;
    }
  };

  const totalTasksDone = useMemo(() => {
    let total = 0;

    FOR_DYNAMIC_TASKS.map((item) => {
      if (isTaskDone(item.key)) {
        total += 1;
      }
    });

    return total;
  }, [shop]);

  return (
    <Box className={styles.tasksCenter}>
      <Box
        className={styles.actionWrapper}
        onClick={(e) => {
          if (anchorEl) {
            setAnchorEl(null);
          } else {
            setAnchorEl(e.currentTarget);
          }
        }}
      >
        <Button
          color="primary"
          variant="outlined"
          size="small"
          className={styles.cta}
        >
          MY TASKS {totalTasksDone}/3 <ExpandMore />
        </Button>
      </Box>

      {anchorEl && (
        <Box
          ref={popoverRef}
          className={cn(styles.popoverContent)}
          style={{ top: anchorEl.offsetTop + 32 }}
        >
          <Box className={cn(styles.popoverBody)}>
            <Box onClick={onCloseTasks} className={styles.closeWrapper}>
              <Close />
            </Box>
            <Text
              onClick={() => {
                setAnchorEl(null);
                navigate(ROUTE_PATHS.DASHBOARD);
              }}
              variant="h2"
              className={styles.title}
            >
              My Tasks
            </Text>
            <Text variant="caption" className={styles.subtitle} />

            <Box className={styles.tasksStepper}>
              <Stepper connector={null} orientation="vertical">
                {FOR_DYNAMIC_TASKS.map((task) => (
                  <Step active expanded key={task.title}>
                    <StepLabel
                      classes={{
                        root: cn(
                          styles.stepperlabelRoot,
                          isTaskDone(task.key) && styles.stepperlabelRootDone
                        ),
                        iconContainer: isTaskDone(task.key)
                          ? styles.stepperIconContainerDone
                          : "",
                      }}
                    >
                      <Text variant="caption" className={styles.title}>
                        {task.title}
                      </Text>
                    </StepLabel>
                    <StepContent
                      classes={{
                        root: cn(
                          styles.stepperContentRoot,
                          isTaskDone(task.key) && styles.stepperContentRootDone
                        ),
                      }}
                    >
                      <Box className={styles.content}>
                        <Text>{parse(task.content)}</Text>
                        <Box className={styles.icon}>
                          {renderIcon(task.icon)}
                        </Box>
                      </Box>
                    </StepContent>
                  </Step>
                ))}
              </Stepper>
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
}

export default DashboardHeaderPopover;
