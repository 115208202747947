import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

// material UI Components
import { Box, Stack } from "@mui/material";

// custom components and constants:
import AccountNav from "components/AccountNav";
import Button from "components/Buttons/Button";
import AccountAddressCard from "components/Cards/AccountAddressCard/AccountAddressCard";
import AccountCard from "components/Cards/AccountCard";
import PageHeader from "components/PageHeader/PageHeader";
import Text from "components/Text";

// Modals:
import ConfirmRemoveAddressModal from "components/Modals/ConfirmRemoveAddressModal/ConfirmRemoveAddressModal";

// store:
import {
  getShippingAddressesAsync,
  selectStatus,
  setIsAdding,
  setIsEditing,
} from "state/accountAddressesSlice";

// hooks:
import useAccountAddresses from "hooks/useAccountAddresses";

import {
  ACCOUNT_ADDRESSES_CREATED,
  ACCOUNT_ADDRESSES_UPDATED,
} from "constants/accountAddresses";
import styles from "./AccountAddressesView.module.scss";

export default function AccountAddressesView() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    addresses,
    maxAddresses,
    handleOnClickAddAddress,
    handleOnClickEditAddress,
    handleOnRemoveAddress,
  } = useAccountAddresses();

  const [open, setOpen] = useState(false);
  const [selectedAddressId, setSelectedAddressId] = useState(null);
  // const [addressesRendered, setAddressesRendered] = useState(null);

  const handleOpenConfirmRemoveAddressModal = (id) => {
    setSelectedAddressId(id);
    setOpen(true);
  };

  const handleOnClickRemoveAddress = () => {
    handleOnRemoveAddress(selectedAddressId);
    setOpen(false);
  };

  const handleEditAddress = (id) => {
    handleOnClickEditAddress(id);
    navigate("/profile/addresses/update");
  };

  const handleAddAdresses = () => {
    handleOnClickAddAddress();
    navigate("/profile/addresses/new");
  };

  const renderAddresses = () =>
    addresses?.map((address) => (
      <AccountAddressCard
        key={address.id}
        address={address}
        handleOnClickEditAddress={handleEditAddress}
        handleOpenConfirmRemoveAddressModal={
          handleOpenConfirmRemoveAddressModal
        }
      />
    ));

  useEffect(() => {
    dispatch(getShippingAddressesAsync());
    dispatch(setIsEditing(false));
    dispatch(setIsAdding(false));
  }, [dispatch, navigate]);

  const status = useSelector(selectStatus);

  useEffect(() => {
    if (
      status === ACCOUNT_ADDRESSES_CREATED ||
      status === ACCOUNT_ADDRESSES_UPDATED
    ) {
      toast.success("Address has been saved!");
    }
  }, [status]);

  return (
    <div className={styles.accountAddressesView}>
      <PageHeader title="My Account" />
      <AccountNav />
      <AccountCard>
        <Stack className={styles.flexContainer}>
          <Box>
            <Text variant="title">Saved Addresses</Text>
            <Text variant="description">
              Checkout easier by saving addresses to your acccount.
            </Text>
          </Box>
          <Button
            variant="contained"
            color="primary"
            onClick={handleAddAdresses}
            disabled={addresses?.length >= maxAddresses}
            size="small"
          >
            Add new address
          </Button>
        </Stack>
        <Stack className={styles.addressesContainer}>
          {addresses && addresses?.length === 0 ? (
            <Box className={styles.boxContainer}>
              <Text variant="body1" className={styles.text}>
                You don’t have any saved addresses, why not{" "}
                <span onClick={handleAddAdresses} className={styles.link}>
                  add one now
                </span>
                ?
              </Text>
            </Box>
          ) : (
            <>{renderAddresses()}</>
          )}
        </Stack>
      </AccountCard>
      <ConfirmRemoveAddressModal
        open={open}
        handleClose={() => setOpen(false)}
        handleConfirm={handleOnClickRemoveAddress}
        address={addresses?.find((address) => address.id === selectedAddressId)}
      />
    </div>
  );
}
