import Text from "components/Text";
import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { Box } from "@mui/material";
import DashboardNewsCard from "components/Dashboard/DashboardNewsCard";
import DashboardTaskCard from "components/Dashboard/DashboardTaskCard";
import parse from "html-react-parser";

import { FOR_DYNAMIC_NEWS_CARDS, FOR_DYNAMIC_TASKS } from "constants/dashboard";

import { QUERY, updateShop } from "api";
import queryClient from "api/queryClient";
import TaskSuccessModal from "components/TaskSuccessModal";
import { useCheckTask } from "hooks";
import styles from "./DashboardView.module.scss";

// update <Text> component with latest once notification branch is merged
function DashboardView() {
  const [successModal, setSuccessModal] = useState(false);
  const profile = useSelector((state) => state.profile);
  const { isTaskDone } = useCheckTask();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const isDone = FOR_DYNAMIC_TASKS.every((item) => isTaskDone(item.key));
    const { onboarding_checklist_complete: completed } = profile.shop;

    if (isDone && !completed) {
      setSuccessModal(true);
    } else {
      setSuccessModal(false);
    }
  }, [profile.shop]);

  const onClose = useCallback(async () => {
    setLoading(true);
    await updateShop(profile.shop.id, {
      onboarding_checklist_complete: true,
    });
    queryClient.refetchQueries([QUERY.getUser]);
    // remove until we can build in credit
    // setSuccessModal(false);
  }, []);

  return (
    <Box className={styles.dashboardView}>
      <Text variant="h1" className={styles.title}>
        Welcome, {profile?.profile?.name}
      </Text>

      <Box className={styles.tasksWrapper}>
        <Text variant="h2" className={styles.tasksTitle}>
          Complete these 3 quick tasks to get the most out of Blanka
        </Text>

        <Box className={styles.taskCards}>
          {FOR_DYNAMIC_TASKS.map((item) => (
            <DashboardTaskCard
              isDone={isTaskDone(item.key)}
              icon={item.icon}
              step={item.step}
              title={item.title}
              buttonLabel={item.buttonLabel}
              buttonPath={item.buttonPath}
            >
              {parse(item.content)}
            </DashboardTaskCard>
          ))}
        </Box>

        <Text variant="caption" className={styles.taskCaption} />
      </Box>

      <Box className={styles.newsWrapper}>
        {FOR_DYNAMIC_NEWS_CARDS.map((item) => (
          <DashboardNewsCard title={item.title} image={item.image}>
            {parse(item.content)}
          </DashboardNewsCard>
        ))}
      </Box>

      {successModal && <TaskSuccessModal loading={loading} onClose={onClose} />}
    </Box>
  );
}

export default DashboardView;
