import React, { useEffect, useState } from "react";

import { Box } from "@mui/material";

import AccountNav from "components/AccountNav";
import AccountCard from "components/Cards/AccountCard";
import PageHeader from "components/PageHeader/PageHeader";
import Text from "components/Text";

import { QUERY } from "api";
import { IOSSwitch } from "components/Switch/Switch";
import { useQuery } from "react-query";

import {
  getShopPreferences,
  getTransports,
  updateShopPreferences,
} from "api/endpoints/profileApi";

import cn from "utils/cn";
import styles from "./ProfileSettingsView.module.scss";

export default function ProfileSettingsView() {
  const [isEditingUnits, setIsEditingUnits] = useState(false);
  const [outOfStockUnits, setOutOfStockUnits] = useState(0);
  const [selectedTransports, setSelectedTransports] = useState([]);

  const { data: preferences } = useQuery(
    QUERY.getShopPreferences,
    getShopPreferences,
    {
      onSuccess: (data) => {
        setSelectedTransports(data?.data?.[0]?.preferred_transports ?? []);
      },
    }
  );

  const { data: transports } = useQuery(QUERY.getTransports, getTransports);

  useEffect(() => {
    setOutOfStockUnits(preferences?.data?.[0]?.metadata?.min_inventory);
  }, [preferences]);

  const onSaveStockInventory = async () => {
    const id = preferences?.data?.[0]?.id;

    await updateShopPreferences(id, {
      metadata: { min_inventory: Number(outOfStockUnits) },
    });

    setIsEditingUnits(false);
  };

  const renderLowInventoryInput = () => {
    if (isEditingUnits) {
      return (
        <Box className={styles.editRow}>
          <input
            value={outOfStockUnits}
            onChange={(e) => setOutOfStockUnits(e.target.value)}
            type="number"
            min={0}
            className={styles.editStockInput}
          />
          <Text
            onClick={onSaveStockInventory}
            variant="p"
            className={cn(styles.description, styles.editStock)}
          >
            Save
          </Text>
          <Text
            onClick={() => setIsEditingUnits(false)}
            variant="p"
            className={cn(styles.description, styles.editStock)}
          >
            Cancel
          </Text>
        </Box>
      );
    }

    return (
      <Text
        onClick={() => setIsEditingUnits(true)}
        variant="p"
        className={cn(styles.description, styles.editStock)}
      >
        {outOfStockUnits} units.
      </Text>
    );
  };

  const handleChangeTransport = async (event, transport) => {
    const { checked } = event.target;
    const id = preferences?.data?.[0]?.id;

    if (checked) {
      const newTransports = [...selectedTransports, transport?.id];
      setSelectedTransports(newTransports);
      await updateShopPreferences(id, {
        preferred_transports: newTransports,
      });
    } else {
      const newTransports = selectedTransports.filter(
        (value) => value !== transport?.id
      );
      setSelectedTransports(newTransports);
      await updateShopPreferences(id, {
        preferred_transports: newTransports,
      });
    }
  };

  const renderTransports = () => {
    if (!transports?.data && !preferences?.data) return null;

    return transports?.data.map((transport) => {
      const isChecked = selectedTransports?.includes(transport?.id);

      return (
        <IOSSwitch
          checked={isChecked}
          onChange={(e) => handleChangeTransport(e, transport)}
        />
      );
    });
  };

  if (!transports?.data && !preferences?.data) return null;

  return (
    <div className={styles.settingsView}>
      <PageHeader title="My Account" />
      <AccountNav />
      <AccountCard>
        <Box>
          <Text variant="title" mb={2}>
            Settings
          </Text>
        </Box>
        <Box className={styles.innerCard}>
          <Box>
            <Text variant="subtitle" mb={0.5}>
              Notifications
            </Text>
            <Text variant="description">
              Blanka may still send you notifications outside of these settings
              (eg. shipping status updates, order payments, etc).
            </Text>
          </Box>

          <Box className={styles.notificationsList}>
            <Box className={styles.notificationsListRow}>
              <Box />
              <Box>
                <Text className={styles.settingColumn}>IN APP</Text>
              </Box>
              <Box>
                <Text className={styles.settingColumn}>EMAIL</Text>
              </Box>
            </Box>

            <Box className={styles.notificationsListRow}>
              <Box>
                <Text variant="h3" className={styles.settingTitle}>
                  Low inventory
                </Text>
                <Text variant="p" className={styles.description}>
                  Notify me when inventory is less than{" "}
                  {renderLowInventoryInput()}
                </Text>
              </Box>
              {renderTransports()}
            </Box>
          </Box>
        </Box>
      </AccountCard>
    </div>
  );
}
