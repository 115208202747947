import React, { useEffect, useMemo, useState } from "react";

import { useAnalytics, useProfile } from "hooks";
import { useMutation } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ROUTE_PATHS from "routes/routePaths";
import { resetQuestions } from "state/changePlanSlice";

import { Box } from "@mui/material";
import Button from "components/Buttons/Button";
import DowngradeLayout from "components/DowngradeLayout";
import EarthquakeIcon from "components/Icons/EarthquakeIcon";
import ChangePlanPaymentConfirmation from "components/Modals/ChangePlanModal/components/ChangePlanPaymentConfirmation";
import ModalWrapper from "components/ModalWrapper";
import Text from "components/Text";
import PlanDescription from "views/DowngradeView/components/PlanDescription";

import { ChatBubbleOutline, CheckCircle } from "@mui/icons-material";
import { pauseAccount } from "api/endpoints/shopApi";
import { Transaction } from "api/trace";
import SmileyMehIcon from "components/Icons/SmileyMehIcon";
import { PLAN_INFO } from "constants/plans";
import { Helmet } from "react-helmet";
import styles from "./DowngradeReviewView.module.scss";

const TRANSACTION_NAME = "/downgrade/review";
const OPERATION_NAME = "pause";
const TRANSACTION_DESCRIPTION = "Pause account";

// should come from backend
const PAUSE_PRICES = {
  GROWTH: 10,
  SCALE: 15,
  VIP: 20,
};
const PAUSE_CODES = ["GROWTH_PAUSE", "SCALE_PAUSE", "VIP_PAUSE"];

function DowngradeReviewView() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const analytics = useAnalytics();
  const changePlan = useSelector((state) => state.changePlan);
  const [confirmModal, setConfirmModal] = useState(false);
  const { refreshProfile, shop } = useProfile();
  const [transaction] = useState(
    Transaction.startTransaction(
      TRANSACTION_NAME,
      OPERATION_NAME,
      TRANSACTION_DESCRIPTION
    )
  );

  useEffect(() => {
    if (changePlan) {
      analytics.sendEvent(analytics.DOWNGRADE_FLOW_STEP_CHANGE, {
        step: "REVIEW",
      });
    }
  }, [changePlan]);

  const pauseAccountMutation = useMutation(pauseAccount);

  const onCancelSubscription = () => {
    navigate(ROUTE_PATHS.DOWNGRADE_CANCEL_SUBSCRIPTION);
  };

  const onConfirmPauseAccount = async () => {
    transaction.span.data = changePlan.questions;
    transaction.finishTransaction();

    pauseAccountMutation.mutate(shop?.id, {
      onSuccess: () => {
        refreshProfile().then(() => {
          analytics.sendEvent(analytics.DOWNGRADE_FLOW_PAUSE_COMPLETE, {
            old_plan: changePlan.trackPlan.older_plan,
            new_plan: changePlan.trackPlan.new_plan,
            downgrade_reason: changePlan.downgradeReason,
            downgrade_response: changePlan.downgradeResponse,
            scheduled_downgrade_date: shop.subscription_next_billing,
          });
          window.location.href = "/profile?downgrade=success";
        });
      },
    });
  };

  const onKeepCurrentPlan = () => {
    analytics.sendEvent(analytics.DOWNGRADE_FLOW_EXIT, {
      step_name: "REVIEW",
    });
    dispatch(resetQuestions());
    navigate(ROUTE_PATHS.PROFILE);
  };

  const onClickPauseAccount = () => {
    analytics.sendEvent(analytics.DOWNGRADE_FLOW_PAUSE_START);
    setConfirmModal(true);
  };

  const isAnnual = useMemo(
    () => PLAN_INFO[changePlan.trackPlan.older_plan]?.name.includes("Annual"),
    [changePlan.trackPlan.older_plan]
  );

  const isPaused = useMemo(() => {
    if (Array.isArray(shop?.subscription_discount)) {
      const vouchers = shop?.subscription_discount?.map(
        (discount) => discount?.voucher?.code
      );
      return vouchers.some((value) => PAUSE_CODES.includes(value));
    }

    return false;
  }, [shop?.subscription_discount]);

  const handleOpenIntercom = () => {
    if (window.Intercom) {
      window.Intercom("show");
      analytics.sendEvent(analytics.DOWNGRADE_FLOW_CONTACT_SUPPORT);
    } else {
      console.error("Intercom is not initialized");
    }
  };

  const renderContentMain = () => {
    if (isAnnual) {
      return (
        <Box className={styles.pauseContent}>
          <Box className={styles.pauseContentIconAnnual}>
            <SmileyMehIcon color="#FFF" size={52} />
          </Box>
          <Box>
            <Text className={styles.pauseContentTitle}>
              We hate to see you go
            </Text>
            <Text className={styles.pauseContentDescription}>
              Give us one more chance to make things right! We can change, we
              promise.
            </Text>
          </Box>
          <Button
            variant="contained"
            color="primary"
            onClick={handleOpenIntercom}
            size="small"
          >
            <Box className={styles.pauseCta}>
              <ChatBubbleOutline fontSize="20px" />
              CONTACT MERCHANT SUPPORT
            </Box>
          </Button>
        </Box>
      );
    }

    return (
      <Box className={styles.pauseContent}>
        <Box className={styles.pauseContentIcon}>
          <EarthquakeIcon />
        </Box>
        <Box>
          <Text className={styles.pauseContentTitle}>
            Don’t lose your progress!
          </Text>
          <Text className={styles.pauseContentDescription}>
            Why not pause your account and re-activate when you’re ready to get
            back to business?
          </Text>
        </Box>
        <Button
          variant="contained"
          color="primary"
          onClick={onClickPauseAccount}
          size="small"
          disabled={isPaused}
        >
          PAUSE MY ACCOUNT
        </Button>

        <Box className={styles.pauseContentFooter}>
          <Text className={styles.pauseContentFooterTitle}>
            When your account is paused:
          </Text>

          <Box className={styles.pauseContentFooterList}>
            <Box className={styles.pauseContentFooterRow}>
              <CheckCircle fontSize="12px" />
              <Text className={styles.pauseContentFooterText}>
                Your plan cost is reduced up to 90 days:
                <br />
                <Text className={styles.pauseContentFooterTextBold}>
                  {PLAN_INFO[changePlan.trackPlan.older_plan]?.plan_type} plan{" "}
                  <Text className={styles.pauseContentFooterTextStrike}>
                    ${PLAN_INFO[changePlan.trackPlan.older_plan]?.price}
                  </Text>{" "}
                  <Text className={styles.pauseContentTextInline}>
                    $
                    {
                      PAUSE_PRICES[
                        PLAN_INFO[changePlan.trackPlan.older_plan]?.plan_type
                      ]
                    }
                    /mo
                  </Text>
                </Text>
              </Text>
            </Box>

            <Box className={styles.pauseContentFooterRow}>
              <CheckCircle fontSize="12px" />
              <Text className={styles.pauseContentFooterText}>
                You won’t lose your branded products
              </Text>
            </Box>

            <Box className={styles.pauseContentFooterRow}>
              <CheckCircle fontSize="12px" />
              <Text className={styles.pauseContentFooterText}>
                You can resume anytime from where you left off
              </Text>
            </Box>
          </Box>
        </Box>
      </Box>
    );
  };

  return (
    <DowngradeLayout>
      <Helmet>
        <title>Downgrade: Review</title>
      </Helmet>
      <Box className={styles.reviewWrapper}>
        <Box className={styles.content}>
          <Text variant="h1" className={styles.title}>
            Review Changes
          </Text>

          <Box className={styles.contentMain}>
            {renderContentMain()}

            <Box className={styles.planContent}>
              <Text className={styles.planContentTitle}>Selected Plan</Text>
              <PlanDescription
                planId={changePlan?.trackPlan?.new_plan}
                compareWithBase
              />
            </Box>
          </Box>
        </Box>
        <Box className={styles.actions}>
          <Button
            size="small"
            color="primary"
            variant="outlined"
            onClick={onKeepCurrentPlan}
          >
            Keep Current Plan
          </Button>

          <Button
            variant="text"
            color="primary"
            size="small"
            onClick={onCancelSubscription}
            className={styles.cancelSubscriptionButton}
          >
            Cancel subscription
          </Button>
        </Box>
      </Box>

      <ModalWrapper
        handleClose={() => {
          setConfirmModal(false);
        }}
        isOpen={confirmModal}
        data-testid="ConfirmPlanModal"
        className={`${styles.confirmModalWrapper}`}
      >
        <ChangePlanPaymentConfirmation
          shop={shop}
          handleConfirm={() => {
            onConfirmPauseAccount();
          }}
          handleCancelConfirm={() => {
            setConfirmModal(false);
          }}
          isPauseAccount
          pendingPlanId={shop?.plan}
          loading={pauseAccountMutation.isLoading}
        />
      </ModalWrapper>
    </DowngradeLayout>
  );
}

export default DowngradeReviewView;
