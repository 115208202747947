import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Box } from "@mui/material";
import logo from "assets/images/logo.png";
import Text from "components/Text";

import { useDispatch, useSelector } from "react-redux";
import ROUTE_PATHS from "routes/routePaths";
import { resetQuestions } from "state/changePlanSlice";
import styles from "./DowngradeLayout.module.scss";

function DowngradeLayout({ children }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const changePlan = useSelector((state) => state.changePlan);

  const pendingPlanId = changePlan.trackPlan.new_plan;

  useEffect(() => {
    if (!pendingPlanId) {
      navigate(ROUTE_PATHS.PROFILE);
    }
  }, [pendingPlanId]);

  const backToProfile = () => {
    dispatch(resetQuestions());
    navigate(ROUTE_PATHS.PROFILE);
  };
  return (
    <Box className={styles.downgradeLayout}>
      <header className={styles.downgradeLayoutHeader}>
        <Box className={styles.backButton} onClick={backToProfile}>
          <ArrowBackIcon />
          <Text className={styles.text}>Back to my account</Text>
        </Box>
        <img src={logo} alt="Blanka Logo" className={styles.logo} />
      </header>

      <Box className={styles.downgradeLayoutContent}>{children}</Box>
    </Box>
  );
}

export default DowngradeLayout;
