import React from "react";
import { useMediaQuery } from "react-responsive";
import { NavLink } from "react-router-dom";

// Material UI Components
import Grid from "@mui/material/Grid";

// Project Components & Constants
import { SMALL } from "constants/breakpoints";

import ROUTE_PATHS from "routes/routePaths";
import cn from "utils/cn";
import styles from "./AccountNav.module.scss";

const ACCOUNT_NAV = [
  {
    slug: ROUTE_PATHS.PROFILE,
    title: "Profile",
  },
  {
    slug: ROUTE_PATHS.PROFILE_ADDRESSES,
    title: "Addresses",
  },
  {
    slug: ROUTE_PATHS.PROFILE_BILLING,
    title: "Payment",
  },
  {
    slug: ROUTE_PATHS.PROFILE_STORE_CONNECT,
    title: "My Store",
  },
  {
    slug: ROUTE_PATHS.PROFILE_SETTINGS,
    title: "Settings",
  },
];

function AccountNav({ isShopPaused }) {
  const isMobile = useMediaQuery({ maxWidth: SMALL });

  return (
    <Grid className="paper " container spacing={2}>
      <Grid item xs={12} md={12}>
        <div className={`nav-div ${isMobile && styles["mt-10"]}`}>
          {ACCOUNT_NAV.map((item, index) => (
            <NavLink
              key={`${item.slug}-${item.title}`}
              to={item.slug}
              end
              className={({ isActive }) => {
                if (index === 0) {
                  return cn(
                    isActive ? styles.navItemFirstActive : styles.navItemFirst
                  );
                }

                if (item.title === "Settings") {
                  return cn(
                    isActive ? styles.navItemActive : styles.navItem,
                    isShopPaused && styles.navItemPaused
                  );
                }

                return cn(isActive ? styles.navItemActive : styles.navItem);
              }}
            >
              {item.title}
            </NavLink>
          ))}
        </div>
      </Grid>
    </Grid>
  );
}

export default AccountNav;
