import React from "react";
import { useNavigate } from "react-router-dom";

// MUI components
import { Box } from "@mui/material";

// Custom components
import Text from "components/Text";
import Button from "components/Buttons/Button";
import SadFaceImage from "assets/images/sad-face-blanka.gif";

import styles from "./PageNotFound.module.scss";

export default function PageNotFound() {
  const navigate = useNavigate();

  const handleBrowseProducts = () => {
    navigate("/");
  };

  const handleInterconPopUp = () => {
    window.Intercom("showNewMessage");
  };

  return (
    <main className={styles.pageNotFound}>
      <Box className={styles.content}>
        <Text variant="body1" className={styles.headerText}>
          404 - page not found
        </Text>
        <Text variant="h1" className={styles.title}>
          Looks like this page needs a touch-up! Let’s get you back to
          browsing...
        </Text>
        <Text variant="body1" className={styles.text}>
          Please check your link and try again.
        </Text>
        <Button
          variant="contained"
          color="primary"
          size="small"
          hasChevron
          onClick={handleBrowseProducts}
        >
          Browse Products
        </Button>
      </Box>
      <img src={SadFaceImage} alt="404" className={styles.image} />
      <Text variant="body1" className={styles.footerText}>
        If you’re stuck, please contact{" "}
        <span onClick={handleInterconPopUp} className={styles.link}>
          Merchant Success
        </span>
      </Text>
    </main>
  );
}
