import {
  BRANDED_BOX_COST,
  FEES_CONSTANT,
  FEES_PERCENTAGE,
  PICK_N_PACK_FEE,
  PRODUCT_TYPE_STORED_WITH_BLANKA,
} from "constants/constants";

function useCustomerOrders() {
  const getBrandedBoxFees = (total, fullfilledByBlanka) => {
    if (!fullfilledByBlanka) {
      return 0;
    }
    return total * FEES_PERCENTAGE + FEES_CONSTANT;
  };

  const getBrandedBoxData = (brandedBoxItems) => {
    const updatedList = [];
    brandedBoxItems?.map((item) => {
      if (item.branded_box_available && item.branded_box) {
        updatedList.push({ ...item });
      }
    });
    return updatedList;
  };

  const getBrandedBoxTotal = (brandedBoxItems) => {
    let totalFeesCost = 0;
    brandedBoxItems?.map((item) => {
      if (item.branded_box && item.branded_box_available) {
        totalFeesCost += BRANDED_BOX_COST * item.quantity;
      }
    });

    return totalFeesCost;
  };

  const getPickNPackFee = (items) => {
    let total = 0.0;
    items?.forEach((item) => {
      if (item.product_type === PRODUCT_TYPE_STORED_WITH_BLANKA) {
        total += Number(PICK_N_PACK_FEE) * item.quantity;
      }
    });
    return Number(total).toFixed(2);
  };

  return [
    getBrandedBoxFees,
    getBrandedBoxData,
    getBrandedBoxTotal,
    getPickNPackFee,
  ];
}

export default useCustomerOrders;
