import React, { useState } from "react";

import Button from "components/Buttons/Button";

import ModalWrapper from "components/ModalWrapper/ModalWrapper";
import Text from "components/Text";
import { useMediaQuery } from "react-responsive";
import { SMALL } from "constants/breakpoints";
import styles from "./ConfirmOrderModal.module.scss";

import "styles/colors.scss";

export default function ConfirmOrderModal({
  open,
  onConfirm,
  price,
  handleClose,
  title,
  brandplateConfirm,
  isLoading = false,
}) {
  const isMobile = useMediaQuery({ maxWidth: SMALL });

  const [loading, setLoading] = useState(isLoading);

  const handleOnConfirm = () => {
    setLoading(true);
    onConfirm();
    setLoading(false);
  };

  return (
    <ModalWrapper isOpen={open} handleClose={handleClose}>
      <div
        className={`${styles.ConfirmOrderModal}`}
        data-testid="ConfirmOrderModal"
      >
        <Text fontSize={21} variant="h1" className={styles.textHeader}>
          {title || "Please confirm your order!"}
        </Text>
        <div className={styles.contentContainer}>
          <Text color="gray" fontSize={14} className={styles.textContent}>
            Your credit card on file will be charged ${price} USD immediately
            and your order will be processed.
          </Text>

          {brandplateConfirm && (
            <Text
              color="gray"
              className={styles.textContentDisclaimer}
              fontSize={14}
            >
              <span>
                Brand Plates are non-refundable. <br /> Read more about our
                policy{" "}
                <a
                  target="__blank"
                  href="https://faq.blankabrand.com/en/articles/5924132-what-is-blanka-s-subscription-refund-cancellation-policy"
                >
                  here
                </a>
              </span>
            </Text>
          )}
        </div>

        <div className={styles.buttonContainer}>
          <Button
            variant="contained"
            color="primary"
            // hasChevron={isMobile}
            hasChevron
            fullWidth={isMobile}
            size="large"
            onClick={handleOnConfirm}
            loading={loading}
          >
            Place Order
          </Button>
        </div>
      </div>
    </ModalWrapper>
  );
}
