import React, { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";

import { Box, TableCell, TableRow, Checkbox } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";

import Text from "components/Text";

import {
  CART_TYPE_CUSTOMER,
  CART_TYPE_INVENTORY,
  CART_TYPE_SAMPLE,
  ORDER_STATUS_PAYMENT_REQUIRED,
  PRODUCT_TYPE_UNBRANDED,
} from "constants/constants";

import { SMALL } from "constants/breakpoints";

import DiscountTag from "components/Labels/DiscountTag/DiscountTag";

import styles from "./OrderTable.module.scss";

function OrderTableItem(props) {
  const { item, order, fullfilledByBlanka, handleSetBrandedBox, isSelected } =
    props;

  const isMobile = useMediaQuery({ maxWidth: SMALL });

  const [orderReadOnly, setOrderReadOnly] = useState(
    order.status !== ORDER_STATUS_PAYMENT_REQUIRED || !fullfilledByBlanka
  );

  useEffect(() => {
    setOrderReadOnly(
      order.status !== ORDER_STATUS_PAYMENT_REQUIRED || !fullfilledByBlanka
    );
  }, [order.status]);

  const isBrandedBoxAvailable = (brandedBoxAvailable, productType) => {
    if (!brandedBoxAvailable || productType === PRODUCT_TYPE_UNBRANDED) {
      return false;
    }

    return true;
  };

  const handleBrandedBoxChecked = (event, id) => {
    handleSetBrandedBox(event, id);
  };

  return (
    <>
      <TableRow key={item.id}>
        <TableCell className={styles.productCell}>
          <img
            className={styles.productImage}
            src={item.product?.image || item.image}
            alt=""
          />
          <Box className={styles.flexContainer}>
            <div className={styles.productName}>
              {item.product?.name || item.product_title}
            </div>
            <div className={styles.productSku}>
              {item.product?.sku || item.sku}
            </div>
          </Box>
        </TableCell>
        <TableCell className={styles.productQuantity}>
          {item.quantity}
        </TableCell>
        <TableCell className={styles.unitCost}>
          {order.order_type === CART_TYPE_SAMPLE && item?.discount ? (
            <p className={styles.discount}>
              ${Number(item.product.cost).toFixed(2)}
            </p>
          ) : (
            <p className={styles.discount}>
              $
              {Number(
                item.product.cost -
                  (item?.discount?.amount ? item.discount.amount : 0)
              ).toFixed(2)}
            </p>
          )}

          {order.order_type === CART_TYPE_INVENTORY &&
            item.discount.value > 0 && (
              <>
                <p className={styles.noDiscount}>${item.product.cost}</p>

                <Box
                  className={`${styles.boxDiscountTag} ${isMobile && styles.adjustAlignment}`}
                >
                  <DiscountTag
                    color="primary"
                    key={item.product.id}
                    label={`${Number(item?.discount?.value)}% off`}
                  />
                </Box>
              </>
            )}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell>
          {order.status === ORDER_STATUS_PAYMENT_REQUIRED &&
          order.order_type === CART_TYPE_CUSTOMER &&
          !orderReadOnly &&
          isBrandedBoxAvailable(
            item.branded_box_available,
            item.product_type
          ) ? (
            <div className={styles.brandedBoxContainer}>
              <Checkbox
                checked={isSelected}
                onChange={(event) => handleBrandedBoxChecked(event, item.id)}
              />
              <Text className={styles.brandedBox}>
                Add branded boxes $0.40/ea
              </Text>
            </div>
          ) : (
            <div
              className={
                !item.branded_box ? "hidden" : styles.brandedBoxContainer
              }
            >
              <CheckIcon className="icon--check" />
              <Text className={styles.brandedBox}>
                Add branded boxes $0.40/ea
              </Text>
            </div>
          )}
        </TableCell>
      </TableRow>
    </>
  );
}

export default OrderTableItem;
