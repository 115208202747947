import { unpauseAccount } from "api/endpoints/shopApi";
import Button from "components/Buttons/Button";
import MoonIcon from "components/Icons/MoonIcon";
import ChangePlanPaymentConfirmation from "components/Modals/ChangePlanModal/components/ChangePlanPaymentConfirmation";
import ModalWrapper from "components/ModalWrapper";
import Text from "components/Text";
import { useProfile } from "hooks";
import React, { useState } from "react";
import { useMutation } from "react-query";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import ROUTE_PATHS from "routes/routePaths";
import styles from "./PausedAccountOverlay.module.scss";

const allowedRoutes = [
  ROUTE_PATHS.PROFILE,
  ROUTE_PATHS.PROFILE_ADDRESSES,
  ROUTE_PATHS.PROFILE_ADDRESSES_NEW,
  ROUTE_PATHS.PROFILE_ADDRESSES_UPDATE,
  ROUTE_PATHS.PROFILE_PAYMENT_METHODS,
  ROUTE_PATHS.PROFILE_BILLING,
  ROUTE_PATHS.PROFILE_BILLING_UPDATE,
  ROUTE_PATHS.PROFILE_STORE_CONNECT,
  ROUTE_PATHS.DOWNGRADE_REVIEW,
  ROUTE_PATHS.DOWNGRADE_FEEDBACK,
  ROUTE_PATHS.DOWNGRADE_CANCEL_SUBSCRIPTION,
];

function PausedAccountOverlay() {
  const { pathname } = useLocation();
  const [confirmModal, setConfirmModal] = useState(false);
  const { refreshProfile } = useProfile();
  const shop = useSelector((state) => state.profile.shop);

  const unpauseAccountMutation = useMutation(unpauseAccount);

  if (allowedRoutes.includes(pathname)) {
    return null;
  }

  const onConfirmUnpauseAccount = async () => {
    unpauseAccountMutation.mutate(shop?.id, {
      onSuccess: () => {
        refreshProfile().then(() => {
          toast.success("Success! Your account has been updated.");
        });
      },
    });
  };

  return (
    <div className={styles.overlayWrapper}>
      <div className={styles.overlayCard}>
        <MoonIcon />
        <Text variant="h1" className={styles.title}>
          Looks like your account is paused
        </Text>
        <Text variant="body1" className={styles.description}>
          Get back to building your brand by re-activating your Blanka account
          today!
        </Text>
        <Button onClick={() => setConfirmModal(true)} color="primary" size="sm">
          Re-activate my account
        </Button>
      </div>

      <ModalWrapper
        handleClose={() => {
          setConfirmModal(false);
        }}
        isOpen={confirmModal}
        data-testid="ConfirmPlanModal"
        className={`${styles.confirmModalWrapper}`}
      >
        <ChangePlanPaymentConfirmation
          shop={shop}
          handleConfirm={() => {
            onConfirmUnpauseAccount();
          }}
          handleCancelConfirm={() => {
            setConfirmModal(false);
          }}
          isUnpauseAccount
          pendingPlanId={shop?.plan}
          loading={unpauseAccountMutation.isLoading}
        />
      </ModalWrapper>
    </div>
  );
}

export default PausedAccountOverlay;
