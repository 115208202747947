import React from "react";

// Custom components
import Text from "components/Text";
import Button from "components/Buttons/Button";
import ModalWrapper from "components/ModalWrapper";

import yourLogo00 from "assets/images/brand-setup/your-logo-00.png";
import yourLogo01 from "assets/images/brand-setup/your-logo-01.png";
import yourLogo10 from "assets/images/brand-setup/your-logo-10.png";
import yourLogo11 from "assets/images/brand-setup/your-logo-11.png";

import checkIcon from "assets/images/brand-setup/check_circle.svg";
import crossIcon from "assets/images/brand-setup/cancel_circle.svg";

import styles from "./BrandSetupLogoExampleModal.module.scss";

const logoExamples = [
  {
    url: yourLogo00,
    alt: "Logo Example 1",
    icon: checkIcon,
    label: "FOR LIGHTER COLORED PACKAGING",
    description: "SOLID COLOR TRANSPARENT BACKGROUND",
    isCorrect: true,
  },
  {
    url: yourLogo01,
    alt: "Logo Example 2",
    icon: crossIcon,
    label: " ",
    description:
      "AVOID GRADIENTS, MUST NOT HAVE ANY BACKGROUND BEHIND YOUR LOGO",
    isCorrect: false,
  },
  {
    url: yourLogo10,
    alt: "Logo Example 3",
    icon: checkIcon,
    label: "FOR DARKER COLORED PACKAGING",
    description: "SOLID COLOR TRANSPARENT BACKGROUND",
    isCorrect: true,
  },
  {
    url: yourLogo11,
    alt: "Logo Example 4",
    icon: crossIcon,
    label: " ",
    description: "USE ONLY 1 LOGO",
    isCorrect: false,
  },
];

function LogoDisplayGrid() {
  return (
    <div className={styles.logoGrid}>
      {logoExamples.map((example) => (
        <div key={example.url} className={styles.logoContainer}>
          {example.label && (
            <Text variant="body2" className={styles.label}>
              {example?.label}
            </Text>
          )}
          <div
            className={`${styles.logoWrapper} ${example.isCorrect ? styles.correct : styles.incorrect}`}
          >
            <img src={example.url} alt={example.alt} className={styles.logo} />
            <img
              src={example.icon}
              alt={example.isCorrect ? "check" : "cross"}
              className={styles.icon}
            />
          </div>
          <Text
            variant="body2"
            className={`${styles.description} ${example.isCorrect ? styles.correctText : styles.incorrectText}`}
          >
            {example.description}
          </Text>
        </div>
      ))}
    </div>
  );
}

export default function BrandSetupExplainModal({ open, handleClose }) {
  return (
    <ModalWrapper
      isOpen={open}
      handleClose={handleClose}
      data-testid="BrandSetupLogoExampleModal"
      className={styles.brandSetupLogoExampleModal}
    >
      <Text variant="h3" className={styles.title}>
        Logo examples
      </Text>
      {LogoDisplayGrid()}
      <Button
        variant="contained"
        color="primary"
        type="button"
        size="small"
        onClick={handleClose}
      >
        Got it
      </Button>
    </ModalWrapper>
  );
}
