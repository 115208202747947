/* eslint-disable import/no-cycle */
import apiService from "api/apiService";

export const uploadBase64Image = (fileBase64) =>
  apiService.post("/generator/check-logo-requirements/", {
    image: fileBase64,
  });

export const uploadBase64ImageV2 = (fileBase64) =>
  apiService.post("/generator/v2/check-logo-requirements/", {
    image: fileBase64,
  });

export const createProducts = () =>
  apiService.post("/generator/generate-preview-images/", {});
