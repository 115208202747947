import { Box } from "@mui/material";
import Button from "components/Buttons/Button";
import CheckIcon from "components/Icons/CheckIcon";
import ProductIcon from "components/Icons/ProductIcon";
import StoreIcon from "components/Icons/StoreIcon";
import WandIcon from "components/Icons/WandIcon";
import Text from "components/Text";
import React from "react";
import { useNavigate } from "react-router-dom";
import cn from "utils/cn";
import styles from "./DashboardTaskCard.module.scss";

function DashboardTaskCard({
  step,
  icon,
  title,
  children,
  isDone,
  buttonLabel,
  buttonPath,
}) {
  const navigate = useNavigate();

  const renderIcon = () => {
    switch (icon) {
      case "wand":
        return <WandIcon color="#D56F5B" size={40} />;

      case "product":
        return <ProductIcon color="#D56F5B" size={40} />;

      case "store":
        return <StoreIcon color="#D56F5B" size={40} />;

      default:
        return <WandIcon color="#D56F5B" size={40} />;
    }
  };
  return (
    <Box
      className={cn(
        styles.dashboardTaskCard,
        isDone && styles.dashboardTaskCardDone
      )}
    >
      {isDone && (
        <Box className={styles.doneOverlay}>
          <Box className={styles.doneIcon}>
            <CheckIcon />
          </Box>
        </Box>
      )}

      <Box className={styles.step}>Step {step}</Box>

      <Box className={styles.dashboardTaskCardContent}>
        <Box className={styles.icon}>{renderIcon()}</Box>

        <Box>
          <Box>
            <Text variant="h3" className={styles.title}>
              {title}
            </Text>
            <Box className={styles.content}>{children}</Box>
          </Box>
        </Box>
      </Box>

      {!isDone && (
        <Box mt="auto">
          <Button
            onClick={() => navigate(buttonPath)}
            color="secondary"
            size="small"
          >
            {buttonLabel}
          </Button>
        </Box>
      )}
    </Box>
  );
}

export default DashboardTaskCard;
