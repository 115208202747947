import PropTypes from "prop-types";
import React, { useState } from "react";

import OrderStatusTag from "components/Labels/OrderStatusTag";
import Text from "components/Text";

import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Box, Card, CardContent } from "@mui/material";

import OrderDetailsModal from "components/Modals/OrderDetailsModal";
import moment from "moment";

import styles from "./OrderCard.module.scss";

function OrderCard(props) {
  const { order } = props;

  // determine if the order is a customer order
  const isSampleOrder = order.order_type === "SAMPLE";
  const isCustomerOrder = order.order_type === "CUSTOMER";

  const [openOrderDetails, setOpenOrderDetails] = useState(false);

  const handleOrderDetails = () => {
    setOpenOrderDetails(true);
  };

  const handleOrderType = () => {
    if (isSampleOrder) {
      return ` #10${order.id}`;
    }

    if (isCustomerOrder && order.external_order_name) {
      return ` ${order.external_order_name}`;
    }

    if (!isCustomerOrder) {
      return ` #10${order.id}`;
    }

    return ` #`;
  };

  const orderTypeReturn = handleOrderType();

  return (
    <>
      <Card className={styles.orderCard}>
        <CardContent
          className={(styles.commonTextStyles, styles.cardWrapper)}
          onClick={handleOrderDetails}
        >
          <Box className={styles.leftSideBox}>
            <Text className={(styles.commonTextStyles, styles.orderNumber)}>
              Order
              {handleOrderType()}
            </Text>
            <Text className={(styles.commonTextStyles, styles.orderDate)}>
              Date: {moment(order.created_at).format("MMM DD yyy")}
            </Text>
          </Box>
          <Box className={styles.rightSideBox}>
            <OrderStatusTag status={order.status} type={order.order_type} />
            <Box className={styles.flexContainer}>
              <Text className={styles.orderDetails}>View Details</Text>
              <ArrowForwardIosIcon className={styles.arrowIcon} />
            </Box>
          </Box>
        </CardContent>
      </Card>
      <OrderDetailsModal
        open={openOrderDetails}
        setOpen={setOpenOrderDetails}
        order={order}
        orderType={orderTypeReturn}
      />
    </>
  );
}

OrderCard.propTypes = {
  order: PropTypes.object,
};

OrderCard.defaultProps = {
  order: {},
};

export default OrderCard;
