import React, { useState } from "react";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";

import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";

import Table from "@mui/material/Table";
import Text from "components/Text";

import Stack from "@mui/material/Stack";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import OrderDetailsModal from "components/Modals/OrderDetailsModal";
import OrderStatusBar from "components/Orders/OrderStatusBar/OrderStatusBar";

import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";

import {
  FULFILLED,
  ORDER_TYPE_CUSTOMER,
  PAYMENT_REQUIRED,
  UNFULFILLED,
} from "constants/constants";
import moment from "moment";

import { TruckIcon } from "components/Icons";

import CartTotals from "components/CartTotals/CartTotals";
import OrderStatusTag from "components/Labels/OrderStatusTag";
import OrderTable from "components/Tables/OrderTable";

// import { LocalShippingOutlinedIcon } from "components/Icons";

import { SMALL } from "constants/breakpoints";
// import PageHeader from "components/PageHeader";
// import OrderDetailsModal from "components/Modals/OrderDetailsModal";
import { Divider } from "@mui/material";
import OrderDetails from "components/Orders/OrderDetails/OrderDetails";
import styles from "./NewSampleOrdersCard.module.scss";

function NewSampleOrderCard(props) {
  const { order } = props;

  const navigate = useNavigate();
  const isMobile = useMediaQuery({ maxWidth: SMALL });

  const [open, setOpen] = useState(!!isMobile);

  const cart = {
    shipping: {
      shipping_cost: order?.shipping_cost,
    },
    total_discount: order?.discount,
    discount: order?.discount,
    fees: order?.fees,
    subtotal: order?.subtotal,
    subtotal_with_savings: "0.00",
    total: order?.total,
    type: order?.order_type,
  };

  const handlePrevious = () => {
    navigate("/sample-orders");
  };

  const trackingStatus = () => {
    if (order?.status === FULFILLED) {
      return (
        <Text className="text--nunito text__tracking-header">
          {order?.tracking_code ? order?.tracking_code : "PENDING"}
        </Text>
      );
    }

    if (order?.status === PAYMENT_REQUIRED) {
      return (
        <Text className="text--nunito text__tracking-header">PENDING</Text>
      );
    }
    if (order.status === UNFULFILLED) {
      return (
        <Text className="text--nunito text__tracking-header">
          ORDER IS BEING PROCESSSED
        </Text>
      );
    }

    return (
      <a
        target="_blank"
        href={`https://parcelsapp.com/en/tracking/${order.tracking_code}`}
        className="text__tracing-number"
        rel="noreferrer"
      >
        {order.tracking_code}
      </a>
    );
  };

  return (
    <Card className="order-card root">
      <CardContent className={styles.cardContent}>
        {!isMobile && (
          <div className="order-card__header">
            <div onClick={handlePrevious}>
              <div className={styles.backBtn}>
                <KeyboardBackspaceIcon />
                Back To Orders
              </div>
            </div>
          </div>
        )}

        <div className="order-card__details">
          {!isMobile ? (
            <>
              <Box className={styles.appendedHeader}>
                <Stack direction="row" gap="32px">
                  <Box
                    className="text--bold"
                    align="left"
                    classes={styles.cell}
                  >
                    <Text variant="body2" className={styles.title}>
                      Date
                    </Text>
                    <Text variant="body2" className={styles.peachText}>
                      {moment(order?.created_at).format("MMM DD, YYYY")}
                    </Text>
                  </Box>
                  <Box
                    className="text--bold"
                    align="left"
                    classes={styles.cell}
                  >
                    <Text variant="body2" className={styles.title}>
                      Order #
                    </Text>
                    <Text variant="body2" className={styles.peachText}>
                      {`10${order?.id}`}
                    </Text>
                  </Box>
                  <Box
                    className="text--bold"
                    align="left"
                    classes={styles.cell}
                  >
                    <Text variant="body2" className={styles.title}>
                      Qty
                    </Text>
                    <Text variant="body2" className={styles.peachText}>
                      {order?.number_of_items}
                    </Text>
                  </Box>
                  <Box
                    className="text--bold"
                    align="left"
                    classes={styles.cell}
                  >
                    <Text variant="body2" className={styles.title}>
                      Total
                    </Text>
                    <Text variant="body2" className={styles.peachText}>
                      ${order?.total}
                    </Text>
                  </Box>
                </Stack>
                <Box className="text--bold" align="center">
                  <OrderStatusBar
                    status={order.status}
                    type={order.order_type}
                  />
                </Box>
              </Box>

              <TableContainer>
                <Table aria-label="">
                  <TableHead
                    sx={{ borderTop: "1px solid #979797" }}
                    className={styles.mobileHeader}
                  >
                    <TableRow>
                      <TableCell className="text--bold" align="left">
                        Product
                      </TableCell>
                      <TableCell className="text--bold" align="left">
                        SKU
                      </TableCell>
                      <TableCell className="text--bold" align="left">
                        Product
                      </TableCell>
                      <TableCell className="text--bold" align="center">
                        Unit Cost
                      </TableCell>
                      <TableCell className="text--bold" align="center">
                        Quantity
                      </TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {order?.items?.map((item) => (
                      <TableRow key={`${item.product.sku}-${item.product.sku}`}>
                        <TableCell>
                          <div className={styles.productImage}>
                            <img
                              className="image"
                              src={item.product?.image}
                              alt="Product"
                            />
                          </div>
                        </TableCell>
                        <TableCell className="text--cell-details text--nunito text--upper">
                          {item.product.sku}
                        </TableCell>
                        <TableCell className="text--cell-details text--nunito text--upper">
                          {item.product.name}
                        </TableCell>

                        <TableCell
                          className="text--cell-details"
                          align="center"
                        >
                          ${item.product.cost}
                        </TableCell>

                        <TableCell
                          className="text--cell-details text--bold"
                          align="center"
                        >
                          {item.quantity}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          ) : (
            <>
              <Box className={styles.appendedHeader}>
                <Stack direction="row" gap="32px">
                  <Box
                    className="text--bold"
                    align="left"
                    classes={styles.cell}
                  >
                    <Text variant="body2" className={styles.title}>
                      Date
                    </Text>
                    <Text variant="body2" className={styles.peachText}>
                      {moment(order?.created_at).format("MMM DD, YYYY")}
                    </Text>
                  </Box>
                  <Box
                    className="text--bold"
                    align="left"
                    classes={styles.cell}
                  >
                    <Text variant="body2" className={styles.title}>
                      Order #
                    </Text>
                    <Text variant="body2" className={styles.peachText}>
                      {`10${order?.id}`}
                    </Text>
                  </Box>
                  <Box
                    className="text--bold"
                    align="left"
                    classes={styles.cell}
                  >
                    <Text variant="body2" className={styles.title}>
                      Qty
                    </Text>
                    <Text variant="body2" className={styles.peachText}>
                      {order?.number_of_items}
                    </Text>
                  </Box>
                  <Box
                    className="text--bold"
                    align="left"
                    classes={styles.cell}
                  >
                    <Text variant="body2" className={styles.title}>
                      Total
                    </Text>
                    <Text variant="body2" className={styles.peachText}>
                      ${order?.total}
                    </Text>
                  </Box>
                </Stack>
                <Box className="text--bold" align="center">
                  <OrderStatusBar
                    status={order.status}
                    type={order.order_type}
                  />
                </Box>
              </Box>
              <Divider className={styles.divider} />
              <OrderDetails order={order} />
            </>
          )}
        </div>
      </CardContent>

      {!isMobile && (
        <>
          <div className="tracking-number-container">
            <div className={styles.trackingContainer}>
              <TruckIcon className="icon--truck" />
              <Text className="text__tracking-header text--upper text--nunito">
                Tracking Info:
              </Text>
              {trackingStatus()}
            </div>
          </div>

          <Box className={styles.cartBillContainer}>
            <CartTotals cart={cart} step="invoice" />
          </Box>
        </>
      )}
    </Card>
  );
}

export default NewSampleOrderCard;
