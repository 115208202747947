import PlanTableCardMobile from "components/Cards/PlanTableCardMobile";
import { SMALL } from "constants/breakpoints";
import PropTypes from "prop-types";
import React from "react";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";

import { Divider } from "@mui/material";
import {
  ANNUAL_PLAN_IDS,
  FREE_PLAN_ID,
  GROWTH_ANNUAL_PLAN_ID,
  GROWTH_MONTHLY_PLAN_ID,
  NEW_MONTHLY_PLAN_IDS,
  PLAN_DESCRIPTION_ID,
  SCALE_ANNUAL_PLAN_ID,
  SCALE_MONTHLY_PLAN_ID,
  VIP_ANNUAL_PLAN_ID,
  VIP_MONTHLY_PLAN_ID,
} from "constants/plans";
import PlanTableCard from "../../Cards/PlanTableCard";
import styles from "./PlanTable.module.scss";

export default function PlanTable({
  loading,
  onPaidPlanSelect,
  onFreePlanSelect,
  switchChecked,
  allowAllSelect,
}) {
  const shop = useSelector((state) => state.profile.shop);
  const isMobile = useMediaQuery({ maxWidth: SMALL });

  if (isMobile) {
    return (
      <section className={styles.priceTableMobile}>
        {/* VIP */}
        <PlanTableCardMobile
          planId={!switchChecked ? VIP_ANNUAL_PLAN_ID : VIP_MONTHLY_PLAN_ID}
          hasTrial={shop.has_trial}
          handleClick={onPaidPlanSelect}
          loading={
            loading === VIP_ANNUAL_PLAN_ID || loading === VIP_MONTHLY_PLAN_ID
          }
          disabled={!!loading}
          activePlan={shop.plan}
          allowAllSelect={allowAllSelect}
        />
        <Divider />
        {/* Scale */}
        <PlanTableCardMobile
          planId={!switchChecked ? SCALE_ANNUAL_PLAN_ID : SCALE_MONTHLY_PLAN_ID}
          hasTrial={shop.has_trial}
          handleClick={onPaidPlanSelect}
          loading={
            loading === SCALE_ANNUAL_PLAN_ID ||
            loading === SCALE_MONTHLY_PLAN_ID
          }
          disabled={!!loading}
          activePlan={shop.plan}
          allowAllSelect={allowAllSelect}
        />
        <Divider />
        {/* Growth */}
        <PlanTableCardMobile
          planId={
            !switchChecked ? GROWTH_ANNUAL_PLAN_ID : GROWTH_MONTHLY_PLAN_ID
          }
          hasTrial={shop.has_trial}
          handleClick={onPaidPlanSelect}
          loading={
            loading === GROWTH_ANNUAL_PLAN_ID ||
            loading === GROWTH_MONTHLY_PLAN_ID
          }
          disabled={!!loading}
          activePlan={shop.plan}
          allowAllSelect={allowAllSelect}
        />
        <Divider />
        <PlanTableCardMobile
          planId={FREE_PLAN_ID}
          handleClick={onFreePlanSelect}
          loading={loading === FREE_PLAN_ID}
          disabled={!!loading}
          activePlan={shop.plan}
          allowAllSelect={allowAllSelect}
        />
        <Divider />
      </section>
    );
  }

  return (
    <section className={`${styles.priceTable}`}>
      <PlanTableCard
        key={PLAN_DESCRIPTION_ID}
        planId={PLAN_DESCRIPTION_ID}
        switchChecked={switchChecked}
        allowAllSelect={allowAllSelect}
      />
      <PlanTableCard
        key={FREE_PLAN_ID}
        planId={FREE_PLAN_ID}
        handleClick={onFreePlanSelect}
        loading={loading === FREE_PLAN_ID}
        disabled={!!loading}
        activePlan={shop.plan}
        switchChecked={switchChecked}
        allowAllSelect={allowAllSelect}
      />
      {/* Growth */}
      <PlanTableCard
        key={!switchChecked ? GROWTH_ANNUAL_PLAN_ID : GROWTH_MONTHLY_PLAN_ID}
        planId={!switchChecked ? GROWTH_ANNUAL_PLAN_ID : GROWTH_MONTHLY_PLAN_ID}
        hasTrial={shop.has_trial}
        handleClick={onPaidPlanSelect}
        loading={
          loading === GROWTH_ANNUAL_PLAN_ID ||
          loading === GROWTH_MONTHLY_PLAN_ID
        }
        disabled={!!loading}
        activePlan={shop.plan}
        switchChecked={switchChecked}
        allowAllSelect={allowAllSelect}
      />
      {/* Scale */}
      <PlanTableCard
        key={!switchChecked ? SCALE_ANNUAL_PLAN_ID : SCALE_MONTHLY_PLAN_ID}
        planId={!switchChecked ? SCALE_ANNUAL_PLAN_ID : SCALE_MONTHLY_PLAN_ID}
        hasTrial={shop.has_trial}
        handleClick={onPaidPlanSelect}
        loading={
          loading === SCALE_ANNUAL_PLAN_ID || loading === SCALE_MONTHLY_PLAN_ID
        }
        disabled={!!loading}
        activePlan={shop.plan}
        switchChecked={switchChecked}
        allowAllSelect={allowAllSelect}
      />
      {/* VIP */}
      <PlanTableCard
        key={!switchChecked ? VIP_ANNUAL_PLAN_ID : VIP_MONTHLY_PLAN_ID}
        planId={!switchChecked ? VIP_ANNUAL_PLAN_ID : VIP_MONTHLY_PLAN_ID}
        hasTrial={shop.has_trial}
        handleClick={onPaidPlanSelect}
        loading={
          loading === VIP_ANNUAL_PLAN_ID || loading === VIP_MONTHLY_PLAN_ID
        }
        disabled={!!loading}
        activePlan={shop.plan}
        switchChecked={switchChecked}
        allowAllSelect={allowAllSelect}
      />
    </section>
  );
}

PlanTable.propTypes = {
  onPaidPlanSelect: PropTypes.func.isRequired,
  onFreePlanSelect: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  allowAllSelect: PropTypes.bool,
};

PlanTable.defaultProps = {
  allowAllSelect: false,
};
