import { SvgIcon } from "@mui/material";
import React from "react";

function UserIcon({ size, color = "currentColor", active }) {
  return (
    <SvgIcon
      data-testid="MyLogoIcon"
      className="icon icon--normal"
      viewBox="0 0 18 18"
      style={{ width: size, height: size }}
    >
      {active && (
        <circle
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#E88A80"
          cx="9"
          cy="4.5"
          r="2.4"
        />
      )}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 2.16399C9.46676 2.16399 9.92304 2.30202 10.3111 2.56062C10.6992 2.81922 11.0017 3.18678 11.1804 3.61681C11.359 4.04685 11.4057 4.52005 11.3147 4.97658C11.2236 5.4331 10.9988 5.85247 10.6688 6.1816C10.3387 6.51074 9.91821 6.73488 9.46041 6.82569C9.00262 6.9165 8.5281 6.86988 8.09687 6.69175C7.66564 6.51363 7.29706 6.21198 7.03773 5.82496C6.77841 5.43793 6.64 4.98291 6.64 4.51743C6.63174 4.20613 6.68715 3.89642 6.80283 3.60717C6.9185 3.31791 7.09204 3.05518 7.31285 2.83497C7.53366 2.61477 7.79713 2.44172 8.08719 2.32636C8.37725 2.211 8.68783 2.15575 9 2.16399ZM9 12.2559C12.38 12.2559 15.86 13.9413 15.86 14.6094V15.846H2.14V14.6094C2.14 13.9114 5.62 12.226 9 12.226V12.2559ZM9 0.0299157C8.10998 0.0299157 7.23995 0.293111 6.49993 0.786207C5.75991 1.2793 5.18314 1.98015 4.84254 2.80014C4.50195 3.62013 4.41283 4.52244 4.58646 5.39293C4.7601 6.26343 5.18868 7.06303 5.81802 7.69063C6.44735 8.31822 7.24918 8.7456 8.12209 8.91875C8.99501 9.0919 9.89981 9.00304 10.7221 8.66339C11.5443 8.32374 12.2471 7.74857 12.7416 7.0106C13.2361 6.27263 13.5 5.40498 13.5 4.51743C13.5026 3.92598 13.3883 3.33982 13.1634 2.79251C12.9386 2.24519 12.6077 1.74747 12.1897 1.32785C11.7717 0.908238 11.2748 0.574973 10.7275 0.347119C10.1801 0.119266 9.59311 0.00130563 9 0V0.0299157ZM9 10.1418C6 10.1418 0 11.6078 0 14.6294V18H18V14.6294C18 11.5579 12 10.1418 9 10.1418Z"
        fill={color}
      />
    </SvgIcon>
  );
}

export default UserIcon;
