import Skeleton from "@mui/material/Skeleton";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";

import styles from "./FindProductsImageContainer.module.scss";

export default function FindProductsImageContainer({ product }) {
  const [loading, setLoading] = useState(true);

  return (
    <div className={styles.mainContainer}>
      <div className="product-image__wrapper">
        <img
          alt={product?.name}
          className={styles.productImage}
          src={product?.image}
          loading="lazy"
        />
      </div>
    </div>
  );
}

FindProductsImageContainer.propTypes = {
  product: PropTypes.object,
};

FindProductsImageContainer.defaultProps = {
  product: {},
};
