import { Check } from "@mui/icons-material";
import { CircularProgress } from "@mui/material";
import { getProfileDataPointsMetadata, QUERY, updateShop } from "api";
import { Transaction } from "api/trace";
import Button from "components/Buttons/Button";
import { useAnalytics } from "hooks";
import React, { useEffect, useRef, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useSelector } from "react-redux";
import styles from "./OnboardingQuestionForms.module.scss";

const QUESTION_TITLES = [
  "Which of the following best describes you?",
  "How long have you been operating your business?",
  "How did you hear about us?",
];

function CheckboxButton({ label, isChecked, onChange }) {
  return (
    <div
      className={`${styles.checkboxButton} ${isChecked ? styles.checked : ""}`}
      onClick={() => onChange(label)}
    >
      <input
        type="checkbox"
        checked={isChecked}
        className={styles.checkboxInput}
        readOnly
      />
      <span className={styles.checkboxCircle}>
        {isChecked && <Check className={styles.checkMark} />}
      </span>

      <span className={styles.checkboxLabel}>{label}</span>
    </div>
  );
}

function OnboardingQuestionForms() {
  const containerRef = useRef(null);
  const shop = useSelector((state) => state.profile.shop);
  const [forms, setForms] = useState([]);
  const [selectedAnswers, setSelectedAnswers] = useState({});
  const [currentIndex, setCurrentIndex] = useState(0);
  const [otherInput, setOtherInput] = useState("");
  const [isOtherSelected, setIsOtherSelected] = useState(false);
  const analytics = useAnalytics();

  const [transaction] = useState(
    Transaction.startTransaction(
      "/onboarding-step-1",
      "onboarding",
      "Answering the question about what describes their brand "
    )
  );

  const { isLoading } = useQuery(
    [QUERY.getProfileDataPointsMetadata],
    () => getProfileDataPointsMetadata(),
    {
      onSuccess: (response) => {
        const filtered = [];
        response.data.forEach((form) => {
          if (QUESTION_TITLES.includes(form?.question?.question_text)) {
            filtered.push(form);
          }
        });
        setForms(filtered);
      },
    }
  );

  const sentAnalyticsEvent = () => {
    // Iterate over the answers array and send an event for each answer
    transaction.span.data.forEach((answer) => {
      const answerToSend = answer.has_other
        ? answer.otherAnswer
        : answer.answer;

      analytics.sendEvent(analytics.ONBOARDING_QUESTIONS_SUBMIT, {
        question_id: answer.id,
        question: answer.question,
        answer: answerToSend,
      });
    });
  };

  const updateShopMutation = useMutation(
    ({ shopId, ...payload }) => updateShop(shopId, payload),
    {
      onSuccess: () => {
        transaction.finishTransaction();
      },
    }
  );

  const onFormsComplete = (formData) => {
    const formDataArray = Object.keys(formData).map((question) => {
      const questionForm = forms?.find(
        (form) => form.question.id === Number(question)
      );
      const hasOtherAnswer = typeof formData[question] === "string";
      let answer;

      if (hasOtherAnswer) {
        answer = formData[question];
      } else {
        answer = questionForm?.answers?.find(
          (answer) => answer.id === formData[question]
        )?.answer_text;
      }

      return {
        id: Number(question),
        has_other: hasOtherAnswer,
        answer,
        otherAnswer: hasOtherAnswer ? formData[question] : "",
        question: questionForm?.question?.question_text,
      };
    });

    transaction.span.data = formDataArray;

    const isNotEmpty = Object.values(formData).some((value) => !!value);

    if (isNotEmpty) {
      sentAnalyticsEvent();
    }

    updateShopMutation.mutate({
      shopId: shop.id,
      onboarding_step: shop.onboarding_step + 1,
      answerObject: formData,
    });
  };

  const resetOther = () => {
    setOtherInput("");
    setIsOtherSelected(false);
  };

  const goToNextSection = (index) => {
    const container = containerRef.current;
    if (container) {
      const section = container.children[index + 1];
      section?.scrollIntoView({ behavior: "smooth" });
    }

    resetOther();
  };

  const handleScroll = () => {
    const container = containerRef.current;
    if (container) {
      const sections = Array.from(container.children);
      const sectionOffsets = sections.map((section) => section.offsetTop);
      const scrollPosition = container.scrollTop;
      const current = sectionOffsets.findIndex(
        (offset, index) =>
          scrollPosition >= offset &&
          (index === sectionOffsets.length - 1 ||
            scrollPosition < sectionOffsets[index + 1])
      );
      setCurrentIndex(current !== -1 ? current : 0);
    }
  };

  const handleOtherSubmit = (questionId) => {
    setSelectedAnswers((prev) => {
      const updatedAnswers = {
        ...prev,
        [questionId]: otherInput?.trim(),
      };

      const isLastQuestion =
        forms.findIndex((form) => form.question.id === questionId) ===
        forms.length - 1;

      if (isLastQuestion) {
        // trigger mutation on last form
        onFormsComplete(updatedAnswers);
      } else {
        goToNextSection(
          forms.findIndex((form) => form.question.id === questionId)
        );
      }

      return updatedAnswers;
    });
  };

  const handleCheckboxChange = (questionId, answer) => {
    const { id: answerId, answer_text: answerText } = answer;

    setSelectedAnswers((prev) => {
      const updatedAnswers = {
        ...prev,
        [questionId]: answerText === "Other" ? otherInput : answerId,
      };

      const isLastQuestion =
        forms.findIndex((form) => form.question.id === questionId) ===
        forms.length - 1;

      if (isLastQuestion) {
        if (answer?.answer_text !== "Other") {
          // trigger mutation on last form for checkbox
          onFormsComplete(updatedAnswers);
        }
      }

      return updatedAnswers;
    });

    if (answerText === "Other") {
      setOtherInput(
        typeof selectedAnswers[questionId] === "string"
          ? selectedAnswers[questionId]
          : ""
      );
      setIsOtherSelected(true);
    } else {
      setIsOtherSelected(false);
      setOtherInput("");
    }

    const isLastQuestion =
      forms.findIndex((form) => form.question.id === questionId) ===
      forms.length - 1;

    if (answerText !== "Other") {
      setTimeout(() => {
        if (!isLastQuestion) {
          goToNextSection(
            forms.findIndex((form) => form.question.id === questionId)
          );
        }
      }, 200);
    }
  };

  const handleSkip = (questionId) => {
    analytics.sendEvent(analytics.ONBOARDING_QUESTION_SKPIPPED, {});

    setSelectedAnswers((prev) => {
      const updatedAnswers = {
        ...prev,
        [questionId]: null,
      };

      const isLastQuestion =
        forms[forms.length - 1]?.question.id === questionId;

      if (isLastQuestion) {
        onFormsComplete(updatedAnswers);
      } else {
        goToNextSection(
          forms.findIndex((form) => form.question.id === questionId)
        );
      }

      return updatedAnswers;
    });
  };

  const renderAnswers = (answers, questionId) => {
    const currentAnswer = selectedAnswers[questionId];

    return answers.map((answer) => (
      <CheckboxButton
        key={answer?.id}
        label={answer?.answer_text}
        isChecked={
          currentAnswer === answer?.id ||
          (isOtherSelected && answer?.answer_text === "Other")
        }
        onChange={() => handleCheckboxChange(questionId, answer)}
      />
    ));
  };

  const populateOtherInput = (questionId) => {
    const currentAnswer = selectedAnswers[questionId];
    if (typeof currentAnswer === "string") {
      setOtherInput(selectedAnswers[questionId]);
      setIsOtherSelected(true);
    } else {
      setIsOtherSelected(false);
      setOtherInput("");
    }
  };

  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      container.addEventListener("scroll", handleScroll);
    }
    return () => {
      container?.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    populateOtherInput(forms[currentIndex]?.question?.id);
    return () => {
      setIsOtherSelected(false);
      setOtherInput("");
    };
  }, [currentIndex]);

  return (
    <div className={styles.onboardingQuestionForms}>
      <div className={styles.snapContainer} ref={containerRef}>
        {forms.map((form, index) => (
          <div className={styles.snapSection} key={`${index + 1}`}>
            <h2 className={styles.questionText}>
              {form?.question?.question_text}
            </h2>
            <div className={styles.answersContainer}>
              {renderAnswers(form?.answers, form?.question?.id)}
              {isOtherSelected && currentIndex === index && (
                <div className={styles.answerInputContainer}>
                  <input
                    className={styles.answerInput}
                    type="text"
                    placeholder="Please describe"
                    value={otherInput}
                    onChange={(e) => setOtherInput(e.target.value)}
                  />
                  <Button
                    color="primary"
                    className={styles.answerButton}
                    onClick={() => handleOtherSubmit(form?.question?.id)}
                    disabled={!otherInput || updateShopMutation.isLoading}
                    loading={updateShopMutation.isLoading}
                  >
                    OK
                  </Button>
                </div>
              )}
            </div>

            {currentIndex === index && (
              <div
                className={styles.skip}
                onClick={() => handleSkip(form?.question?.id)}
              >
                Skip
              </div>
            )}
          </div>
        ))}

        {isLoading && (
          <div className={styles.formLoader}>
            <CircularProgress />
          </div>
        )}
      </div>

      <div className={styles.dotsContainer}>
        {forms.map((_, index) => (
          <span
            key={`${index + 1}`}
            className={`${styles.dot} ${currentIndex === index ? styles.active : ""}`}
            onClick={() => goToNextSection(index - 1)}
          />
        ))}
      </div>
    </div>
  );
}

export default OnboardingQuestionForms;
