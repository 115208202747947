export const FEES_PERCENTAGE = 0.029;
export const FEES_CONSTANT = 0.3;
export const DEBOUNCE_DELAY = 600;

export const UNLIMITED_PRODUCTS = "Unlimited";
export const FREE_PRODUCT = "Free";
export const CHECKED_PRODUCT = "Check";

export const SHIPPING_COST_BASE_US = 6;
export const SHIPPING_COST_INC_US = 2;

export const SHIPPING_COST_BASE_INT = 9;
export const SHIPPING_COST_INC_INT = 3;

export const BRANDED_BOX_COST = 0.4;

export const CART_TYPE_SAMPLE = "SAMPLE";
export const CART_TYPE_INVENTORY = "INVENTORY";
export const CART_TYPE_CUSTOMER = "CUSTOMER";
export const CART_TYPE_BRAND_PLATE = "BRAND_PLATE";
export const CART_TYPE_BLANKA_LABS_INVENTORY = "BLANKA_LABS_INVENTORY";
export const CART_TYPE_BLANKA_LABS_SAMPLES = "BLANKA_LABS_SAMPLES";

export const UNFULFILLED = "UNFULFILLED";
export const FULFILLED = "FULFILLED";
export const ORDERED = "ORDERED";
export const SHIPPED = "SHIPPED";
export const CANCELLED = "CANCELLED";
export const PAYMENT_ERROR = "PAYMENT_ERROR";
export const PAYMENT_REQUIRED = "PAYMENT_REQUIRED";

export const TRY_FOR_FREE = "TRY FOR FREE";
export const CHANGE_PLAN = "CHANGE PLAN";
export const CURRENT = "CURRENT";

export const DISCOUNT_TYPE_FIXED = "FIXED";
export const DISCOUNT_TYPE_PERCENTAGE = "PERCENTAGE";

export const PRODUCT_TYPE_ALL = "ALL";
export const PRODUCT_TYPE_UNBRANDED = "BLANK";
export const PRODUCT_TYPE_CUSTOM = "CUSTOM";
export const PRODUCT_TYPE_STORED_WITH_BLANKA = "STORED_WITH_BLANKA";
export const PRODUCT_TYPE_BRAND_PLATE = "BRAND_PLATE";
export const PRODUCT_TYPE_BLANKA_LABS = "BLANKA_LABS";
export const PRODUCT_TYPE_BLANKA_FORMULATOR = "BLANKA_FORMULATOR";

export const PRODUCT_STATE_ALL = "ALL";
export const PRODUCT_STATE_PUBLISHED = "PUBLISHED";
export const PRODUCT_STATE_IN_PROGRESS = "IN_PROGRESS";
export const PRODUCT_STATE_UNPUBLISHED = "UNPUBLISHED";

export const PRODUCT_CATEGORY_SAMPLE_KIT_ID = 6;

export const SHOP_PRODUCT_STATUS_UNPUBLISHED = "UNPUBLISHED";
export const SHOP_PRODUCT_STATUS_PUBLISHED = "PUBLISHED";
export const SHOP_PRODUCT_STATUS_IN_PROGRESS = "IN_PROGRESS";

export const CUSTOM_PRODUCT_STATUS_NONE = "NONE";
export const CUSTOM_PRODUCT_STATUS_IN_PROGRESS = "IN_PROGRESS";
export const CUSTOM_PRODUCT_STATUS_COMPLETE = "CREATED";

export const PRODUCT_BLANKA_BRAND = "BLANKA";

export const SHOP_ONBOARDING_STANDALONE = "STANDALONE";
export const SHOP_ONBOARDING_SHOPIFY = "SHOPIFY";

export const SUBSCRIPTION_STATUS_ACTIVE = "active";
export const SUBSCRIPTION_STATUS_FAILED = "past_due";
export const SUBSCRIPTION_STATUS_UNPAID = "unpaid";
export const SUBSCRIPTION_STATUS_INCOMPLETE_EXPIRED = "incomplete_expired";
export const SUBSCRIPTION_STATUS_TRIALING = "trialing";

export const ORDER_STATUS_ALL = "ALL";
export const ORDER_STATUS_PAYMENT_REQUIRED = "PAYMENT_REQUIRED";
export const ORDER_STATUS_ORDERED = "ORDERED";
export const ORDER_STATUS_UNFULFILLED = "UNFULFILLED";
export const ORDER_STATUS_FULFILLED = "FULFILLED";
export const ORDER_STATUS_SHIPPED = "SHIPPED";
export const ORDER_STATUS_CANCELLED = "CANCELLED";
export const ORDER_STATUS_PAYMENT_ERROR = "PAYMENT_ERROR";

export const ORDER_TYPE_SAMPLE = "SAMPLE";
export const ORDER_TYPE_INVENTORY = "INVENTORY";
export const ORDER_TYPE_CUSTOMER = "CUSTOMER";

// Integrationt ype
export const SHOP_INTEGRATION_TYPE_SHOPIFY = "SHOPIFY";
export const SHOP_INTEGRATION_TYPE_WOOCOMMERCE = "WOOCOMMERCE";
export const SHOP_INTEGRATION_TYPE_WIX = "WIX";
export const SHOP_INTEGRATION_TYPE_BLANKA_API = "BLANKA_API";

export const PICK_N_PACK_FEE = 2;

export const BLANKA_CDN_URL =
  process.env.REACT_BLANKA_CDN_URL || "https://images.blankabrand.com";

export const MY_PRODUCT_OPTIONS = [
  "PUBLISH PRODUCTS",
  "UNPUBLISH PRODUCTS",
  "REMOVE FROM MY PRODUCTS",
];

export const VOLUME_DISCOUNTS = [
  {
    quantity: 12,
    discount: 5,
  },
  {
    quantity: 49,
    discount: 10,
  },
  {
    quantity: 97,
    discount: 15,
  },
];

export const ONBOARDING_FLOW = {
  ONBOARDING_QUESTION_STEP: "ONBOARDING_QUESTION_STEP",
  ONBOARDING_PICK_PLAN_STEP: "ONBOARDING_PICK_PLAN_STEP",
  ONBOARDING_PAYMENT_STEP: "ONBOARDING_PAYMENT_STEP",
  ONBOARDING_SUCCESS_STEP: "ONBOARDING_SUCCESS_STEP",
};

export const BRAND_SETUP_STEPS = {
  UPLOAD: 0,
  MOCKUP: 1,
  REVIEW: 2,
  SUCCESS: 3,
  COMPLETE: 4,
};

export const ITEMS_PER_PAGE = 100;
