import Chip from "@mui/material/Chip";
import React from "react";

import {
  CANCELLED,
  FULFILLED,
  ORDER_TYPE_INVENTORY,
  ORDERED,
  PAYMENT_ERROR,
  PAYMENT_REQUIRED,
  SHIPPED,
  UNFULFILLED,
} from "constants/constants";

import { INVENTORY_ORDER_STATUS, ORDER_STATUS } from "constants/orders";
import styles from "./OrderStatusTag.module.scss";

export default function OrderStatusTag({ status, type = "default" }) {
  const getLabel = () => {
    if (type === ORDER_TYPE_INVENTORY) {
      return INVENTORY_ORDER_STATUS[status]?.label;
    }

    return ORDER_STATUS[status]?.label;
  };

  const getStyle = () => {
    switch (status) {
      case UNFULFILLED:
        return styles.statusOrdered;
      case ORDERED:
        return styles.statusOrdered;
      case "IN_PROGRESS":
        return styles.statusOrdered;
      case SHIPPED:
        return styles.statusFulfilled;
      case FULFILLED:
        return styles.statusFulfilled;
      case CANCELLED:
        return styles.statusCancelled;
      case PAYMENT_ERROR:
        return styles.statusCancelled;
      case PAYMENT_REQUIRED:
        return styles.statusPaymentRequired;
      default:
        return "";
    }
  };

  return (
    <Chip
      data-testid="OrderStatusTag"
      size="small"
      label={getLabel()}
      className={`${styles.tag} ${getStyle()}`}
    />
  );
}
