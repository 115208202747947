import React from "react";
import PropTypes from "prop-types";

// mui:
import { Box, Divider } from "@mui/material";

// components:
import Button from "components/Buttons/Button";
import ModalWrapper from "components/ModalWrapper";
import Text from "components/Text";

import styles from "./OrderWithIssuesModal.module.scss";

function OrderWithIssuesModal(props) {
  const { open, onClose, onAccept } = props;

  const handleClose = () => {
    onClose(false);
  };

  const handleContactMS = () => {
    window.Intercom("show");
    onClose(false);
  };

  const handleAccept = () => {
    onAccept();
  };

  return (
    <ModalWrapper
      isOpen={open}
      handleClose={handleClose}
      className={styles.orderWithIssuesModal}
    >
      <Text variant="h1" className={styles.title}>
        This order contains discontinued or out of stock products
      </Text>
      <Text>Are you sure you want to place this order?</Text>
      <Button
        variant="contained"
        color="primary"
        className={styles.cta}
        onClick={handleContactMS}
      >
        Contact Merchant Success
      </Button>
      <Box className={styles.boxWrapper}>
        <div className={styles.divider}>OR</div>
      </Box>
      <Box className={styles.boxWrapper}>
        <Button
          fullWidth
          variant="contained"
          color="primary"
          className={styles.cta}
          onClick={handleAccept}
        >
          Proceed
        </Button>
        <Button
          fullWidth
          variant="outlined"
          color="primary"
          className={styles.cta}
          onClick={handleClose}
        >
          Cancel
        </Button>
      </Box>
    </ModalWrapper>
  );
}

OrderWithIssuesModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onAccept: PropTypes.func.isRequired,
};

export default OrderWithIssuesModal;
