export const ORDER_STATUS = {
  PAYMENT_REQUIRED: {
    value: "PAYMENT_REQUIRED",
    label: "Payment Required",
  },
  IN_PROGRESS: {
    value: "IN_PROGRESS",
    label: "In Progress",
  },
  UNFULFILLED: {
    value: "UNFULFILLED",
    label: "In Progress",
  },
  ORDERED: {
    value: "ORDERED",
    label: "In Progress",
  },
  FULFILLED: {
    value: "FULFILLED",
    label: "Shipped",
  },
  SHIPPED: {
    value: "SHIPPED",
    label: "Shipped",
  },
  CANCELLED: {
    value: "CANCELLED",
    label: "Cancelled",
  },
  PAYMENT_ERROR: {
    value: "PAYMENT_ERROR",
    label: "Payment Error",
  },
};

export const INVENTORY_ORDER_STATUS = {
  PAYMENT_REQUIRED: {
    value: "PAYMENT_REQUIRED",
    label: "Payment Required",
  },
  IN_PROGRESS: {
    value: "IN_PROGRESS",
    label: "In Progress",
  },
  UNFULFILLED: {
    value: "UNFULFILLED",
    label: "In Progress",
  },
  ORDERED: {
    value: "ORDERED",
    label: "In Progress",
  },
  FULFILLED: {
    value: "FULFILLED",
    label: "Complete",
  },
  SHIPPED: {
    value: "SHIPPED",
    label: "Complete",
  },
  CANCELLED: {
    value: "CANCELLED",
    label: "Cancelled",
  },
  PAYMENT_ERROR: {
    value: "PAYMENT_ERROR",
    label: "Payment Error",
  },
};

export const SAMPLE_ORDER_STATUS_PROGRESS = ["In Progress", "Shipped"];

export const INVENTORY_ORDER_STATUS_PROGRESS = ["In Progress", "Complete"];

export const CUSTOMER_ORDER_STATUS_PROGRESS = [
  "Payment Required",
  "In Progress",
  "Shipped",
];
