import { ExpandMore } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
} from "@mui/material";
import Text from "components/Text";
import React, { useMemo, useState } from "react";
import cn from "utils/cn";

import { getProductsV2, QUERY } from "api";
import { useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { onSetProductGroupsOpened } from "state/logoCustomizeSlice";
import BrandSetupGroupProductCard from "../BrandSetupGroupProductCard";
import BrandSetupGroupProductCardSkeleton from "../BrandSetupGroupProductCardSkeleton";
import styles from "./BrandSetupGroupAccordion.module.scss";

function BrandSetupGroupAccordion({ group, index }) {
  const { productGroupsOpened } = useSelector((state) => state.logoCustomize);
  const dispatch = useDispatch();
  const [activeFilter, setActiveFilter] = useState(group?.slug);

  // fetch data here instead of in review view
  // base it off slug , if all = parent slug , if category = child slug
  const {
    isLoading,
    data: products,
    refetch,
    isSuccess,
    isRefetching,
  } = useQuery(
    [
      `${QUERY.getProducts}-${activeFilter}`,
      { product_type: "CUSTOM", category: activeFilter, mockups: true },
    ],
    () =>
      getProductsV2({
        product_type: "CUSTOM",
        category: activeFilter,
        mockups: true,
      }),
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        console.log("products", data?.data);
      },
    }
  );

  const renderGroupFilters = () => {
    if (group?.children) {
      const filters = [{ name: "all", slug: group?.slug }, ...group.children];
      return filters.map((filter) => (
        <Box
          key={filter}
          onClick={() => setActiveFilter(filter?.slug)}
          className={cn(styles.groupFilterLinkWrapper)}
        >
          <Text
            variant="body1"
            fontSize={12}
            letterSpacing="4.8px"
            textTransform="uppercase"
            color="gray"
            lineHeight="16px"
            className={cn(
              styles.groupFilterLink,
              activeFilter === filter?.slug && styles.groupFilterLinkActive
            )}
          >
            {filter?.name}
          </Text>
        </Box>
      ));
    }

    return null;
  };

  const productsLength = useMemo(() => {
    if (!products) return 0;

    const { data } = products;

    if (Array.isArray(data)) {
      return data?.length;
    }

    if (data && typeof data === "object") {
      return 1;
    }

    return 0;
  }, [products]);

  const renderGroupProducts = useMemo(() => {
    if (!products) return null;

    const { data } = products;

    if (Array.isArray(data)) {
      return data.map((product) => {
        const productBox = product?.product?.custom_boxes?.[0]?.product;
        const productBoxGroup =
          product?.product?.custom_boxes?.[0]?.product_group?.[0];

        return (
          <BrandSetupGroupProductCard
            refetch={refetch}
            isRefetching={isRefetching}
            product={product?.product}
            productGroup={product?.product_group?.[0]}
            productBox={productBox}
            productBoxGroup={productBoxGroup}
            key={`${product?.product?.name}-${product?.product?.id}`}
          />
        );
      });
    }

    if (data && typeof data === "object") {
      const productBox = data?.product?.custom_boxes?.[0]?.product;
      const productBoxGroup =
        data?.product?.custom_boxes?.[0]?.product_group?.[0];

      return (
        <BrandSetupGroupProductCard
          refetch={refetch}
          isRefetching={isRefetching}
          product={data?.product}
          productGroup={data?.product_group?.[0]}
          productBox={productBox}
          productBoxGroup={productBoxGroup}
          key={`${data?.product?.name}-${data?.product?.id}`}
        />
      );
    }

    return null;
  }, [products, refetch, isRefetching]);

  const renderSkeletons = () =>
    Array.from({ length: 12 }).map((_, index) => (
      <BrandSetupGroupProductCardSkeleton key={`${index + 1}`} />
    ));

  return (
    <Accordion
      id={`accordion-${group?.slug}`}
      defaultExpanded={index === 0}
      expanded={productGroupsOpened.includes(group.slug)}
      onChange={(_, expanded) => {
        if (expanded) {
          dispatch(
            onSetProductGroupsOpened([...productGroupsOpened, group.slug])
          );
        } else {
          dispatch(
            onSetProductGroupsOpened(
              productGroupsOpened.filter((slug) => slug !== group.slug)
            )
          );
        }
      }}
      slotProps={{ transition: { unmountOnExit: true, timeout: 300 } }}
      className={styles.groupAccordion}
    >
      <AccordionSummary
        expandIcon={<ExpandMore />}
        aria-controls="panel1-content"
        id="panel1-header"
      >
        <Box className={styles.accordionHeader}>
          <Text variant="h3" className={styles.accordionTitle}>
            {group?.name}
          </Text>
          <Text className={styles.accordionSubTitle}>
            {productsLength} {productsLength > 1 ? "Products" : "Product"}
          </Text>
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        <Box className={styles.groupFilters}>{renderGroupFilters()}</Box>
        <Box className={styles.accordionContent}>
          {isLoading ? renderSkeletons() : renderGroupProducts}
        </Box>
      </AccordionDetails>
    </Accordion>
  );
}

export default BrandSetupGroupAccordion;
