/* eslint-disable react/jsx-no-useless-fragment */
import React from "react";

export default function NotificationBellIcon({ active }) {
  return (
    <div style={{ display: "inline-block", cursor: "pointer" }}>
      <svg
        width="19"
        height="22"
        viewBox="0 0 19 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        {active && (
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M15.242 15.1227V9.90922C15.2405 8.52361 14.7653 7.18004 13.895 6.10084C13.0247 5.02164 11.8115 4.2715 10.4562 3.97458C9.99869 3.87436 9.49193 3.84998 9.15262 3.84998C8.81332 3.84998 8.21855 3.89293 7.84574 3.97458C6.49037 4.27145 5.27718 5.02164 4.40689 6.10084C3.5366 7.18004 3.0614 8.52361 3.05994 9.90922V15.1227C3.08114 16.1328 3.0599 16.5998 3.05994 17.7294H15.242C15.242 16.5998 15.2241 15.6657 15.242 15.1227Z"
            fill="#D56F5B"
          />
        )}
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.4203 15.8602L17.7221 17.1801C17.8666 17.3233 17.9652 17.5061 18.0053 17.7054C18.0453 17.9047 18.025 18.1113 17.947 18.2991C17.8689 18.4868 17.7366 18.647 17.5669 18.7593C17.3973 18.8717 17.1979 18.9311 16.9943 18.9299H1.46519C1.26247 18.929 1.06455 18.8682 0.896459 18.7551C0.728372 18.6419 0.597665 18.4816 0.520865 18.2943C0.444064 18.107 0.424618 17.9012 0.464988 17.7029C0.505359 17.5046 0.603734 17.3226 0.74767 17.1801L2.06995 15.8602V9.72076C2.07168 8.08904 2.63146 6.50682 3.65665 5.23594C4.68184 3.96506 6.11099 3.08168 7.70759 2.73203V1.53486C7.70759 1.12779 7.86958 0.737381 8.15793 0.44954C8.44627 0.161699 8.83735 0 9.24513 0C9.65291 0 10.044 0.161699 10.3323 0.44954C10.6207 0.737381 10.7827 1.12779 10.7827 1.53486V2.73203C12.3793 3.08168 13.8084 3.96506 14.8336 5.23594C15.8588 6.50682 16.4186 8.08904 16.4203 9.72076V15.8602ZM4.038 16.8834H14.2883V9.72076C14.2883 8.36386 13.7483 7.06252 12.7871 6.10305C11.826 5.14357 10.5224 4.60457 9.16313 4.60457C7.80386 4.60457 6.50027 5.14357 5.53912 6.10305C4.57797 7.06252 4.038 8.36386 4.038 9.72076V16.8834ZM10.6947 21.4007C10.3102 21.7845 9.78879 22.0001 9.24508 22.0001C8.70137 22.0001 8.17993 21.7845 7.79547 21.4007C7.41101 21.0169 7.19503 20.4964 7.19503 19.9536H11.2951C11.2951 20.4964 11.0791 21.0169 10.6947 21.4007Z"
          fill="#5E5E5E"
        />
      </svg>
    </div>
  );
}
