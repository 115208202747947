import Text from "components/Text";
import React from "react";

import { Box } from "@mui/material";
import cn from "utils/cn";
import styles from "./DashboardNewsCard.module.scss";

function DashboardNewsCard({ title, children, image }) {
  return (
    <Box
      className={cn(
        styles.dashboardNewsCard,
        image && styles.dashboardNewsCardWithImage
      )}
    >
      {image && (
        <Box className={styles.image}>
          <img src={image} alt="news-content" />
        </Box>
      )}

      <Box className={styles.main}>
        <Text variant="h3" className={styles.title}>
          {title}
        </Text>
        <Box className={styles.content}>{children}</Box>
      </Box>
    </Box>
  );
}

export default DashboardNewsCard;
