import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { resetQuestions } from "state/changePlanSlice";

import { Box, CircularProgress } from "@mui/material";
import Button from "components/Buttons/Button";
import DowngradeLayout from "components/DowngradeLayout";
import Text from "components/Text";

import { createSetupIntent, getProduct, getUser, QUERY, updatePlan } from "api";
import { Transaction } from "api/trace";
import { NoCustomProductsIcon, NoStoreIcon } from "components/Icons";
import ModalWrapper from "components/ModalWrapper";
import ChangePlanPaymentConfirmation from "components/Modals/ChangePlanModal/components/ChangePlanPaymentConfirmation";
import UpdatePaymentStripe from "components/Modals/UpdatePaymentStripe";
import SuspenseLoading from "components/SuspenseLoading";
import { SHOP_ONBOARDING_SHOPIFY } from "constants/constants";
import { PLAN_FREE_ID, PLAN_INFO } from "constants/plans";
import { useAnalytics, useProfile } from "hooks";
import { Helmet } from "react-helmet";
import { useMutation, useQuery } from "react-query";
import { toast } from "react-toastify";
import ROUTE_PATHS from "routes/routePaths";
import styles from "./DowngradeCancelView.module.scss";

const EXAMPLE_CUSTOM_PRODUCT_SKU = "BLNK-PC-03-04-CR-AEC";
const TRANSACTION_NAME = "/downgrade/cancel-subscription";
const OPERATION_NAME = "downgrade";
const TRANSACTION_DESCRIPTION = "Downgrade within Plans";

function DowngradeCancelView() {
  const analytics = useAnalytics();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const changePlan = useSelector((state) => state.changePlan);
  const shop = useSelector((state) => state.profile.shop);
  const [confirmModal, setConfirmModal] = useState(false);
  const [clientSecret, setClientSecret] = useState(null);
  const [setupCardModal, setSetupCardModal] = useState(false);
  const { isShopifyBilling, refreshProfile } = useProfile();
  const [transaction] = useState(
    Transaction.startTransaction(
      TRANSACTION_NAME,
      OPERATION_NAME,
      TRANSACTION_DESCRIPTION
    )
  );

  const { data, isLoading } = useQuery([QUERY.getProduct, shop.id], () =>
    getProduct(`${shop.id}-${EXAMPLE_CUSTOM_PRODUCT_SKU}`)
  );

  const pendingPlanId = changePlan.trackPlan.new_plan;

  const changePlanMutation = useMutation(updatePlan);

  useEffect(() => {
    if (changePlan) {
      analytics.sendEvent(analytics.DOWNGRADE_FLOW_STEP_CHANGE, {
        step: "CANCEL",
      });
    }
  }, [changePlan]);

  const onCancelSubscription = () => {
    setConfirmModal(true);
  };

  const setSetupIntent = async (id) => {
    await getUser();
    const response = await createSetupIntent(PLAN_INFO[id].price);
    setClientSecret(response.data.client_secret);
  };

  const executeChangePlanMutation = () => {
    transaction.span.data = changePlan.questions;
    transaction.finishTransaction();

    changePlanMutation.mutate(pendingPlanId, {
      onSuccess: (response) => {
        if (shop.shop_signup_type === SHOP_ONBOARDING_SHOPIFY) {
          window.location.href = response.data.confirmation_url;
          return;
        }

        refreshProfile().then(() => {
          analytics.sendEvent(analytics.DOWNGRADE_FLOW_COMPLETE, {
            old_plan: changePlan.trackPlan.older_plan,
            new_plan: changePlan.trackPlan.new_plan,
            downgrade_reason: changePlan.downgradeReason,
            downgrade_response: changePlan.downgradeResponse,
            scheduled_downgrade_date: shop.subscription_next_billing,
          });
          navigate("/profile?downgrade=success");
        });
      },
      onError: (err) => {
        console.error(err);
        toast.error("There was an error updating your account.");
      },
    });
  };

  const handleChangePlan = async (bypass = false) => {
    if (
      !shop.has_payment_method &&
      !bypass &&
      pendingPlanId !== PLAN_FREE_ID &&
      !isShopifyBilling()
    ) {
      setSetupCardModal(true);
      await setSetupIntent(pendingPlanId);
      return;
    }

    if (!bypass && pendingPlanId !== shop.plan) {
      executeChangePlanMutation();
    }
  };

  const onSuccessUpdateCard = async () => {
    setSetupCardModal(false);
    executeChangePlanMutation(pendingPlanId);
  };

  const onErrorUpdateCard = async () => {
    setClientSecret("");
    await setSetupIntent(pendingPlanId);
  };

  const onKeepCurrentPlan = () => {
    analytics.sendEvent(analytics.DOWNGRADE_FLOW_EXIT, {
      step_name: "CANCEL",
    });
    dispatch(resetQuestions());
    navigate(ROUTE_PATHS.PROFILE);
  };

  if (isLoading) {
    return (
      <DowngradeLayout>
        <Box className={styles.cancelWrapper}>
          <SuspenseLoading />
        </Box>
      </DowngradeLayout>
    );
  }

  return (
    <DowngradeLayout>
      <Helmet>
        <title>Cancel Subscription</title>
      </Helmet>
      <Box className={styles.cancelWrapper}>
        <div className={styles.imgContainer}>
          <img
            alt="Branded Product"
            className={styles.productImage}
            src={data?.data?.image}
          />
        </div>

        <Box className={styles.content}>
          <Text variant="h1" className={styles.title}>
            Hold on, you will lose your branded products!
          </Text>

          <div className={styles.usageContainer}>
            <div className={styles.usageBox}>
              <NoCustomProductsIcon className={styles.icon} />
              <Text
                color="primary"
                fontSize="16"
                className="text--bold"
                variant="body1"
              >
                You will lose access to all branded products
              </Text>
              <Text mt="8px" color="primary" variant="body1" fontSize="14">
                You will no longer be able to order branded products, or pay for
                branded products sold on your site.
              </Text>
            </div>
            <div className={styles.usageBox}>
              <NoStoreIcon className={styles.icon} />
              <Text
                color="primary"
                fontSize="16"
                className="text--bold"
                variant="body1"
              >
                You&apos;ll be limited to publishing 10 unbranded products in
                total
              </Text>
            </div>
          </div>
        </Box>

        <Box className={styles.actions}>
          <Button
            size="small"
            variant="contained"
            color="primary"
            onClick={onKeepCurrentPlan}
          >
            KEEP CURRENT PLAN
          </Button>
          <Button
            variant="text"
            color="primary"
            size="small"
            onClick={onCancelSubscription}
            className={styles.cancelSubscriptionButton}
          >
            I’m okay with losing these features
          </Button>
        </Box>
      </Box>

      <ModalWrapper
        handleClose={() => {
          setConfirmModal(false);
        }}
        isOpen={confirmModal}
        data-testid="ConfirmPlanModal"
        className={styles.confirmModalWrapper}
      >
        <ChangePlanPaymentConfirmation
          shop={shop}
          handleConfirm={() => {
            handleChangePlan();
          }}
          handleCancelConfirm={() => {
            setConfirmModal(false);
          }}
          pendingPlanId={pendingPlanId}
          loading={changePlanMutation.isLoading}
        />
      </ModalWrapper>

      <ModalWrapper
        handleClose={() => {
          setSetupCardModal(false);
        }}
        isOpen={setupCardModal}
        data-testid="SetupCardModal"
        className={`${styles.stripeModalWrapper}`}
      >
        {!clientSecret && (
          <div className={styles.loadingContainer}>
            <CircularProgress />
          </div>
        )}
        <UpdatePaymentStripe
          clientSecret={clientSecret}
          onError={onErrorUpdateCard}
          onSuccess={onSuccessUpdateCard}
          onCancel={() => setSetupCardModal(false)}
        />
      </ModalWrapper>
    </DowngradeLayout>
  );
}

export default DowngradeCancelView;
