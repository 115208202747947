import React from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";

import ModalWrapper from "components/ModalWrapper";
import Text from "components/Text";
import Button from "components/Buttons/Button";

import { Box } from "@mui/material";

import styles from "./OrderDetailsModal.module.scss";

function NoOrdersModal(props) {
  const { open, handleClose, title, message, ctaText, navLink } = props;

  const navigate = useNavigate();

  const handlePlaceOrderCta = () => {
    navigate(navLink);
    handleClose();
  };

  return (
    <ModalWrapper
      isOpen={open}
      handleClose={handleClose}
      headerType="default"
      title="No Order"
    >
      <Box className={styles.noOrdersModal}>
        <Text className={styles.textHeader} variant="h1">
          {title}
        </Text>

        <p className={styles.textWarning}>{message}</p>

        <Button
          variant="contained"
          color="primary"
          hasChevron
          size="small"
          fullWidth
          onClick={handlePlaceOrderCta}
        >
          {ctaText}
        </Button>
      </Box>
    </ModalWrapper>
  );
}

NoOrdersModal.propTypes = {
  open: PropTypes.bool.isRequired,
  title: PropTypes.string,
  message: PropTypes.string,
  ctaText: PropTypes.string.isRequired,
  navLink: PropTypes.string.isRequired,
  handleClose: PropTypes.func,
};

NoOrdersModal.defaultProps = {
  handleClose: () => {},
  title: "",
  message: "",
};

export default NoOrdersModal;
