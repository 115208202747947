export const BRAND_PLATES_TYPES = {
  ONE_COLOR: "1 Color",
  TWO_COLOR: "2 Color",
  MULTI_COLOR: "Multi-color",
};

export const BRAND_PLATES_ALT_ATTRIBUTES = [
  "One color logo",
  "two color logo",
  "Multi color logo",
];

export const BRAND_PLATE_STEPS = [
  {
    title: "Visualize Your Brand",
  },
  {
    title: "Brand Plate Ready",
  },
  {
    title: "Ship Branded Products",
  },
];

export const CATEGORY = "all";
export const PRODUCT_TYPE = "BRAND_PLATE";
export const PAGE = 1;

export const BRAND_PLATE_STATUS = {
  PAYMENT_REQUIRED: ["PAYMENT_REQUIRED", "Payment required"],
  WAITING_FOR_LOGO: ["WAITING_FOR_LOGO", "Waiting for logo"],
  IN_PROGRESS: ["IN_PROGRESS", "In progress"],
  IN_REVISION: ["IN_REVISION", "In Revision"],
  PENDING_APPROVAL: ["PENDING_APPROVAL", "Pending Approval"],
  MOCKUP_ACCEPTED: ["MOCKUP_ACCEPTED", "Mockup Accepted"],
  APPROVED: ["APPROVED", "Approved"],
  DENIED: ["DENIED", "Denied"],
};

export const BRAND_PLATE_STATUS_V2 = {
  UPLOAD_YOUR_LOGO: ["UPLOAD_YOUR_LOGO", "Waiting for logo"],
  IN_PROGRESS: ["IN_PROGRESS", "In progress"],
  IN_REVISION: ["IN_REVISION", "In Revision"],
  PENDING_APPROVAL: ["PENDING_APPROVAL", "Pending Approval"],
  MOCKUP_ACCEPTED: ["MOCKUP_ACCEPTED", "Mockup Accepted"],
  APPROVED: ["APPROVED", "Approved"],
  DENIED: ["DENIED", "Denied"],
};

export const BRAND_PLATE_PROCESS = [
  {
    title: "Upload Your Logo",
    subtitle: "Upload the logo you want to use for your brand plate",
    status: "current",
    id: BRAND_PLATE_STATUS_V2.UPLOAD_YOUR_LOGO[0],
  },
  {
    title: "In Progress",
    subtitle: "Our team will review your files to create product mock-ups",
    status: "current",
    id: BRAND_PLATE_STATUS_V2.IN_PROGRESS[0],
  },
  {
    title: "In Revision",
    subtitle: "",
    status: "current",
    hidden: true,
    id: BRAND_PLATE_STATUS.IN_REVISION[0],
  },
  {
    title: "Pending Review",
    subtitle: "Please review your pending Brand Plate mockups",
    status: "current",
    id: BRAND_PLATE_STATUS_V2.PENDING_APPROVAL[0],
  },
  {
    title: "Mockup Accepted",
    subtitle: "",
    status: "current",
    id: BRAND_PLATE_STATUS_V2.MOCKUP_ACCEPTED[0],
  },
  {
    title: "Approved",
    subtitle: "Your Brand Plate is ready!",
    status: "current",
    id: BRAND_PLATE_STATUS_V2.APPROVED[0],
  },
];

// ID's for the Brand Plate Statuses, needed in the status indicator
export const STATUSES = [
  {
    title: "Payment Received",
    status: "completed",
    id: BRAND_PLATE_STATUS.PAYMENT_REQUIRED[0],
  },
  {
    title: "Waiting for Logo",
    subtitle: "Upload the logo you want to use for your brand plate",
    status: "current",
    id: BRAND_PLATE_STATUS.WAITING_FOR_LOGO[0],
  },
  {
    title: "In Progress",
    subtitle: "Our team will review your files to create product mock-ups",
    status: "current",
    id: BRAND_PLATE_STATUS.IN_PROGRESS[0],
  },
  {
    title: "In Revision",
    subtitle: "",
    status: "current",
    hidden: true,
    id: BRAND_PLATE_STATUS.IN_REVISION[0],
  },
  {
    title: "Pending Review",
    subtitle: "Please review your pending Brand Plate mockups",
    status: "current",
    id: BRAND_PLATE_STATUS.PENDING_APPROVAL[0],
  },
  {
    title: "Mockup Accepted",
    subtitle: "",
    status: "current",
    id: BRAND_PLATE_STATUS.MOCKUP_ACCEPTED[0],
  },
  {
    title: "Approved",
    subtitle: "Your Brand Plate is ready!",
    status: "current",
    id: BRAND_PLATE_STATUS.APPROVED[0],
  },
];

export const BRAND_PLATE_FILE_UPLOAD_STATUS = [
  "Minimum size 500px x 500px",
  "Maximum size 5000px x 5000px",
  "Image quality higher than 72 DPI",
  "Transparent background",
  "No shadows or gradients",
  "No thin lines to ensure legibility",
  "File size is < 2 mb",
];

export const BRAND_PLATE_LOGO_DEFAULT = "DEFAULT";
export const BRAND_PLATE_LOGO_INVERTED = "INVERTED";
export const BRAND_PLATE_LOGO_THIN = "THIN";
export const BRAND_PLATE_LOGO_INVERTED_THIN = "INVERTED_THIN";

export const BRAND_PLATE_MOCKUPS = [
  {
    caption: "Light products",
    logo_type: BRAND_PLATE_LOGO_DEFAULT,
    image_class: "image1",
  },
  {
    caption: "Dark products",
    logo_type: BRAND_PLATE_LOGO_INVERTED,
    image_class: "image2",
  },
  {
    caption: "Thin light products",
    logo_type: BRAND_PLATE_LOGO_THIN,
    image_class: "image3",
  },
  {
    caption: "Thin dark products",
    logo_type: BRAND_PLATE_LOGO_INVERTED_THIN,
    image_class: "image4",
  },
];
