// eslint-disable-next-line import/no-cycle
import apiService from "api/apiService";
import { setNotifications } from "state/profileSlice";
import { store } from "state/store";

export const changePassword = (data) =>
  apiService.put("/auth/password/change/", {
    old_password: data.old_password,
    new_password: data.new_password,
  });

export const getShippingAddresses = () =>
  apiService.get("/shop-shipping-address/");

export const getShippingAddressById = (id) =>
  apiService.get(`/shop-shipping-address/${id}/`);

export const createShippingAddress = (data) =>
  apiService.post("/shop-shipping-address/", data);

export const updateShippingAddressById = (id, data) =>
  apiService.put(`/shop-shipping-address/${id}/`, data);

export const deleteShippingAddressById = (id) =>
  apiService.delete(`/shop-shipping-address/${id}/`);

export const getProfileDataPointsMetadata = () =>
  apiService.get(`/profile-data-points-metadata/`);

export const getNotifications = () =>
  apiService.get("/notifications/").then((res) => {
    store.dispatch(setNotifications(res.data));
    return res;
  });

// for testing purposes
export const pushNotification = () => apiService.post("/notify/");

export const getShopPreferences = () => apiService.get("/shop-preferences/");

export const getTransports = () => apiService.get("/transports/");

export const updateShopPreferences = (id, data) =>
  apiService.patch(`/shop-preferences/${id}/`, data);

export const updateNotification = (id, data) =>
  apiService.patch(`/notifications/${id}/`, data);

export const updateReadAllNotifications = () =>
  apiService.post("/notifications/mark-all-as-read/");
