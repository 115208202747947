import { Box } from "@mui/material";
import { QUERY } from "api";
import { updateNotification } from "api/endpoints/profileApi";
import queryClient from "api/queryClient";
import moment from "moment";
import React from "react";
import { useNavigate } from "react-router-dom";
import cn from "utils/cn";
import NotificationCardIcon from "../NotificationCardIcon";
import styles from "./NotificationCard.module.scss";

function NotificationCard({ data, onCloseNavigationCenter }) {
  const navigate = useNavigate();

  const updateToRead = async () => {
    if (data?.id) {
      await updateNotification(data?.id, { is_read: true });
      queryClient.refetchQueries([QUERY.getNotifications]);
    }
  };

  return (
    <Box
      className={cn(
        styles.notificationCard,
        !data.is_read && styles.notificationRead
      )}
    >
      <Box
        className={cn(
          styles.notificationIcon,
          data.urgency === "high" && styles.notificationIconUrgent
        )}
      >
        <NotificationCardIcon type={data.icon} />
      </Box>

      <Box className={styles.notificationContent}>
        <Box className={styles.notificationTitle}>{data.title}</Box>
        {data.description && (
          <Box className={styles.notificationDescription}>
            {data.description}
          </Box>
        )}
        <Box
          onClick={() => {
            if (data.navigate_to) {
              updateToRead();
              navigate(data.navigate_to);
              onCloseNavigationCenter();
            }
          }}
          className={styles.notificationAction}
        >
          {data.action_text}
        </Box>
      </Box>

      <Box className={styles.notificationMetaData}>
        <Box className={styles.notificationTime}>
          {moment(data.created_at).fromNow()}
        </Box>
        {!data.is_read && (
          <Box onClick={updateToRead} className={styles.notificationAction}>
            Mark as read
          </Box>
        )}
      </Box>
    </Box>
  );
}

export default NotificationCard;
